/* eslint-disable */

import React, { Component } from 'react';

import UserContext from '../../contexts/user'


class PagamentoPendentePopUp extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.user = this.context;

        const aux2 = this.user.loading ? this.user.updateLoading() : false;
    }

    fecharPopup() {
        this.user.updateModal(null);
    }

    render() {
        return (
            <div>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-secondary">
                            <ul className="nav nav-tabs card-header-tabs" role="tablist">
                                <li className="nav-item"><a className="nav-link fw-medium active" href="#pagamento-em-aberto-tab" data-bs-toggle="tab" role="tab" aria-selected="true"><i className="ci-coin me-2 mt-n1" />Pagamento em aberto</a></li>
                            </ul>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={this.fecharPopup.bind(this)} />
                        </div>

                        <div className="modal-body tab-content py-4">
                            <div className="row mt-3">
                                <div className="col-sm">
                                    {this.user?.mensagemPagamentoPendente}
                                </div>
                            </div>

                            <button style={{ marginTop: 30 }} className="btn btn-primary btn-shadow d-block w-100" onClick={this.fecharPopup.bind(this)}>Entendido</button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PagamentoPendentePopUp