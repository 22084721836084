import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from '../home/home';
import ProdutoDetalhado from '../produtos/produto-detalhado';
import ProdutosLista from '../produtos/produtos-lista';
import Sobre from '../sobre/sobre';
import MeuCarrinho from '../carrinho/meu-carrinho';
import MeusPedidos from '../meus-pedidos/meus-pedidos';
import MeusDados from '../meus-dados/meus-dados';
import PedidoDetalhado from '../meus-pedidos/pedido-detalhado';
import TermosUsuarios from '../termos-usuarios/termos-usuarios';

import UserContext from '../../contexts/user'
import { useHistory } from "react-router-dom";

import LeftSide from '../left-side/left-side';
import Header from '../header/header';
import Download from '../download/download';

const Routes = () => {
    const { signed } = useContext(UserContext);
    const history = useHistory();

    let props = useContext(UserContext);

    function getContext() {
        return props;
    }

    //Adicionar página permissão negada
    return (
        <Router>
            <Route component={Header} context={props} history={history} />
            <Route component={LeftSide} context={props} history={history} />
            <Switch>
                {/* <Route path='/' exact component={() => <Home context={props}/>} /> GET LOOP na renderização ao atualizar o context NÃO USAR*/}
                <Route path='/' exact component={Home} context={props} history={history} />
                <Route path='/sobre' component={Sobre} history={history} />
                <Route path='/termos-usuarios' component={TermosUsuarios} history={history} />
                <Route path='/download' component={Download} history={history} />

                {signed ?
                    <Route path='/produto-detalhado' exact component={ProdutoDetalhado} context={props} history={history} /> : null}
                {signed ?
                    <Route path='/produtos-lista' exact component={ProdutosLista} context={props} history={history} /> : null}
                {signed ?
                    <Route path='/meu-carrinho' exact component={MeuCarrinho} context={props} history={history} /> : null}
                {signed ?
                    <Route path='/meus-pedidos' exact component={MeusPedidos} context={props} history={history} /> : null}
                {signed ?
                    <Route path='/pedido-detalhado' exact component={PedidoDetalhado} context={props} history={history} /> : null}
                {signed ?
                    <Route path='/meus-dados' exact component={MeusDados} context={props} history={history} /> : null}
            </Switch>
        </Router>
    );
}

export default Routes;