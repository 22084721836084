/* eslint-disable */

import React, { Component } from 'react';
import app_home from '../../assets/img/home/app_home.png';
import ProdutosHome from '../produtos/produtos-home';

import UserContext from '../../contexts/user';
import { toast } from 'react-toastify';


class Home extends Component {
    static contextType = UserContext;
    user;

    constructor() {
        super();

        // this.state = {
        //     user: null,
        // };
    }

    componentDidMount() {
        this.user = this.context;

        setTimeout(() => window.scrollTo(0, 0), 100);

        setTimeout(() => {
            this.user.loading ? this.user.updateLoading() : false;
        }, 1000);
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;
    }

    popUpCadastro() {
        if (!this.user.signed && this.user.statusServidor) {
            this.user.updateModal('Cadastro');
        }
    }

    navegarProdutosLista() {
        // this.user.selecionarProduto(JSON.stringify(value));
        if (this.user.signed) {
            this.props.history.push('/produtos-lista');
        } else
            toast.warning('Para visualizar mais produtos, você precisa realizar login!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
    }


    render() {
        let props = this.context;

        return (
            <div>
                {/* Page*/}
                <main className="offcanvas-enabled" style={{ paddingTop: '5rem' }}>
                    <section className="ps-lg-4 pe-lg-3 pt-4">

                        <div className="px-3 pt-2">
                            {/* Slider*/}
                            <section className="tns-carousel mb-3 mb-md-5"  >
                                {/* <div className="tns-carousel-inner" data-carousel-options="{&quot;items&quot;: 1, &quot;mode&quot;: &quot;gallery&quot;, &quot;nav&quot;: false, &quot;responsive&quot;: {&quot;0&quot;: {&quot;nav&quot;: true, &quot;controls&quot;: false}, &quot;576&quot;: {&quot;nav&quot;: false, &quot;controls&quot;: true}}}"> */}
                                {/* Slide 1*/}
                                <div>
                                    <div className="rounded-3 px-md-5 text-center text-xl-start" style={{ backgroundImage: 'linear-gradient(#cde3f5, #d1e3f1, #c1e3f1, #5397cd)' }}>
                                        <div className="d-xl-flex justify-content-between align-items-center px-4 px-sm-5 mx-auto" style={{ maxWidth: '1226px' }}>
                                            <div className="py-5 me-xl-4 mx-auto mx-xl-0" style={{ maxWidth: '490px' }}>
                                                <h2 className="h1 text-dark">Modernizando sua maneira de realizar pedidos</h2>
                                                {!props.signed &&
                                                    <p className="text-dark pb-4">
                                                        <a className="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={this.popUpCadastro.bind(this)}><u>Solicite o seu cadastro</u></a> e realize suas compras online.
                                                    </p>
                                                }
                                                <h5 className="text-dark pb-3">Conheça nosso App</h5>
                                                <div className="d-flex flex-wrap justify-content-center justify-content-xl-start"><a className="btn-market btn-apple me-2 mb-2" href="https://apps.apple.com/us/app/distribuidora-ipanema/id1575424987" target="_blank" role="button"><span className="btn-market-subtitle">Disponível na</span><span className="btn-market-title">App Store</span></a><a className="btn-market btn-google mb-2" href="https://play.google.com/store/apps/details?id=prime.ipanema.br" target="_blank" role="button"><span className="btn-market-subtitle">Disponível na</span><span className="btn-market-title">Google Play</span></a></div>
                                            </div>
                                            <div><img src={app_home} style={{ maxHeight: '500px' }} alt="Image - Illustration by Freepik Storyset" /></div>
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </section>
                            {/* Discounted products (Carousel)*/}
                            <section className="pt-3 pt-md-4">

                                <div className="d-flex flex-wrap justify-content-between align-items-center pt-1 border-bottom pb-4 mb-4">
                                    <h2 className="h3 mb-0 pt-3 me-3">Nossos produtos</h2>
                                    <div className="pt-3"><a className="btn btn-outline-accent btn-sm" style={{ cursor: 'pointer' }} onClick={this.navegarProdutosLista.bind(this)}>Mais produtos<i className="ci-arrow-right ms-1 me-n1" /></a></div>
                                </div>

                                {/* {!props.signed ?
                                    <small>Olá, faça seu login</small>
                                    :
                                    <div>Minha Conta</div>
                                } */}

                                {/* <ProdutosHome context={this.user} {...this.props} /> */}

                                {
                                    props &&
                                    <ProdutosHome data={props} history={this.props.history} />
                                }
                            </section>
                        </div>
                    </section>
                </main>

            </div>
        )
    }
}

export default Home