/* eslint-disable */

import React, { Component } from 'react';
import manutencao from '../../assets/manutencao.jpg'

import UserContext from '../../contexts/user'


class ManutencaoPopUp extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.user = this.props.data

        this.state = {
            descricao: '',
            previsaoRetorno: '',
        }
    }

    componentDidMount() {
        this.user = this.context;

        this.setState({ descricao: this.user.statusVersaoServidor.descricao, previsaoRetorno: this.user.statusVersaoServidor.previsaoRetorno });
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;
    }

    fecharPopup() {
        this.user.updateModal(null);
    }

    render() {
        return (
            <div>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="container py-5 mb-lg-3">
                            <div className="row justify-content-center pt-lg-4 text-center">
                                <div className="col-sm">
                                    <img className="d-block mx-auto mb-5" src={manutencao} width={340} alt="404 Error" />
                                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <h3 className="h5 fw-normal mb-4" style={{ fontWeight: '800', color: 'black' }}>Estamos em manutenção</h3>
                                        <h3 className="h5 fw-normal mb-4">{this.state.descricao}</h3>
                                        <h3 className="h5 fw-normal mb-4">Previsão de retorno: {this.state.previsaoRetorno}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ManutencaoPopUp