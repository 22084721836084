import React, { Component } from 'react';

import UsuarioService from '../../service/usuario.service';
import semImagem from '../../assets/img/semImagem.png';

import UserContext from '../../contexts/user';

import { toast } from 'react-toastify';

const usuarioService = new UsuarioService();

let buscandoProduto = false;

class ProdutoDetalhado extends Component {
    static contextType = UserContext;

    user;
    bquantidadeCompradaProdutoNoCarrinho = false;

    constructor(props) {
        super(props);
        this.user = this.props.data

        this.state = {
            signed: false,
            produto: [{ QuantidadeMinima: 1 }],
            quantidade: 1,
            valorTotal: 0,
            credito: 0,
            subtrair: 0,
            listaProdutosPromocionais: [],
            grupoProduto: [],
            contTotalProdutosGrupo: 0,
        }
    }

    componentDidMount() {
        this.user = this.context;
        buscandoProduto = true;
        this.carregarProduto();
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;

        if (this.user.produtoSelecionado && this.user.produtoSelecionado !== JSON.stringify(this.state.produto)) {
            if (!buscandoProduto) {
                buscandoProduto = true;
                this.carregarProduto();
            }
            this.setState({ produto: JSON.parse(this.user.produtoSelecionado), quantidade: JSON.parse(this.user.produtoSelecionado).QuantidadeMinima });
        }

        if (this.user.signed !== prevState.signed) {
            this.setState({ signed: this.user.signed });
        }

        if (this.user.credito !== prevState.credito) {
            this.setState({ credito: this.user.credito });
        }

        if (this.user.listaProdutosPromocionais !== prevState.listaProdutosPromocionais) {
            this.setState({ listaProdutosPromocionais: this.user.listaProdutosPromocionais });
        }

        if (this.user.grupoPromocionalProdutoSelecionado !== prevState.grupoProduto) {
            this.setState({ grupoProduto: this.user.grupoPromocionalProdutoSelecionado });
        }

        if (this.user.contTotalProdutosGrupo !== prevState.contTotalProdutosGrupo) {
            this.setState({ contTotalProdutosGrupo: this.user.contTotalProdutosGrupo });
        }
    }

    carregarProduto() {
        console.log('carregarProduto');
        this.user.updateLoading();
        const idProduto = this.props.location.search.substring(1, this.props.location.search.length);
        usuarioService.getProdutoCatalogo(idProduto).then(prod => {
            const produto = prod.data;
            this.setState({
                produto: produto,
                quantidade: (produto.QuantidadeEstoque >= produto.QuantidadeMinima) ? produto.QuantidadeMinima : produto.QuantidadeEstoque
            });

            this.user.selecionarProduto(JSON.stringify(produto));
            setTimeout(() => {
                if (this.user.loading)
                    this.user.updateLoading();
                buscandoProduto = false;
            }, 500);
        });
        // }

        window.scrollTo(0, 0);
    }

    adicionarCarrinho() {

        if (this.state.produto.Status) {

            if (this.state.produto.QuantidadeEstoque > 0) {
                if ((this.state.produto.Valor * this.state.quantidade) + this.user.totalCompra - this.state.subtrair > this.state.credito) {
                    alert("Você não possui crédito suficiente para adicionar a quantidade seleciona ao carrinho. Seu crédito atualmente é de: R$ " + this.state.credito.toFixed(2).replace('.', ','));
                } else {
                    this.user.updateLoading();

                    this.user.updateItemCarrinho(this.state.produto, this.state.quantidade);
                    toast.success('Produto adicionado com sucesso!', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => this.props.history.goBack(), 700);
                }
            } else {
                alert('Produto sem estoque disponível no momento.');
            }
        } else {
            alert('Produto sem estoque no momento');
        }
    }

    popUpCadastro() {
        if (this.user.statusServidor) {
            this.user.updateModal('Cadastro');
        }
    }

    navegarProdutosListaCategoria(grupo) {
        this.user.updateCategoria(grupo);
        setTimeout(() => this.props.history.push('/produtos-lista'), 500);
    }

    // loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>
    render() {
        let props = this.context;

        return (
            <div>
                {/* {this.state.produto && */}
                <main className="offcanvas-enabled" style={{ paddingTop: '5rem' }}>
                    <section className="ps-lg-4 pe-lg-3 pt-4">
                        <div className="px-3 pt-2">
                            {/* Page title + breadcrumb*/}
                            <nav className="mb-4" aria-label="breadcrumb">
                                <ol className="breadcrumb flex-lg-nowrap">
                                    <li className="breadcrumb-item"><a className="text-nowrap" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/')}><i className="ci-home" />Início</a></li>
                                    <li className="breadcrumb-item text-nowrap"><a style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/produtos-lista')}>Catálogo de produtos</a>
                                    </li>
                                    <li className="breadcrumb-item text-nowrap active" aria-current="page">Produto Detalhado</li>
                                </ol>
                            </nav>
                            {/* Content*/}
                            {/* Product Gallery + description*/}
                            <section className="row g-0 mx-n2 pb-5 mb-xl-3">
                                <div className="col-xl-7 px-2 mb-3">
                                    <div className="h-100 bg-light rounded-3 p-4">
                                        <div className="product-gallery">
                                            <div className="product-gallery-preview order-sm-2">
                                                <div className="product-gallery-preview-item active" id="first">
                                                    {this.state.produto.URL_Imagem ?
                                                        <img style={{ maxHeight: 370, maxWidth: 280 }} src={this.state.produto.URL_Imagem} alt={this.state.produto.Nome} />
                                                        :
                                                        <img style={{ maxHeight: 370, maxWidth: 280 }} src={semImagem} alt={this.state.produto.Nome} />
                                                    }
                                                </div>
                                                {/* <div className="product-gallery-preview-item" id="second"><img src="img/grocery/single/02.jpg" alt="Product image" /></div>
                                                    <div className="product-gallery-preview-item" id="third"><img src="img/grocery/single/03.jpg" alt="Product image" /></div> */}
                                            </div>
                                            <div className="product-gallery-thumblist order-sm-1">
                                                <a className="product-gallery-thumblist-item active" href="#/">
                                                    {this.state.produto.URL_Imagem ?
                                                        <img src={this.state.produto.URL_Imagem} alt={this.state.produto.Nome} />
                                                        :
                                                        <img src={semImagem} alt={this.state.produto.Nome} />
                                                    }
                                                </a>
                                                {/* <a className="product-gallery-thumblist-item" href="#second">
                                                    <img src="img/grocery/single/th02.jpg" alt="Product thumb" />
                                                </a>
                                                <a className="product-gallery-thumblist-item" href="#third">
                                                    <img src="img/grocery/single/th03.jpg" alt="Product thumb" />
                                                </a> */}
                                            </div>
                                        </div>

                                        {this.state.listaProdutosPromocionais.findIndex(x => x.IDProduto === this.state.produto.Key) !== -1 && this.state.grupoProduto &&
                                            <div style={{ marginTop: 20 }}>
                                                <span className="badge bg-danger badge-shadow" style={{ marginBottom: 10 }}>{this.state.listaProdutosPromocionais[this.state.listaProdutosPromocionais.findIndex(x => x.IDProduto === this.state.produto.Key)].NomeGrupoPromocional}</span>
                                                <ul className="list-unstyled fs-sm pt-2 mb-0">
                                                    <li><i className="ci-arrow-right text-success me-2" />Ao somar {this.state.grupoProduto.QuantidadeMinima} produtos deste grupo no seu carrinho, você irá receber {this.state.grupoProduto.PorcentagemDesconto} % de desconto em cada unidade</li>
                                                    <li><i className="ci-arrow-right text-success me-2" />Quantidade comprada do grupo: {this.state.contTotalProdutosGrupo}/{this.state.grupoProduto.QuantidadeMinima}</li>
                                                    <div className="h2 fw-normal" style={{ fontSize: 10, marginTop: 16, color: 'GrayText' }}>* Confira o desconto no carrinho</div>

                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-xl-5 px-2 mb-3">
                                    <div className="h-100 bg-light rounded-3 py-5 px-4 px-sm-5"><a className="product-meta d-block fs-sm pb-2" style={{ cursor: 'pointer' }} onClick={() => this.navegarProdutosListaCategoria(this.state.produto.Grupo)}>{this.state.produto.Grupo}
                                    </a>
                                        <h1 className="h2">{this.state.produto.Nome}</h1>

                                        {this.state.signed ?
                                            <div className="h2 fw-normal text-accent">R$ {this.state.produto.ValorReal},<small>{this.state.produto.ValorCentavos}</small></div>
                                            :
                                            <div className="h2 fw-normal text-accent">R$ XX,<small>xx</small></div>
                                        }

                                        <div className="d-flex flex-wrap align-items-center pt-4 pb-2 mb-3">
                                            <input className="form-control me-3 mb-3" type="number" id="quantity1" step="1"
                                                min={(this.state.produto.QuantidadeEstoque >= this.state.produto.QuantidadeMinima) ? this.state.produto.QuantidadeMinima : this.state.produto.QuantidadeEstoque}
                                                max={(this.state.produto.QuantidadeEstoque <= this.state.produto.QuantidadeMaxima) ? this.state.produto.QuantidadeEstoque : this.state.produto.QuantidadeMaxima}
                                                style={{ width: '5rem' }} value={this.state.quantidade}
                                                onChange={(evt) => { this.setState({ quantidade: evt.target.value }) }} />
                                            {this.state.signed ?
                                                <div>
                                                    {this.state.produto.QuantidadeEstoque > 0 ?
                                                        <button className="btn btn-primary btn-shadow me-3 mb-3" onClick={this.adicionarCarrinho.bind(this)}><i className="ci-cart fs-lg me-2" />Adicionar ao carrinho</button>
                                                        :
                                                        <div className="fs-lg me-2" style={{ fontSize: 14, color: 'red', fontWeight: '550' }}>Sem estoque</div>
                                                    }
                                                </div>
                                                :
                                                <button className="btn btn-accent btn-shadow me-3 mb-3" onClick={this.popUpCadastro.bind(this)}><i className="ci-add-user fs-lg me-2" />Solicitar cadastro</button>
                                            }

                                            {this.bquantidadeCompradaProdutoNoCarrinho &&
                                                <div className="h2 fw-normal" style={{ fontSize: 10, marginTop: 16, color: 'GrayText' }}>* Produto já adicionado ao carrinho, você está editando a quantidade</div>
                                            }
                                            {/* <button className="btn btn-icon btn-secondary mb-3" type="submit" data-bs-toggle="tooltip" title="Add to Wishlist"><i className="ci-heart fs-lg" /></button> */}
                                        </div>
                                        {this.state.produto.DescricaoComplementar &&
                                            <div>
                                                <h6>Descrição do produto</h6>
                                                <ul className="list-unstyled fs-sm pt-2 mb-0">
                                                    <li><i className="ci-check-circle text-success me-2" />{this.state.produto.DescricaoComplementar}</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </main>
                {/* } */}

            </div>
        )
    }
}

export default ProdutoDetalhado