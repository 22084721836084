import React, { Component } from 'react';
import semImagem from '../../assets/img/semImagem.png';
import UsuarioService from '../../service/usuario.service';
import notFoundIMG from '../../assets/img/pages/404.png'
import { toast } from 'react-toastify';

const usuarioService = new UsuarioService();

class ListaPedido extends Component {
    user;
    aux;

    constructor(props) {
        super(props);

        this.user = this.props.data;

        this.state = {
            produtos: [],
            bLoad: true,
            bPentendeCompraAlteradaEstoque: false,
            produtosRemovidos: []
        }
    }

    componentDidMount() {
        this.user = this.props.data;

        if (this.user.carrinho.length > 0) {
            this.setState({ produtos: this.verificarProdutosPromocionais(this.user.carrinho), produtosRemovidos: this.user.itensRemovidosFaltaEstoqueCarrinho });
        }

        console.log('produtosRemovidos MOUNT')
        console.log(this.user.itensRemovidosFaltaEstoqueCarrinho)

    }

    selecionarProduto(produto) {
        if (!this.state.bPentendeCompraAlteradaEstoque) {
            this.user.updateLoading();
            this.user.selecionarProduto(JSON.stringify(produto));
            this.props.history.push({
                pathname: '/produto-detalhado',
                search: '?' + produto.Key
            });
        } else {
            toast.warning('Você só poderá alterar os produtos se esvaziar os itens do carrinho!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }

    componentWillUnmount() {
        this.setState({ produtos: [] });
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.props.data;

        if (this.user.loading !== prevProps.data.loading) {
            this.setState({ bLoad: this.user.loading });
        }

        if (this.user.carrinho !== prevProps.data.carrinho) {
            this.setState({ produtos: this.verificarProdutosPromocionais(this.user.carrinho) });
        }

        if (this.user.itensRemovidosFaltaEstoqueCarrinho !== prevState.produtosRemovidos) {
            this.setState({ produtosRemovidos: this.user.itensRemovidosFaltaEstoqueCarrinho });
        }


        if (this.user.bPentendeCompraAlteradaEstoque !== prevState.bPentendeCompraAlteradaEstoque) {
            this.setState({ bPentendeCompraAlteradaEstoque: this.user.bPentendeCompraAlteradaEstoque });
        }
    }

    verificarProdutosPromocionais(carrinho) {
        //Verifica de há produtos em grupos promocionais antes de adicionar à lista;
        let auxProdutos = carrinho;
        let auxGrupoPromocional;
        let auxDesconto;
        let auxValor;
        for (let i = 0; i < auxProdutos.length; i++) {
            auxDesconto = this.user.validarDescontoGrupoProduto(auxProdutos[i], auxProdutos);
            auxGrupoPromocional = this.user.getGrupoPromocionalProduto(auxProdutos[i]);
            if (auxDesconto) {
                auxValor = (auxProdutos[i].Valor - (auxProdutos[i].Valor * (0.01 * auxDesconto))).toFixed(2);
                auxProdutos[i].PorcentagemDesconto = auxDesconto;
                auxProdutos[i].ValorRealDesconto = auxValor.toString().substring(0, auxValor.toString().indexOf("."));
                auxProdutos[i].ValorCentavosDesconto = auxValor.toString().substring((auxValor.toString().length - 2), auxValor.length);
                auxProdutos[i].ValorDesconto = auxValor;
                auxProdutos[i].NomeGrupoPromocional = auxGrupoPromocional.NomeGrupoPromocional;
                auxProdutos[i].IDGrupoPromocional = auxGrupoPromocional.IDGrupo;
                auxProdutos[i].DescontoGrupoPromocional = true;
            } else
                auxProdutos[i].DescontoGrupoPromocional = false;
        }

        return auxProdutos;
    }

    removerItem(value) {
        if (!this.state.bPentendeCompraAlteradaEstoque) {
            this.user.updateLoading();
            this.user.removerItemCarrinho(value);

            setTimeout(() => this.user.updateLoading(), 300);
        } else {
            toast.warning('Você só poderá alterar os produtos se esvaziar os itens do carrinho!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.produtos.length > 0 ?

                    <div>
                        {
                            (this.state.produtos.map((value, i) =>
                                <div key={value.Key} className="widget-cart-item py-2 border-bottom">
                                    <button className="btn-close text-danger" type="button" aria-label="Remover" onClick={evnt => this.removerItem(value)}><span aria-hidden="true">×</span></button>
                                    <div className="d-flex align-items-center pb-2">
                                        <a className="d-block flex-shrink-0" onClick={() => this.selecionarProduto(value)} style={{ cursor: 'pointer' }}>
                                            {value.URL_Imagem ?
                                                <img src={value.URL_Imagem} alt={value.Nome} style={{ width: 64, marginTop: 4, marginBottom: 0 }} />
                                                :
                                                <img style={{ cursor: 'pointer', width: 64, marginTop: 4, marginBottom: 0 }} src={semImagem} alt={value.Nome} />
                                            }
                                        </a>
                                        <div className="ps-2">
                                            <h6 className="widget-product-title"><a style={{ cursor: 'pointer' }} onClick={() => this.selecionarProduto(value)}>{value.Nome}
                                                {value.DescontoGrupoPromocional &&
                                                    <div style={{ display: 'inline-block', marginLeft: 10, fontSize: 16, cursor: 'pointer' }}><span className="badge bg-danger ">{value.NomeGrupoPromocional} | {value.PorcentagemDesconto}%</span></div>
                                                }
                                            </a>
                                            </h6>

                                            {!value.DescontoGrupoPromocional ?
                                                <div className="widget-product-meta"><span className="text-accent me-2">R$ {value.ValorReal},<small>{value.ValorCentavos}</small></span><span className="text-muted">x {value.QuantidadeComprada}</span></div>
                                                :
                                                <div className="widget-product-meta"><span className="text-accent me-2">R$ {value.ValorRealDesconto},<small>{value.ValorCentavosDesconto}</small></span><span className="text-muted">x {value.QuantidadeComprada}</span></div>
                                            }
                                        </div>

                                        <i className="navbar-tool-icon ci-edit-alt text-accent" style={{ marginLeft: 32, marginTop: 8, cursor: 'pointer', fontSize: 16 }} onClick={evnt => this.selecionarProduto(value)} />
                                    </div>

                                </div>
                            ))
                        }

                        {
                            (this.state.produtosRemovidos.map((value, i) =>
                                <div key={value.Key} className=" py-2 border-bottom" style={{ opacity: 0.5 }}>
                                    <div className="d-flex align-items-center pb-2">
                                        <a className="d-block flex-shrink-0" >
                                            {value.URL_Imagem ?
                                                <img src={value.URL_Imagem} alt={value.Nome} style={{ width: 64, marginTop: 4, marginBottom: 0 }} />
                                                :
                                                <img style={{ cursor: 'pointer', width: 64, marginTop: 4, marginBottom: 0 }} src={semImagem} alt={value.Nome} />
                                            }
                                        </a>
                                        <div className="ps-2">
                                            <h6 className="widget-product-title">{value.Nome}
                                                {value.DescontoGrupoPromocional &&
                                                    <div style={{ display: 'inline-block', marginLeft: 10, fontSize: 16, cursor: 'pointer' }}><span className="badge bg-danger ">{value.NomeGrupoPromocional} | {value.PorcentagemDesconto}%</span></div>
                                                }
                                            </h6>

                                            {!value.DescontoGrupoPromocional ?
                                                <div className="widget-product-meta"><span className="text-accent me-2" style={{ textDecorationLine: 'line-through', textDecorationColor: 'red' }}>R$ {value.ValorReal},<small>{value.ValorCentavos}</small></span><span className="text-muted" style={{ textDecorationLine: 'line-through', textDecorationColor: 'red' }}>x {value.QuantidadeComprada}</span></div>
                                                :
                                                <div className="widget-product-meta"><span className="text-accent me-2" style={{ textDecorationLine: 'line-through', textDecorationColor: 'red' }}>R$ {value.ValorRealDesconto},<small>{value.ValorCentavosDesconto}</small></span><span className="text-muted" style={{ textDecorationLine: 'line-through', textDecorationColor: 'red' }}>x {value.QuantidadeComprada}</span></div>
                                            }

                                            <div style={{ color: 'red', fontSize: 12, fontWeight: '600' }}>Removido</div>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                    :
                    <div>
                        {
                            this.state.produtos.length === 0 &&
                            <div className="row justify-content-center pt-lg-1 text-center">
                                <div className="">
                                    <img className="d-block mx-auto mb-3" src={notFoundIMG} width={150} alt="Sem produtos" />
                                    <h1 className="h3">Nada encontrado</h1>
                                    <h3 className="h5 fw-normal mb-4">Não há produtos no seu carrinho</h3>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default ListaPedido