import React, { Component } from 'react';
// import logoDark from '../../assets/img/logo-dark.png';
import logoDark from '../../assets/img/logo_deitada.png';
import UsuarioService from '../../service/usuario.service';
import UserContext from '../../contexts/user'

const usuarioService = new UsuarioService();

class Header extends Component {
    static contextType = UserContext
    user;
    auxObservableNomesProdutos;
    inputBuscaFocus;

    constructor() {
        super();

        // const context = this.context se for usar o context na construção ou no componentDidMount
        this.state = {
            categorias: [],
            signed: false,
            totalCompra: 0,
            valorReal: 0,
            valorCentavos: 0,
            contItens: 0,
            auxProdutosBuscaNome: [],
            nomeBuscaProduto: ''
        }
    }

    componentDidMount() {
        this.user = this.context;

        const auxValor = this.user.totalCompra.toFixed(2).replace('.', ',');
        this.setState({ signed: this.user.signed, totalCompra: this.user.totalCompra, valorReal: auxValor.substring(0, auxValor.indexOf(",")), valorCentavos: auxValor.substring((auxValor.length - 2), auxValor.length), totalItens: this.user.contItens });
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;

        if (this.user.signed !== prevState.signed) {
            this.setState({ signed: this.user.signed });
        }

        if (this.user.totalCompra !== prevState.totalCompra) {
            const auxValor = this.user.totalCompra.toFixed(2).replace('.', ',');
            this.setState({ totalCompra: this.user.totalCompra, valorReal: auxValor.substring(0, auxValor.indexOf(",")), valorCentavos: auxValor.substring((auxValor.length - 2), auxValor.length), contItens: this.user.contItens });
        }

        //Limpa o carrinho caso o usuário navegue sem confirmar sua compra
        if (this.props.location.pathname !== '/meu-carrinho') {
            if (this.user.bPentendeCompraAlteradaEstoque) {
                this.user.limparCarrinhoCompraEstoque();
            }
        }
    }

    popUpLogin() {
        if (!this.user.signed && this.user.statusServidor) {
            this.user.updateModal('Login');
        }
    }

    logout() {
        this.props.history.push('/');
        this.user.SingOut();
    }

    navegarCarrinho() {
        setTimeout(() => this.props.history.push('/meu-carrinho'), 100);
    }


    // removeAccents(s) {
    //         const map = {
    //         '-' : ' |_',
    //         'a' : 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
    //         'e' : 'é|è|ê|ë|É|È|Ê|Ë',
    //         'i' : 'í|ì|î|ï|Í|Ì|Î|Ï',
    //         'o' : 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
    //         'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    //         'c' : 'ç|Ç',
    //         'n' : 'ñ|Ñ'
    //         };

    //         for (var pattern in map) {
    //         s = s.replace(new RegExp(map[pattern], 'g'), pattern);
    //         }

    //         return s;
    // }

    onChangeBuscaNomeProduto(nome) {
        if (nome.length >= 4) {
            this.auxObservableNomesProdutos = usuarioService.getBuscaInicioNomeProduto(nome.toLowerCase()).once('value', snap => {
                let dados;
                let auxValor;

                this.setState({ auxProdutosBuscaNome: [] });
                let auxBusca = []

                snap.forEach((item, index) => {
                    dados = item.val();
                    auxValor = dados.Valor.toFixed(2).replace('.', ',');

                    if (dados.NomeBusca.includes(nome.toLowerCase())) {
                        if (dados.Status) {
                            auxBusca.push({
                                Status: dados.Status,
                                Key: item.key,
                                CodigoBarras: dados.CodigoBarras,
                                CodigoProduto: dados.CodigoProduto,
                                Comissao: dados.Comissao,
                                DescricaoComplementar: dados.DescricaoComplementar,
                                Grupo: dados.Grupo,
                                IDGrupo: dados.IDGrupo,
                                IDUnidade: dados.IDUnidade,
                                Identificador: dados.Identificador,
                                Nome: dados.Nome,
                                NomeBusca: dados.NomeBusca,
                                QuantidadeEstoque: dados.QuantidadeEstoque,
                                QuantidadeAtacado: dados.QuantidadeAtacado,
                                QuantidadeMinima: dados.QuantidadeMinima,
                                QuantidadeMaxima: dados.QuantidadeMaxima,
                                Referencia: dados.Referencia,
                                TimestampFinalPromocional: dados.TimestampFinalPromocional,
                                TimestampInicialPromocional: dados.TimestampInicialPromocional,
                                TipoItem: dados.TipoItem,
                                URL_Imagem: dados.URL_Imagem,
                                Unidade: dados.Unidade,
                                Valor: dados.Valor,
                                ValorString: auxValor,
                                ValorAtacado: dados.ValorAtacado,
                                ValorPromocional: dados.ValorPromocional,
                                ValorReal: auxValor.substring(0, auxValor.indexOf(",")),
                                ValorCentavos: auxValor.substring((auxValor.length - 2), auxValor.length)
                            });
                        }
                    }
                });

                if (auxBusca.length > 0) {
                    this.setState({ auxProdutosBuscaNome: auxBusca });
                }
            });
        } else {
            this.setState({ auxProdutosBuscaNome: [] });
        }
    }

    selecionarProdutoPesquisa(produto) {
        this.user.selecionarProduto(JSON.stringify(produto));
        this.setState({ pageLoaded: false, auxProdutosBuscaNome: [], nomeBuscaProduto: '' });

        this.props.history.push({
            pathname: '/produto-detalhado',
            search: '?' + produto.Key
        });
    }

    render() {
        let props = this.props.context;

        return (
            <header className="bg-light shadow-sm fixed-top" data-fixed-element>
                <div className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid"><a className="navbar-brand d-none d-sm-block me-3 me-xl-4 flex-shrink-0" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/')}>
                        <img src={logoDark} width={150} alt="Ipanema" /></a><a className="navbar-brand d-sm-none me-2" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/')}><img src={logoDark} width={74} alt="Ipanema" /></a>
                        {/* Search */}
                        {this.state.signed &&
                            <div className="input-group d-none d-lg-flex flex-nowrap mx-4"><i className="ci-search position-absolute top-50 start-0 translate-middle-y ms-3" />
                                <input className="form-control rounded-start w-100" type="text" placeholder="Pesquisar produtos" value={this.state.nomeBuscaProduto} onChange={(e) => { this.setState({ nomeBuscaProduto: e.target.value.replace(/[^\w\s]/gi, "") }); this.onChangeBuscaNomeProduto(e.target.value); }} ref={(input) => { this.inputBuscaFocus = input; }} />
                                {
                                    this.inputBuscaFocus &&
                                    (this.state.auxProdutosBuscaNome.map((value, i) =>
                                        <div key={value.Key} className="dropdown" style={{ position: 'absolute', marginTop: 60 }}>

                                            <li className="dropdown-item" style={{ position: 'absolute', marginTop: 35 * i, backgroundColor: 'white', width: 800, cursor: 'pointer' }} onClick={(event) => this.selecionarProdutoPesquisa(value)}>
                                                {value.Nome}
                                            </li>
                                        </div>

                                    ))
                                }
                            </div>
                        }
                        {/* Toolbar */}
                        <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center ms-xl-2">
                            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#sideNav"><span className="navbar-toggler-icon" /></button>
                            {this.state.signed &&
                                <a className="navbar-tool d-flex d-lg-none" href="#searchBox" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="searchBox">
                                    <span className="navbar-tool-tooltip">Pesquisar</span>
                                    {/* <div className="navbar-tool-icon-box"><i className="navbar-tool-icon ci-search" /></div></a><a className="navbar-tool d-none d-lg-flex" href="#/"><span className="navbar-tool-tooltip">Lida de Desejos</span> */}
                                    <div className="navbar-tool-icon-box"><i className="navbar-tool-icon ci-search" /></div>
                                </a>
                            }

                            {!this.state.signed ?
                                <a className="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2" style={{ cursor: 'pointer' }} onClick={this.popUpLogin.bind(this)}>
                                    <div className="navbar-tool-icon-box">
                                        <i className="navbar-tool-icon ci-user" />
                                    </div>
                                    <div className="navbar-tool-text ms-n3">
                                        <small>Olá, faça seu login</small>
                                    </div>
                                </a>
                                :
                                <div className="navbar-tool dropdown ms-1"><a className="navbar-tool-icon-box dropdown-toggle" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('meus-dados')}><i className="navbar-tool-icon ci-user" /></a>
                                    <a className="navbar-tool-text" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('meus-dados')}><small>Minha conta</small></a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <div className="widget widget-cart px-3 pt-2 pb-3" style={{ width: '10rem' }}>
                                            <div style={{ height: '8rem' }} data-simplebar data-simplebar-auto-hide="false">
                                                <div className="pb-2 border-bottom">
                                                    <div className="d-flex align-items-center">
                                                        <div className="ps-2">
                                                            <h6 className="widget-product-title"><a style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('meus-dados')}>Meus dados</a></h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-3 pb-2 border-bottom">
                                                    <div className="d-flex align-items-center">
                                                        <div className="ps-2">
                                                            <h6 className="widget-product-title"><a style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('meus-pedidos')}>Meus pedidos</a></h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-3 pb-2 border-bottom">
                                                    <div className="d-flex align-items-center">
                                                        <div className="ps-2">
                                                            <h6 className="widget-product-title"><a style={{ cursor: 'pointer' }} onClick={this.logout.bind(this)} >Sair</a></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.signed ?
                                <div className="navbar-tool ms-3">
                                    {/* <div className="navbar-tool dropdown ms-3"> */}
                                    <a className="navbar-tool-icon-box bg-secondary dropdown-toggle" style={{ cursor: 'pointer' }} onClick={this.navegarCarrinho.bind(this)}>
                                        {this.state.contItens > 0 ?
                                            <span className="navbar-tool-label">{this.state.contItens}</span>
                                            : null
                                        }
                                        <i className="navbar-tool-icon ci-cart" />
                                    </a>
                                    <a className="navbar-tool-text" style={{ cursor: 'pointer' }} onClick={this.navegarCarrinho.bind(this)}><small>Meu Carrinho</small>R$ {this.state.totalCompra.toFixed(2).replace('.', ',')}</a>

                                    {/* <div className="dropdown-menu dropdown-menu-end">
                                        <div className="widget widget-cart px-3 pt-2 pb-3" style={{ width: '20rem' }}> */}
                                    {/* <div style={{ height: '15rem' }} data-simplebar data-simplebar-auto-hide="false">
                                            <div className="widget-cart-item pb-2 border-bottom">
                                                <button className="btn-close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">×</span></button>
                                                <div className="d-flex align-items-center">
                                                    <a className="d-block" href="grocery-single.html"><img src="img/grocery/cart/th01.jpg" width={64} alt="Product" /></a>
                                                    <div className="ps-2">
                                                        <h6 className="widget-product-title"><a href="grocery-single.html">Frozen Oven-ready Poultry</a></h6>
                                                        <div className="widget-product-meta"><span className="text-accent me-2">$15.<small>00</small></span><span className="text-muted">x 1</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-cart-item py-2 border-bottom">
                                                <button className="btn-close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">×</span></button>
                                                <div className="d-flex align-items-center">
                                                    <a className="d-block" href="grocery-single.html"><img src="img/grocery/cart/th02.jpg" width={64} alt="Product" /></a>
                                                    <div className="ps-2">
                                                        <h6 className="widget-product-title"><a href="grocery-single.html">Nut Chocolate Paste (750g)</a></h6>
                                                        <div className="widget-product-meta"><span className="text-accent me-2">$6.<small>50</small></span><span className="text-muted">x 1</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-cart-item py-2 border-bottom">
                                                <button className="btn-close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">×</span></button>
                                                <div className="d-flex align-items-center">
                                                    <a className="d-block" href="grocery-single.html"><img src="img/grocery/cart/th03.jpg" width={64} alt="Product" /></a>
                                                    <div className="ps-2">
                                                        <h6 className="widget-product-title"><a href="grocery-single.html">Mozzarella Mini Cheese</a></h6>
                                                        <div className="widget-product-meta"><span className="text-accent me-2">$3.<small>50</small></span><span className="text-muted">x 1</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    {/* <div className="d-flex flex-wrap justify-content-between align-items-center pt-3">
                                                <div className="fs-sm me-2 py-2"><span className="text-muted">Total:</span><span className="text-accent fs-base ms-1">R$ {this.state.valorReal},<small>{this.state.valorCentavos}</small></span></div><a className="btn btn-primary btn-sm" style={{ cursor: 'pointer' }} onClick={this.navegarCarrinho.bind(this)}><i className="ci-card me-2 fs-base align-middle" />Checkout<i className="ci-arrow-right ms-1 me-n1" /></a>
                                            </div> */}
                                    {/* </div>
                                    </div> */}
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
                {/* Search collapse */}
                <div className="collapse" id="searchBox">
                    <div className="card pt-2 pb-4 border-0 rounded-0">
                        <div className="container">
                            <div className="input-group"><i className="ci-search position-absolute top-50 start-0 translate-middle-y ms-3" />
                                <input className="form-control rounded-start" type="text" placeholder="Pesquisar produtos" value={this.state.nomeBuscaProduto} onChange={(e) => { this.setState({ nomeBuscaProduto: e.target.value.replace(/[^\w\s]/gi, "") }); this.onChangeBuscaNomeProduto(e.target.value); }} ref={(input) => { this.inputBuscaFocus = input; }} />
                                {
                                    this.inputBuscaFocus &&
                                    (this.state.auxProdutosBuscaNome.map((value, i) =>
                                        <div key={value.Key} className="dropdown" style={{ position: 'absolute', marginTop: 60 }}>

                                            <li className="dropdown-item" style={{ position: 'absolute', marginTop: 35 * i, backgroundColor: 'white', width: 800, cursor: 'pointer' }} onClick={(event) => this.selecionarProdutoPesquisa(value)}>
                                                {value.Nome}
                                            </li>
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header;