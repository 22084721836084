/* eslint-disable */

import React, { Component, Suspense } from 'react';
import app_home from '../../assets/img/home/app_home.png';
import ProdutosHome from '../produtos/produtos-home';

class Sobre extends Component {

    componentDidMount() {

    }



    render() {
        return (
            <div>
                {/* Page*/}
                <main className="offcanvas-enabled" style={{ paddingTop: '5rem' }}>
                    <section className="ps-lg-4 pe-lg-3 pt-4">
                        <div className="px-3 pt-2">
                            <div className="d-flex flex-wrap justify-content-between align-items-center pt-1 border-bottom pb-4 mb-4">
                                <section className="pt-3 pt-md-4">
                                    <h2 className="h3 mb-0 pt-3 me-3">Sobre nós</h2>
                                    <br></br>
                                    A Distribuidora Ipanema é especializada em distribuir os melhores produtos com qualidade, rapidez e preços competitivos para o segmentos de mercados e varejos.
                                    <br></br>
                                    Nosso compromisso é contribuir para o crescimento e a rentabilidade de seu negócio, visando qualidade ao atendimento e rapidez na entrega.
                                    <br></br>
                                </section>
                                
                                <section className="pt-3 pt-md-4">
                                    <h2 className="h5 mb-0 pt-3 me-3">MISSÃO</h2>
                                    <div style={{ marginTop: 10 }}></div>
                                    •	Ser referência em qualidade no atendimento e garantir agilidade em todos os processos.
                                    <br></br>
                                    •	Agregar valores para nossos clientes, colaboradores e fornecedores.
                                    <br></br>
                                </section>

                                <section className="pt-3 pt-md-4">
                                    <h2 className="h5 mb-0 pt-3 me-3">VISÃO</h2>
                                    <div style={{ marginTop: 10 }}></div>
                                    •	Ser a melhor Distribuidora na área de atuação, buscando sempre inovações para melhor atender nossos clientes.
                                    <br></br>
                                </section>

                                <section className="pt-3 pt-md-4">
                                    <h2 className="h5 mb-0 pt-3 me-3">VALORES</h2>
                                    <div style={{ marginTop: 10 }}></div>
                                    •	Ser uma empresa formada por uma equipe de sucesso.
                                    <br></br>
                                    •	Ter um ótimo ambiente profissional para ser trabalhar.
                                    <br></br>
                                    •	Ética, transparência e comprometimento com nossos colaboradores, clientes e fornecedores.
                                    <br></br>
                                    •	Respeito às pessoas.
                                    <br></br>
                                </section>

                                <section className="pt-3 pt-md-4">
                                    <h2 className="h5 mb-0 pt-3 me-3">CONTATOS | HORÁRIOS</h2>
                                    <div style={{ marginTop: 10 }}></div>
                                    •	<a className="nav-link-style fs-md" href="mailto:distribuidoraipanema@hotmail.com">distribuidoraipanema@hotmail.com.</a>
                                    <br></br>
                                    •   <a className="nav-link-style fs-md" href="https://api.whatsapp.com/send?phone=5532988146312&text=Olá!" target="_blank">+ 55 (32) 9 8814-6312</a>.
                                    <br></br>
                                    •	Segunda à sexta: 08:00 às 18:00. Sábado: 08:00 às 12:00.
                                    <br></br>
                                </section>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        )
    }
}

export default Sobre