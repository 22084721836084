/* eslint-disable */

import React, { Component } from 'react';

import UserContext from '../../contexts/user'
import { toast } from 'react-toastify';


class RecuperarSenhaPopUp extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.user = this.props.data

        this.state = {
            email: '',
        }
    }

    componentDidMount() {
        this.user = this.context;
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;
    }

    fecharPopup() {
        this.user.updateModal(null);
    }

    async recuperarSenha(event) {
        event.preventDefault();
        this.user.updateLoading(true);
        const resp = await this.user.RecuperarSenha(this.state.email);

        setTimeout(() => {
            this.user.updateLoading(false);

            if (resp.status) {
                toast.success(resp.mensagem, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });

                this.user.updateModal(null)
            } else {
                toast.error(resp.mensagem, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        }, 1500);

    }

    render() {
        return (
            <div>
                {/* Sign in / sign up modal*/}
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-secondary">
                            <ul className="nav nav-tabs card-header-tabs" role="tablist">
                                <li className="nav-item"><a className="nav-link fw-medium active" href="#signin-tab" data-bs-toggle="tab" role="tab" aria-selected="true"><i className="ci-unlocked me-2 mt-n1" />Recuperar senha</a></li>
                            </ul>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={this.fecharPopup.bind(this)} />
                        </div>

                        <div className="modal-body tab-content py-4">
                            <form className="needs-validation tab-pane fade show active" autoComplete="off" id="signin-tab" onSubmit={this.recuperarSenha.bind(this)}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="si-email">Email</label>
                                    <input className="form-control" type="email" id="si-email" placeholder="email@example.com" required onChange={(evt) => this.setState({ email: evt.target.value })} />
                                    <div className="invalid-feedback">Insira um e-mail válido.</div>
                                </div>

                                <button className="btn btn-primary btn-shadow d-block w-100">Enviar e-mail de recuperação</button>
                            </form>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export default RecuperarSenhaPopUp