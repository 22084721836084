/* eslint-disable */

import React, { Component } from 'react';

import UserContext from '../../contexts/user'


class LoginPopUp extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.user = this.props.data

        this.state = {
            email: '',
            senha: '',
            exibirSenha: 'password',
            manterConectado: false
        }
    }

    componentDidMount() {
        this.user = this.context;
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;
    }

    fecharPopup() {
        this.user.updateModal(null);
    }

    recuperarSenha(){
        this.user.updateModal('RecuperarSenha');
    }

    efetuarLogin(event) {
        event.preventDefault();
        this.user.updateLoading(true);
        this.user.Login(this.state.email.trim(), this.state.senha, this.state.manterConectado);

        setTimeout(() => {
            this.user.updateLoading(false);
            this.user.signed ? this.user.updateModal(null) : this.setState({ senha: '' });
        }, 1500);
    }

    render() {
        return (
            <div>
                {/* Sign in / sign up modal*/}
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-secondary">
                            <ul className="nav nav-tabs card-header-tabs" role="tablist">
                                <li className="nav-item"><a className="nav-link fw-medium active" href="#signin-tab" data-bs-toggle="tab" role="tab" aria-selected="true"><i className="ci-unlocked me-2 mt-n1" />Login</a></li>
                            </ul>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={this.fecharPopup.bind(this)} />
                        </div>

                        <div className="modal-body tab-content py-4">
                            <form className="needs-validation tab-pane fade show active" autoComplete="off" id="signin-tab" onSubmit={this.efetuarLogin.bind(this)}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="si-email">Email</label>
                                    <input className="form-control" type="email" id="si-email" placeholder="email@example.com" required onChange={(evt) => this.setState({ email: evt.target.value })} />
                                    <div className="invalid-feedback">Insira um e-mail válido.</div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="si-password">Senha</label>
                                    <div className="password-toggle">
                                        <input className="form-control" type={this.state.exibirSenha} id="si-password" required value={this.state.senha} onChange={(evt) => this.setState({ senha: evt.target.value })} />
                                        <label className="password-toggle-btn" aria-label="Show/hide password">
                                            <input className="password-toggle-check" type="checkbox" /><span className="password-toggle-indicator" onClick={() => this.state.exibirSenha === 'password' ? this.setState({ exibirSenha: 'text' }) : this.setState({ exibirSenha: 'password' })} />
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 d-flex flex-wrap justify-content-between">
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="checkbox" id="si-remember" onChange={(evt) => this.setState({ manterConectado: evt.target.value })} />
                                        <label className="form-check-label" htmlFor="si-remember">Mantenha-me conectado</label>
                                    </div><a className="fs-sm" onClick={this.recuperarSenha.bind(this)}>Esqueceu sua senha?</a>
                                </div>

                                <button className="btn btn-primary btn-shadow d-block w-100">Login</button>
                            </form>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export default LoginPopUp