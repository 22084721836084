
import React, { Component } from 'react';
import UsuarioService from '../../service/usuario.service';
import UserContext from '../../contexts/user'
import { toast } from 'react-toastify';

const usuarioService = new UsuarioService();

class LeftSide extends Component {

    static contextType = UserContext
    user;

    constructor() {
        super();

        this.state = {
            categorias: []
        }
    }

    componentDidMount() {
        usuarioService.getCategorias().once('value', snapshot => {
            let dados;
            let categorias = [];
            let aux = '';
            snapshot.forEach((item) => {
                dados = item.val();

                categorias.push({
                    Key: item.key,
                    // Nome: dados.Nome.substring(0, 1) + dados.Nome.substring(1, dados.Nome.length).toLowerCase()
                    Nome: dados.Nome
                })
            });
            //Ordem alfabética
            categorias.sort((a, b) => a.Nome.localeCompare(b.Nome));

            this.setState({ categorias: categorias });
        });

    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;
    }

    navegarProdutosListaCategoria(grupo) {
        if (this.user.signed) {
            this.user.updateCategoria(grupo);
            setTimeout(() => this.props.history.push('/produtos-lista'), 100);
        } else
            toast.warning('Para visualizar mais produtos, você precisa realizar login!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
    }

    render() {

        return (
            <aside className="offcanvas offcanvas-expand w-100 border-end zindex-lg-5 pt-lg-5" id="sideNav" style={{ maxWidth: '18.875rem' }}>
                <div className="pt-2 d-none d-lg-block" />
                <ul className="nav nav-tabs nav-justified mt-4 mt-lg-5 mb-0" role="tablist" style={{ minHeight: '3rem' }}>
                    <li className="nav-item"><a className="nav-link fw-medium active" href="#categories" data-bs-toggle="tab" role="tab">Menu</a></li>
                    <li className="nav-item d-lg-none"><a className="nav-link fs-sm" href="#/" data-bs-dismiss="offcanvas" role="tab"><i className="ci-close fs-xs me-2" />Fechar</a></li>
                </ul>
                <div className="offcanvas-body px-0 pt-3 pb-0" data-simplebar>
                    <div className="tab-content">
                        {/* Menu*/}
                        <div className="sidebar-nav tab-pane fade show active" id="categories" role="tabpanel">
                            <div className="widget widget-categories">
                                <div className="accordion" id="shop-categories">
                                    {/* Home*/}
                                    <div className="accordion-item border-bottom">
                                        <h3 className="accordion-header px-grid-gutter"><a className="nav-link-style d-block fs-md fw-normal py-3" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/')}><span className="d-flex align-items-center"><i className="ci-home fs-lg text-danger mt-n1 me-2" />Início</span></a></h3>
                                    </div>
                                    {/* Categorias*/}
                                    <div className="accordion-item border-bottom">
                                        <h3 className="accordion-header px-grid-gutter">
                                            <button className="accordion-button collapsed py-3" type="button" data-bs-toggle="collapse" data-bs-target="#bakery" aria-expanded="false" aria-controls="bakery"><span className="d-flex align-items-center"><i className="ci-list fs-lg text-danger opacity-60 mt-n1 me-2" />Produtos</span></button>
                                        </h3>
                                        <div className="collapse" id="bakery" data-bs-parent="#shop-categories">
                                            <div className="px-grid-gutter pt-1 pb-4">
                                                <div className="widget widget-links">
                                                    <ul className="widget-list">
                                                        {this.state.categorias &&
                                                            (this.state.categorias.map((value) => <li key={value.Key} className="widget-list-item"><a className="widget-list-link" style={{ cursor: 'pointer' }} onClick={() => this.navegarProdutosListaCategoria(value.Nome)}>{value.Nome}</a></li>))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Download */}
                                    <div className="accordion-item border-bottom">
                                        <h3 className="accordion-header px-grid-gutter"><a className="nav-link-style d-block fs-md fw-normal py-3" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/download')}><span className="d-flex align-items-center"><i className="ci-download fs-lg text-danger mt-n1 me-2" />Download app</span></a></h3>
                                    </div>
                                    {/* Termos */}
                                    <div className="accordion-item border-bottom">
                                        <h3 className="accordion-header px-grid-gutter"><a className="nav-link-style d-block fs-md fw-normal py-3" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/termos-usuarios')}><span className="d-flex align-items-center"><i className="ci-document fs-lg text-danger mt-n1 me-2" />Termos e políticas</span></a></h3>
                                    </div>
                                    {/* Sobre */}
                                    <div className="accordion-item border-bottom">
                                        <h3 className="accordion-header px-grid-gutter"><a className="nav-link-style d-block fs-md fw-normal py-3" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/sobre')}><span className="d-flex align-items-center"><i className="ci-announcement fs-lg text-danger mt-n1 me-2" />Sobre nós</span></a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="offcanvas-cap d-block px-grid-gutter pt-5 pb-4 mb-2">
                    <div className="d-flex mb-3"><i className="ci-support h4 mb-0 fw-normal text-primary mt-1 me-1" />
                        <div className="ps-2">
                            <div className="text-muted fs-sm">Suporte e WhatsApp</div><a className="nav-link-style fs-md" href="https://api.whatsapp.com/send?phone=5532988146312&text=Olá!" target="_blank">+55 (32) 9 8814-6312</a>
                        </div>
                    </div>
                    <div className="d-flex mb-3"><i className="ci-mail h5 mb-0 fw-normal text-primary mt-1 me-1" />
                        <div className="ps-2">
                            <div className="text-muted fs-sm">E-mail</div><a className="nav-link-style fs-md" href="mailto:distribuidoraipanema@hotmail.com"><small>distribuidoraipanema@hotmail.com</small></a>
                        </div>
                    </div>
                    <h6 className="pt-2 pb-1">Siga-nos</h6><a className="btn-social bs-outline bs-instagram me-2 mb-2" href="https://www.instagram.com/distribuidoraipanema2/" target="_blank" rel="noreferrer"><i className="ci-instagram" /></a>
                </div>
            </aside>
        )
    }
}

export default LeftSide;