/* eslint-disable */

import React, { Component } from 'react';
import logoLight from '../../assets/img/footer-logo-light.png';
import metodosPGT from '../../assets/img/cards-alt.png';
import config from '../../environments/config';

class Footer extends Component {
    constructor() {
        super();
    }


    render() {
        return (
            <main className="offcanvas-enabled" style={{ paddingTop: '5rem' }}>
                <footer className="bg-dark pt-5">
                    <div className="px-lg-3 pt-2 pb-4">
                        <div className="mx-auto px-3" style={{ maxWidth: '80rem' }}>
                            <div className="row">
                                <div className="col-xl-2 col-lg-3 col-sm-4 pb-2 mb-4">
                                    <div className="mt-n1"><a className="d-inline-block align-middle" href="#/"><img className="d-block mb-4" src={logoLight} width={117} alt="Ipanema" /></a></div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-sm-4">
                                    {/* <div className="widget widget-links widget-light pb-2 mb-4">
                                        <h3 className="widget-title text-light">Produtos e Catálogos</h3>
                                        <ul className="widget-list">
                                            <li className="widget-list-item"><a className="widget-list-link" href="#/">Special offers</a></li>
                                            <li className="widget-list-item"><a className="widget-list-link" href="#/">Bakery</a></li>
                                            <li className="widget-list-item"><a className="widget-list-link" href="#/">Fruits and Vegetables</a></li>
                                            <li className="widget-list-item"><a className="widget-list-link" href="#/">Dairy and Eggs</a></li>
                                            <li className="widget-list-item"><a className="widget-list-link" href="#/">Meat and Poultry</a></li>
                                            <li className="widget-list-item"><a className="widget-list-link" href="#/">Fish and Seafood</a></li>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className="col-xl-3 col-lg-4 col-sm-4">
                                    <div className="widget widget-links widget-light pb-2 mb-4">
                                        <h3 className="widget-title text-light">Empresa</h3>
                                        <ul className="widget-list">
                                            <li className="widget-list-item"><a className="widget-list-link" href="/termos-usuarios">Termos e políticas</a></li>
                                            <li className="widget-list-item"><a className="widget-list-link" href="/sobre">Sobre</a></li>
                                            {/* <li className="widget-list-item"><a className="widget-list-link" href="#/">Contato</a></li>
                                            <li className="widget-list-item"><a className="widget-list-link" href="#/">Dúvidas</a></li> */}
                                        </ul>
                                    </div>
                                    <div className="widget widget-light pb-2 mb-4">
                                        <h3 className="widget-title text-light">Siga-nos</h3><a className="btn-social bs-light bs-instagram me-2 mb-2" href="https://www.instagram.com/distribuidoraipanema2/" rel="noopener noreferrer" target="_blank" ><i className="ci-instagram" /></a>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-sm-8">
                                    <div className="widget pb-2 mb-4">
                                        <h3 className="widget-title text-light pb-1">Baixe o nosso App</h3>
                                        <div className="d-flex flex-wrap">
                                            <div className="me-2 mb-2"><a className="btn-market btn-apple" href="https://apps.apple.com/us/app/distribuidora-ipanema/id1575424987" target="_blank" role="button"><span className="btn-market-subtitle">Disponível para</span><span className="btn-market-title">App Store</span></a></div>
                                            <div className="mb-2"><a className="btn-market btn-google" href="https://play.google.com/store/apps/details?id=prime.ipanema.br" target="_blank" role="button"><span className="btn-market-subtitle">Disponível para</span><span className="btn-market-title">Google Play</span></a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-darker px-lg-3 py-3">
                        <div className="d-sm-flex justify-content-between align-items-center mx-auto px-3" style={{ maxWidth: '80rem' }}>
                            <div className="fs-xs text-light opacity-50 text-center text-sm-start py-3">© All rights reserved. Made by <a className="text-light" href="http://www.primeinfor.com.br/" target="_blank" rel="noopener noreferrer">Prime Automação</a> - Versão {config.versao.toFixed(1)}</div>
                            {/* <div className="py-3"><img className="d-block mx-auto mx-sm-start" src={metodosPGT} width={187} alt="Payment methods" /></div> */}
                        </div>
                    </div>
                </footer>
            </main>
        )
    }
}

export default Footer;