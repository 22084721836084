/* eslint-disable */

import React, { Component } from 'react';
import manutencao from '../../assets/manutencao.jpg'

import UserContext from '../../contexts/user'


class VersaoDesatualizadaPopUp extends Component {

    constructor(props) {
        super(props);
    }

    forcarAtualizacao() {
        window.location.reload(true);
    }

    render() {
        return (
            <div>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="container py-5 mb-lg-3">
                            <div className="row justify-content-center pt-lg-4 text-center">
                                <div className="col-sm">
                                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <h3 className="h5 fw-normal mb-4" style={{ fontWeight: '800', color: 'black' }}>Você está com uma versão desatualizada do sistema.</h3>
                                        <button className="btn btn-primary btn-shadow d-block w-100" onClick={() => this.forcarAtualizacao()}>Forçar atualização</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VersaoDesatualizadaPopUp