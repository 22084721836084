/* eslint-disable */

import React, { Component } from 'react';

import UserContext from '../../contexts/user'


class FinalizarCompraRemocaoProdutosPopUp extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        // this.user = this.props.data

        this.state = {
            produtos: [],
        }
    }

    componentDidMount() {
        this.user = this.context;

        const aux2 = this.user.loading ? this.user.updateLoading() : false;

        if (this.user.carrinho.length > 0) {
            this.setState({ produtos: this.verificarProdutosPromocionais(this.user.carrinho) });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;

        if (this.user.carrinho !== prevProps.context.carrinho) {
            this.setState({ produtos: this.verificarProdutosPromocionais(this.user.carrinho) });
        }
    }

    verificarProdutosPromocionais(carrinho) {
        //Verifica de há produtos em grupos promocionais antes de adicionar à lista;
        let auxProdutos = carrinho;
        let auxGrupoPromocional;
        let auxDesconto;
        let auxValor;
        for (let i = 0; i < auxProdutos.length; i++) {
            auxDesconto = this.user.validarDescontoGrupoProduto(auxProdutos[i], auxProdutos);
            auxGrupoPromocional = this.user.getGrupoPromocionalProduto(auxProdutos[i]);
            if (auxDesconto) {
                auxValor = (auxProdutos[i].Valor - (auxProdutos[i].Valor * (0.01 * auxDesconto))).toFixed(2);
                auxProdutos[i].PorcentagemDesconto = auxDesconto;
                auxProdutos[i].ValorRealDesconto = auxValor.toString().substring(0, auxValor.toString().indexOf("."));
                auxProdutos[i].ValorCentavosDesconto = auxValor.toString().substring((auxValor.toString().length - 2), auxValor.length);
                auxProdutos[i].ValorDesconto = auxValor;
                auxProdutos[i].NomeGrupoPromocional = auxGrupoPromocional.NomeGrupoPromocional;
                auxProdutos[i].IDGrupoPromocional = auxGrupoPromocional.IDGrupo;
                auxProdutos[i].DescontoGrupoPromocional = true;
            } else
                auxProdutos[i].DescontoGrupoPromocional = false;
        }

        return auxProdutos;
    }

    fecharPopup() {
        this.user.setBPentendeCompraAlteradaEstoque(true);
        this.user.updateModal(null);
    }

    render() {
        return (
            <div>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-secondary">
                            <ul className="nav nav-tabs card-header-tabs" role="tablist">
                                <li className="nav-item"><a className="nav-link fw-medium active" href="#confirmar-compra-tab" data-bs-toggle="tab" role="tab" aria-selected="true"><i className="ci-cart me-2 mt-n1" />Atenção</a></li>
                            </ul>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={this.fecharPopup.bind(this)} />
                        </div>

                        <div className="modal-body tab-content py-4">
                            <div className="row">
                                <div className="col-sm">
                                    <small>- Alguns produtos foram removidos por falta de estoque. Os itens liberados para sua compra estão fixados no seu carrinho.</small>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm">
                                    <small>- Não é possível alterar os itens do carrinho. Você deve concluir a compra ou "esvaziar o carrinho".</small>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-sm">
                                    <small className="form-label">* Atenção para os itens com condições promocionais.</small>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-sm">
                                    <small className="form-label">Você possui 3 minutos para finalizar sua compra.</small>
                                </div>
                            </div>

                            <button style={{ marginTop: 30 }} className="btn btn-primary btn-shadow d-block w-100" onClick={this.fecharPopup.bind(this)}>Entendido</button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FinalizarCompraRemocaoProdutosPopUp