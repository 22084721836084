import React, { Component } from 'react';
import semImagem from '../../assets/img/semImagem.png';
import UsuarioService from '../../service/usuario.service';
import notFoundIMG from '../../assets/img/pages/404.png'

const usuarioService = new UsuarioService();

class ListaProdutos extends Component {
    user;
    aux;

    constructor(props) {
        super(props);

        this.user = this.props.data;

        this.state = {
            produtos: [],
            bLoad: true
        }
    }

    componentDidMount() {
        if (this.user.pedidoSelecionado) {
            this.setState({ produtos: JSON.parse(this.user.pedidoSelecionado).Produtos });
        }
    }

    componentWillUnmount() {
        this.setState({ produtos: [] });
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.props.data;

        if (this.user.loading !== prevProps.data.loading) {
            this.setState({ bLoad: this.user.loading });
        }

        if (this.user.produtosPedidoSelecionadoViaURL !== prevProps.data.produtosPedidoSelecionadoViaURL) {
            this.setState({ produtos: JSON.parse(this.user.produtosPedidoSelecionadoViaURL) });
        }
    }

    render() {
        return (
            <div>
                {this.state.produtos.length > 0 ?

                    <div>
                        {
                            (this.state.produtos.map((value, i) =>
                                <div key={value.Key} className="py-2 border-bottom">
                                    <div className="d-flex align-items-center pb-2">
                                        <a className="d-block flex-shrink-0">
                                            {value.URL_Imagem ?
                                                <img src={value.URL_Imagem} alt={value.Nome} style={{ width: 64, marginTop: 4, marginBottom: 0 }} />
                                                :
                                                <img style={{ width: 64, marginTop: 4, marginBottom: 0 }} src={semImagem} alt={value.Nome} />
                                            }
                                        </a>
                                        <div className="ps-2">
                                            <h6 className="widget-product-title">{value.Nome}
                                                {value.DescontoGrupoPromocional &&
                                                    <div style={{ display: 'inline-block', marginLeft: 10, fontSize: 16 }}><span className="badge bg-danger ">{value.NomeGrupoPromocional} | {value.PorcentagemDesconto}%</span></div>
                                                }
                                            </h6>
                                            {!value.DescontoGrupoPromocional ?
                                                <div className="widget-product-meta"><span className="text-accent me-2">R$ {value.ValorReal},<small>{value.ValorCentavos}</small></span><span className="text-muted">x {value.QuantidadeComprada}</span></div>
                                                :
                                                <div className="widget-product-meta"><span className="text-accent me-2">R$ {value.ValorRealDesconto},<small>{value.ValorCentavosDesconto}</small></span><span className="text-muted">x {value.QuantidadeComprada}</span></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    :
                    <div>
                        {
                            this.state.produtos.length === 0 &&
                            <div className="row justify-content-center pt-lg-1 text-center">
                                <div className="">
                                    <img className="d-block mx-auto mb-3" src={notFoundIMG} width={150} alt="Sem produtos" />
                                    <h1 className="h3">Nada encontrado</h1>
                                    <h3 className="h5 fw-normal mb-4">Não há produtos no pedido</h3>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default ListaProdutos