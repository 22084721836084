import React, { Component } from 'react';
import semImagem from '../../assets/img/semImagem.png';
import UsuarioService from '../../service/usuario.service';
import notFoundIMG from '../../assets/img/pages/404.png'
import { toast } from 'react-toastify';

const usuarioService = new UsuarioService();

class ListaProdutos extends Component {
    user;
    aux;

    loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

    constructor(props) {
        super(props);

        this.user = this.props.data

        this.state = {
            produtos: [],
            signed: false,
            paginaAtual: 1,
            totalItensPorPagina: 16,
            categoria: 'Todos',
            pageLoaded: false,
            listaProdutosPromocionais: this.user.listaProdutosPromocionais,
        }
    }

    componentDidMount() {
        this.user = this.props.data;

        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this.setState({ produtos: [] });
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.props.data;

        if (this.user.signed !== prevState.signed) {
            this.setState({ signed: this.user.signed });
        }

        if (this.user.paginaAtual !== prevProps.data.paginaAtual) {
            this.setState({ paginaAtual: this.user.paginaAtual });
        }

        if (this.user.listaProdutosPromocionais !== prevProps.data.listaProdutosPromocionais) {
            this.setState({ listaProdutosPromocionais: this.user.listaProdutosPromocionais });
        }

        if (this.user.categoria !== prevProps.data.categoria || (this.user.categoria === prevProps.data.categoria && !this.state.pageLoaded)) {
            if (!this.user.loading)
                this.user.updateLoading();
            this.setState({ pageLoaded: true });

            this.setState({ categoria: this.user.categoria });

            this.buscarProdutos();

            //     console.log('lista produtos')
            //     this.aux = usuarioService.getProdutosCatalogo(this.user.categoria).once('value', snapshot => {
            //         let dados;
            //         let produtos = [];
            //         let auxValor;
            //         snapshot.forEach((item) => {
            //             dados = item.val();

            //             if (dados.Status) {
            //                 auxValor = dados.Valor.toFixed(2).replace('.', ',');

            //                 produtos.push({
            //                     Key: item.key,
            //                     Status: dados.Status,
            //                     CodigoBarras: dados.CodigoBarras,
            //                     CodigoProduto: dados.CodigoProduto,
            //                     Comissao: dados.Comissao,
            //                     DescricaoComplementar: dados.DescricaoComplementar,
            //                     Grupo: dados.Grupo,
            //                     IDGrupo: dados.IDGrupo,
            //                     IDUnidade: dados.IDUnidade,
            //                     Identificador: dados.Identificador,
            //                     Nome: dados.Nome,
            //                     NomeBusca: dados.NomeBusca,
            //                     QuantidadeEstoque: dados.QuantidadeEstoque,
            //                     QuantidadeAtacado: dados.QuantidadeAtacado,
            //                     QuantidadeMinima: dados.QuantidadeMinima,
            //                     QuantidadeMaxima: dados.QuantidadeMaxima,
            //                     Referencia: dados.Referencia,
            //                     TimestampFinalPromocional: dados.TimestampFinalPromocional,
            //                     TimestampInicialPromocional: dados.TimestampInicialPromocional,
            //                     TipoItem: dados.TipoItem,
            //                     URL_Imagem: dados.URL_Imagem,
            //                     Unidade: dados.Unidade,
            //                     Valor: dados.Valor,
            //                     ValorString: auxValor,
            //                     ValorAtacado: dados.ValorAtacado,
            //                     ValorPromocional: dados.ValorPromocional,
            //                     ValorReal: auxValor.substring(0, auxValor.indexOf(",")),
            //                     ValorCentavos: auxValor.substring((auxValor.length - 2), auxValor.length)
            //                 });
            //             }
            //         });
            //         //Ordem alfabética
            //         let auxProdutos = produtos.sort((a, b) => a.Nome.localeCompare(b.Nome));
            //         let auxCopia = [];
            //         let auxSemEstoque = [];

            //         if (this.user.filtro === 'Maior Valor') {
            //             //this.user.updateLoading();
            //             auxProdutos = produtos.sort((a, b) => a.Valor - b.Valor);
            //         } else
            //             if (this.user.filtro === 'Menor Valor') {
            //                 //this.user.updateLoading();
            //                 auxProdutos = produtos.sort((a, b) => a.Valor - b.Valor);
            //             }
            //             //else {
            //                 //Randomizava a exibição dos produtos
            //                 //const auxProdutos = produtos.sort(() => Math.random() - 0.5);
            //             //}
            //             for (let i = 0; i < auxProdutos.length; i++) {
            //                 auxProdutos[i].QuantidadeEstoque > 0 ? auxCopia.push(auxProdutos[i]) : auxSemEstoque.push(auxProdutos[i]);
            //             }
            //             auxProdutos = [...auxCopia, ...auxSemEstoque];
            //             auxCopia = [];
            //             auxSemEstoque = [];
            //             this.props.setTotalPaginas(Math.ceil(produtos.length / this.state.totalItensPorPagina));
            //             this.setState({ produtos: auxProdutos });
            //             //setTimeout(() => this.user.updateLoading(), 1000);

            //         setTimeout(() => window.scrollTo(0, 0), 200);
            //     }).catch(err => console.log(err))
            // }

            // if (this.user.filtro && this.user.filtro !== prevProps.data.filtro) {
            //     if (this.user.filtro === 'Maior Valor') {
            //         this.user.updateLoading();
            //         const auxProdutos = this.state.produtos.sort((a, b) => a.Valor - b.Valor);
            //         this.setState({ produtos: auxProdutos.reverse(), filtro: 'Maior Valor' });
            //         this.props.setTotalPaginas(Math.ceil(auxProdutos.length / this.state.totalItensPorPagina));
            //         this.props.navegarPagina(0);
            //         setTimeout(() => this.user.updateLoading(), 500);
            //     } else
            //         if (this.user.filtro === 'Menor Valor') {
            //             this.user.updateLoading();
            //             const auxProdutos = this.state.produtos.sort((a, b) => a.Valor - b.Valor);
            //             this.setState({ produtos: auxProdutos, filtro: 'Menor Valor' });
            //             this.props.setTotalPaginas(Math.ceil(auxProdutos.length / this.state.totalItensPorPagina));
            //             this.props.navegarPagina(0);
            //             setTimeout(() => this.user.updateLoading(), 500);
            //         }
            // }
        }
    }

    async buscarProdutos() {
        let produtos = (await usuarioService.getProdutosCatalogo())?.data;

        console.log('this.user.categoria', this.user.categoria)

        //Ordem alfabética
        let auxProdutos = produtos.sort((a, b) => a.Nome.localeCompare(b.Nome));
        if (this.user.categoria !== null)
            auxProdutos = auxProdutos.filter(p => p.Grupo === this.user.categoria);

        let auxCopia = [];
        let auxSemEstoque = [];

        if (this.user.filtro === 'Maior Valor') {
            auxProdutos = produtos.sort((a, b) => a.Valor - b.Valor);
        } else
            if (this.user.filtro === 'Menor Valor') {
                auxProdutos = produtos.sort((a, b) => a.Valor - b.Valor);
            }

        for (let i = 0; i < auxProdutos.length; i++) {
            auxProdutos[i].QuantidadeEstoque > 0 ? auxCopia.push(auxProdutos[i]) : auxSemEstoque.push(auxProdutos[i]);
        }
        auxProdutos = [...auxCopia, ...auxSemEstoque];
        auxCopia = [];
        auxSemEstoque = [];
        this.props.setTotalPaginas(Math.ceil(produtos.length / this.state.totalItensPorPagina));

        setTimeout(() => window.scrollTo(0, 0), 200);

        let auxGrupoPromocional;
        for (let i = 0; i < auxProdutos.length; i++) {
            auxGrupoPromocional = this.user.getGrupoPromocionalProduto(auxProdutos[i]);
            if (auxGrupoPromocional) {
                auxProdutos[i].PorcentagemDesconto = auxGrupoPromocional.PorcentagemDesconto;
                auxProdutos[i].NomeGrupoPromocional = auxGrupoPromocional.NomeGrupoPromocional;
                auxProdutos[i].IDGrupoPromocional = auxGrupoPromocional.IDGrupo;
                auxProdutos[i].DescontoGrupoPromocional = true;
            } else
                auxProdutos[i].DescontoGrupoPromocional = false;
        }

        if (this.user.filtro === 'Maior Valor') {
            auxProdutos = auxProdutos.sort((a, b) => a.Valor - b.Valor);
            this.setState({ produtos: auxProdutos.reverse(), filtro: 'Maior Valor' });
        } else
            if (this.user.filtro === 'Menor Valor') {
                auxProdutos = auxProdutos.sort((a, b) => a.Valor - b.Valor);
                this.setState({ produtos: auxProdutos, filtro: 'Menor Valor' });
            } else
                this.setState({ produtos: auxProdutos });

        this.props.setTotalPaginas(Math.ceil(auxProdutos.length / this.state.totalItensPorPagina));
        this.props.navegarPagina(0);

        if (this.user.loading)
            this.user.updateLoading();
    }

    selecionarProduto(value) {
        if (value.QuantidadeEstoque > 0) {
            this.user.updateLoading();
            this.user.updateCategoria(null);
            this.user.selecionarProduto(JSON.stringify(value));
            this.setState({ pageLoaded: false });
            this.props.history.push({
                pathname: '/produto-detalhado',
                search: '?' + value.Key
            });
        } else {
            toast.warn('Produto sem estoque', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }

    alterarCategoria(value) {
        this.setState({ categoriaSelecionada: value });
        this.user.updateCategoria(value !== 'Todos' ? value : null);
    }

    render() {
        return (
            <div>
                {this.state.produtos.length > 0 ?

                    <div className="row g-0 mx-n2">
                        {
                            (this.state.produtos.map((value, i) =>
                                (i >= this.state.totalItensPorPagina * (this.state.paginaAtual - 1) && i < this.state.paginaAtual * this.state.totalItensPorPagina) &&
                                <div key={value.Key} className="col-xl-3 col-lg-6 col-md-4 col-sm-6 px-2 mb-3">
                                    <div className="card product-card card-static pb-3" style={{ opacity: value.QuantidadeEstoque <= 0 ? 0.7 : 100 }}>
                                        {
                                            this.state.listaProdutosPromocionais.findIndex(x => x.IDProduto === value.Key) !== -1 &&
                                            <div className="card product-card card-static"><span className="badge bg-danger badge-shadow">{this.state.listaProdutosPromocionais[this.state.listaProdutosPromocionais.findIndex(x => x.IDProduto === value.Key)].NomeGrupoPromocional} | {this.state.listaProdutosPromocionais[this.state.listaProdutosPromocionais.findIndex(x => x.IDProduto === value.Key)].PorcentagemDesconto}%</span></div>
                                        }
                                        <a className="card-img-top d-block overflow-hidden" onClick={() => this.selecionarProduto(value)} style={{ height: 170, cursor: 'pointer', background: value.URL_Imagem ? 'white' : '#DCDCDC' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {value.URL_Imagem ?
                                                    <img src={value.URL_Imagem} alt={value.Nome} style={{ maxWidth: 520, maxHeight: 170, display: 'block', margin: '0 auto' }} />
                                                    :
                                                    <img style={{ cursor: 'pointer', maxWidth: 520, maxHeight: 170, display: 'block', margin: '0 auto' }} src={semImagem} alt={value.Nome} />
                                                }
                                            </div>
                                        </a>
                                        <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" style={{ cursor: 'pointer' }} onClick={() => this.alterarCategoria(value.Grupo)}>{value.Grupo}</a>
                                            <h3 className="product-title fs-sm text-truncate"><a style={{ cursor: 'pointer' }} onClick={() => this.selecionarProduto(value)}>{value.Nome}</a></h3>
                                            {this.state.signed ?
                                                <div className="product-price"><span className="text-accent">R$ {value.ValorReal},<small>{value.ValorCentavos}</small></span></div>
                                                : null
                                            }

                                            {
                                                value.QuantidadeEstoque <= 0 ? <div style={{ position: 'absolute', fontSize: 14, color: 'red', fontWeight: '700', right: 10 }}>Indisponível</div>
                                                    : null
                                            }
                                        </div>

                                        {this.state.signed ?
                                            <div>
                                                {
                                                    value.QuantidadeEstoque > 0 &&
                                                    <div className="product-floating-btn">
                                                        <button className="btn btn-primary btn-shadow btn-sm" type="button" onClick={() => this.selecionarProduto(value)}>+<i className="ci-cart fs-base ms-1" /></button>
                                                    </div>
                                                }
                                            </div>

                                            :
                                            <div style={{ alignSelf: 'flex-end' }}>
                                                <button className="btn btn-accent btn-shadow btn-sm" type="button" onClick={() => this.selecionarProduto(value)}>Mais detalhes<i className="ci-add-circle ms-2" /></button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    :
                    <div>
                        {
                            !this.user.loading &&
                            <div className="container py-5 mb-lg-3">
                                <div className="row justify-content-center pt-lg-4 text-center">
                                    <div className="col-lg-5 col-md-7 col-sm-9"><img className="d-block mx-auto mb-5" src={notFoundIMG} width={340} alt="404 Error" />
                                        <h1 className="h3">Nada encontrado</h1>
                                        <h3 className="h5 fw-normal mb-4">Não há produtos com o filtro definido</h3>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default ListaProdutos