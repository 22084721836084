/* eslint-disable */

import React, { Component, Suspense } from 'react';
import app_home from '../../assets/img/home/app_home.png';
import ProdutosHome from '../produtos/produtos-home';

class Download extends Component {

    componentDidMount() {

    }



    render() {
        return (
            <div>
                {/* Page*/}
                <main className="offcanvas-enabled" style={{ paddingTop: '5rem' }}>
                    <section className="ps-lg-4 pe-lg-3 pt-4">
                        <div className="px-3 pt-2">
                            <div className="d-flex flex-wrap justify-content-between align-items-center pt-1 border-bottom pb-4 mb-4">
                                <div className="widget pb-2 mb-4">
                                    <h3 className="widget-title text-dark pb-1">Disponível para</h3>
                                    {/* <br></br> */}
                                    <div style={{fontSize: 14, marginTop: -15, marginBottom: 8, color: 'GrayText'}}>
                                        Baixe agora e modernize sua maneira de realizar pedidos.
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        <div className="me-2 mb-2"><a className="btn-market btn-apple" href="https://apps.apple.com/us/app/distribuidora-ipanema/id1575424987" target="_blank" role="button"><span className="btn-market-subtitle">Disponível para</span><span className="btn-market-title">App Store</span></a></div>
                                        <div className="mb-2"><a className="btn-market btn-google" href="https://play.google.com/store/apps/details?id=prime.ipanema.br" target="_blank" role="button"><span className="btn-market-subtitle">Disponível para</span><span className="btn-market-title">Google Play</span></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        )
    }
}

export default Download