export const config = {
    //DEBUG
    // idCliente: 'SwDbag90KORPds0CkHDMPRH88NP2',
    // versao: 10.2,
    // firebase: {
    //     apiKey: "AIzaSyDMWVkdwd2pcdUukN6j1ZqCy8ivSB89wuo",
    //     authDomain: "ipamenadistribuidoradebug.firebaseapp.com",
    //     databaseURL: "https://ipamenadistribuidoradebug-default-rtdb.firebaseio.com",
    //     projectId: "ipamenadistribuidoradebug",
    //     storageBucket: "ipamenadistribuidoradebug.appspot.com",
    //     messagingSenderId: "230940799412",
    //     appId: "1:230940799412:web:d303c399dad78ddce542e1",
    //     measurementId: "G-8RGKXBMWMQ"
    // },
    //Produção
    idCliente: 'mX6GE5LSX3aqEpNaLjECIDhHinv2',
    versao: 10.2,
    firebase: {
        apiKey: "AIzaSyA8AGT6yVb78YrDwHPLprPkqjYeaSKoZK4",
        authDomain: "ipanemadistribuidoraproducao.firebaseapp.com",
        projectId: "ipanemadistribuidoraproducao",
        storageBucket: "ipanemadistribuidoraproducao.appspot.com",
        messagingSenderId: "254385930857",
        appId: "1:254385930857:web:dab1d6e310b8fe31efd8f8",
        measurementId: "G-77EG1GYNWF"
    },
};

export default config;