import firebase from '../environments/environment';
import config from '../environments/config';

const uidVendedor = config.idCliente;

class UsuarioService {
    // constructor() {
    //     // console.log(firebase.auth().currentUser);
    // }

    //Saber se o sistema esta em manutencao ou nao
    getStatusServidor() {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Sistema/Web');
    }

    getStatusLoja() {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Sistema/Loja/');
    }

    recuperarSenha(email) {
        return firebase.auth().sendPasswordResetEmail(email).then(resp => {
            return {
                status: true,
                mensagem: 'Solicitação realizada com sucesso. Por favor verifique seu e-mail!'
            }
        }).catch(err => {
            if (err.code === "auth/user-not-found") {
                return {
                    status: false,
                    mensagem: 'Este e-mail não está cadastrado no sistema'
                }
            } else {
                return {
                    status: false,
                    mensagem: 'Houve um erro na solicitação, por favor tente novamente mais tarde'
                }
            }
        });
    }

    login(email, password) {
        return firebase.auth().signInWithEmailAndPassword(email, password)
            .catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                if (errorCode === 'auth/wrong-password') {
                    setTimeout(() => {
                        alert('Senha incorreta');
                    }, 900)
                } else {
                    setTimeout(() => {
                        alert(errorMessage);
                    }, 900)
                }
                console.log(error);
            });
    }

    singOut() {
        return firebase.auth().signOut();
    }

    getCategorias() {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Grupos');
    }

    getProdutos() {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Produtos').limitToLast(15);
    }

    async getProdutosCatalogo() {
        console.log('getProdutosCatalogo');
        const resp = firebase.functions().httpsCallable("obterProdutosLoja");

        try {
            return await resp();
        } catch (error_1) {
            console.log(error_1);
            return {
                Status: false,
                Mensagem: `Erro desconhecido recuperar produtos da loja. ${error_1}`
            };
        }

        // return categoria ? firebase.database().ref('Clientes/' + uidVendedor + '/Produtos/').orderByChild('IDGrupo').equalTo(categoria) : firebase.database().ref('Clientes/' + uidVendedor + '/Produtos');
    }

    async getProdutoCatalogo(idProduto) {
        console.log('getProdutoCatalogo', idProduto);

        try {
        const resp = firebase.functions().httpsCallable("obterProdutoLoja");
            return await resp({ IDProduto: idProduto});
        } catch (error_1) {
            console.log(error_1);
            return {
                Status: false,
                Mensagem: `Erro desconhecido recuperar produto da loja. ${error_1}`
            };
        }
    }

    setSolicitacaoCadastro(map) {
        map.Timestamp = firebase.database.ServerValue.TIMESTAMP;
        return firebase.database().ref('Clientes/' + uidVendedor + '/SolicitacoesCadastro').push(map);
    }

    getGruposPromocionaisUsuario(idUsuario) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Usuarios/' + idUsuario + '/GruposPromocionais');
    }

    getStatusGrupoPromocional(idGrupo) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/GruposPromocionais/' + idGrupo + '/Status');
    }

    getGruposTodosPromocionaisVendedor() {
        return firebase.database().ref('Clientes/' + uidVendedor + '/GruposPromocionais/');
    }

    getCredito(idUsuario) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Usuarios/' + idUsuario + '/CreditoDisponivel');
    }

    getProdutoID(idProduto) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Produtos/' + idProduto);
    }

    getEndereco(idUsuario) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Usuarios/' + idUsuario);
    }

    getCidades(uf, cidade) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Cidades/' + uf).orderByChild('Cidade').equalTo(cidade);
    }

    getRotas(idCidade) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Rotas/' + idCidade);
    }

    getIDPedido() {
        let auxID;
        return firebase.database().ref('Clientes/' + uidVendedor + '/ContPedidos/').transaction(mutableData => {
            if (mutableData !== null) {
                auxID = mutableData + 1;
                return (mutableData + 1);
            }
            return mutableData;
        }).then(resp => {
            return auxID;
        }).catch(err => {
            console.log(err)
            return false
        });
    }

    setFinalizarCompra(idCompra, map) {
        map.Timestamp = firebase.database.ServerValue.TIMESTAMP;
        return firebase.database().ref('Clientes/' + uidVendedor + '/Pedidos/' + idCompra).set(map);
    }

    verificarEstoqueProdutos(produtos, idPedido, map) {
        const resp = firebase.functions().httpsCallable("debitarEstoquesProdutos");

        return resp({ Produtos: produtos, IDPedido: idPedido, Map: map }).then((resp, error) => {
            return resp;
        });
        // return 
    }

    enviarPedido(produtos, map) {
        const resp = firebase.functions().httpsCallable("enviarPedido");

        return resp({ Produtos: produtos, Map: map }).then((resp, error) => {
            return resp;
        });
        // return 
    }

    estornarProdutosEstoqueCompraCancelada(arrayPedidos) {
        const resp = firebase.functions().httpsCallable("estornarProdutosEstoqueCompraCancelada");

        return resp({ IDPedidos: arrayPedidos }).then((resp, error) => {
            return resp;
        });
    }

    getPedidos(idUsuario) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Pedidos').orderByChild('IDUsuario').equalTo(idUsuario);
    }

    getNomeVendedor(idFuncionario) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Funcionarios/' + idFuncionario + '/Nome');
    }

    getPedidoID(idPedido) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Pedidos/' + idPedido);
    }

    getValoresMinimos() {
        return firebase.database().ref('Clientes/' + uidVendedor + '/ValoresMinimos/');
    }

    getBuscaInicioNomeProduto(nome) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Produtos/').orderByChild('NomeBusca').startAt(nome.toString().toUpperCase()).endAt(nome.toString().toLowerCase() + '\uf8ff').limitToLast(50);
    }

    getProdutosNaoAutenticadoHome() {
        return firebase.functions().httpsCallable("getSortQuinzeProdutosUsuarioNaoAutenticado").call().then((resp, error) => {
            return resp;
        })

        // return firebase.database().ref('Clientes/' + uidVendedor + '/Sistema/Web');
    }

    //Recupera as cidades cadastradas
    getDadosEmpresa() {
        return firebase.database().ref('Clientes/' + uidVendedor + '/DadosEmpresa');
    }

    //Recupera os clientes
    getClienteByID(idCliente) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Usuarios/' + idCliente);
    }

    getUFCidades() {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Cidades');
    }

    getStatusCliente(idCliente) {
        return firebase.database().ref('Usuarios/' + idCliente + '/Status');
    }

    setTimestampPlataforma(idCliente) {
        const map = {
            Timestamp: firebase.database.ServerValue.TIMESTAMP,
            TipoAcesso: 'Web',
            PlataformaAcesso: 'Computador'
        }
        return firebase.database().ref('Clientes/' + uidVendedor + '/Usuarios/' + idCliente).update(map);
    }

    getBloqueiaCompraPagamentoPendente(idCliente) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/Usuarios/' + idCliente + '/CompraPagamentoPendente');
    }

    getCreditoVoucher(idUsuario) {
        return firebase.database().ref('Clientes/' + uidVendedor + '/CreditoVoucherClientes/' + idUsuario + '/TotalVoucher');
    }

    getTotalPedidoCreditoVoucher(idUsuario, totalPedido, idPedido) {
        let auxTotalPedido = totalPedido;
        let auxTotalCredito = 0;
        let bSalvarLogCredito = false;
        let totalCreditoUtilizado = 0;

        return firebase.database().ref('Clientes/' + uidVendedor + '/CreditoVoucherClientes/' + idUsuario + '/TotalVoucher').transaction(totalCredito => {
            if (totalCredito !== null) {
                if (totalCredito !== 0) {
                    bSalvarLogCredito = true;
                    if (totalCredito - auxTotalPedido <= 0) {
                        auxTotalPedido = auxTotalPedido - totalCredito;
                        auxTotalCredito = totalCredito;
                        totalCreditoUtilizado = totalCredito;
                        totalCredito = 0;
                        return totalCredito;
                    }

                    if (totalCredito - auxTotalPedido > 0) {
                        totalCreditoUtilizado = (auxTotalPedido - totalCredito) + totalCredito;
                        auxTotalPedido = ((auxTotalPedido - totalCredito) + totalCredito) *-1;
                        totalCredito = totalCredito - totalPedido;
                        auxTotalCredito = totalCredito;
                        return totalCredito;
                    }
                    return totalCredito;
                }
            }
            return totalCredito;
        }).then(resp => {
            if (bSalvarLogCredito)
                this.setMapCreditoVoucher(idUsuario, idPedido, totalCreditoUtilizado);

            return auxTotalPedido;
        }).catch(err => {
            console.log(err)
            return auxTotalPedido
        });
    }

    setMapCreditoVoucher(idUsuario, idPedido, auxTotalCredito) {
        const map = {
            Descricao: 'Uso de crédito em pedido',
            PedidoReferencia: Number(idPedido),
            Timestamp: firebase.database.ServerValue.TIMESTAMP,
            Tipo: 'Compra',
            ValorVoucher: Number(auxTotalCredito) *-1
        }

        return firebase.database().ref('Clientes/' + uidVendedor + '/CreditoVoucherClientes/' + idUsuario + '/Historico').push(map);
    }



    tratarUnidade(tratar) {
        let unidade = tratar.toLowerCase()
        if (unidade.includes('caixa')) {
            return 'Cx';
        } else
            if (unidade.includes('fardo')) {
                return 'Fd';
            } else
                if (unidade.includes('litro')) {
                    return 'L';
                } else
                    if (unidade.includes('peça')) {
                        return 'Pc';
                    } else
                        if (unidade.includes('unidade')) {
                            return 'Uni';
                        } else
                            if (unidade.includes('kilograma')) {
                                return 'Kg';
                            } else
                                return unidade.substring(0, unidade.length >= 3 ? 3 : unidade.length);
    }

    corrigirString(s) {
        if (s.toString().length === 1) {
            return '0' + s;
        } else
            return s;
    }

    tratarData(timestamp) {
        let date = new Date();
        date.setTime(timestamp);

        return this.corrigirString(date.getDate()) + '/' + this.corrigirString(date.getMonth() + 1) + '/' + date.getFullYear();
    }

    tratarHora(timestamp) {
        let date = new Date();
        date.setTime(timestamp);

        return this.corrigirString(date.getHours()) + ':' + this.corrigirString(date.getMinutes());
    }

    tratarDataHora(timestamp) {
        let date = new Date();
        date.setTime(timestamp);

        return this.corrigirString(date.getDate()) + '/' + this.corrigirString(date.getMonth() + 1) + '/' + date.getFullYear() + ' às ' + this.corrigirString(date.getHours()) + ':' + this.corrigirString(date.getMinutes());
    }

    tratarDataArquivo(timestamp) {
        if (timestamp && timestamp !== undefined) {
            let date = new Date();
            date.setTime(timestamp);

            return this.corrigirString(date.getDate()) + '_' + this.corrigirString(date.getMonth() + 1) + '_' + date.getFullYear();
        } else
            return 'não informado'
    }
}

export default UsuarioService