import './theme/css/theme.min.css'; // Theme Cartzilla Oficial, otimizado
// import './theme/css/theme.css'; // Theme Cartzilla para verificar o css em testes e alteração
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import Layout from './components/layout/layout';

import './theme/custom.css'

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Layout />
    </div>
  );
}

export default App;
