/* eslint-disable */

import React, { Component, Suspense } from 'react';
import app_home from '../../assets/img/home/app_home.png';
import ProdutosHome from '../produtos/produtos-home';

class TermosUsuarios extends Component {

    componentDidMount() {

    }



    render() {
        return (
            <div>
                {/* Page*/}
                <main className="offcanvas-enabled" style={{ paddingTop: '5rem' }}>
                    <section className="ps-lg-4 pe-lg-3 pt-4" style={{ textAlign: 'justify' }}>
                        <div className="px-3 pt-2">
                            <div className="d-flex flex-wrap justify-content-between align-items-center pt-1 border-bottom pb-4 mb-4">
                                <h2 className="h3 mb-0 pt-3 me-3">Termos de usuário e políticas de privacidade</h2>

                                <section className="pt-3 pt-md-4">
                                    {/* <h2 className="h5 mb-0 pt-3 me-3">CONTATOS | HORÁRIOS</h2> */}
                                    {/* <div style={{ marginTop: 10 }}></div> */}
                                    A <b>DISTRIBUIDORA IPANEMA LTDA</b>, inscrita sob CNPJ 26.257.906/0001-80, é responsável pelo site www.distribuidoraipanema.com.br e aplicativos disponibilizados através das plataformas oficiais: App Store e Play Store. Respeitamos as suas informações com o pleno rigor da Lei Geral de Proteção de Dados Pessoais (LGPD) – lei 13.709/18.
                                    <div style={{ marginTop: 10 }}></div>
                                    Nossa política de privacidade foi elaborada para afirmar nosso compromisso com os dados e a segurança das informações fornecidas pelos usuários do site e aplicativos. <b>Leia com atenção as informações abaixo</b>, pois, ao navegar no site e aplicativos o usuário concordará com os termos e condições previstos e com todos os outros avisos que possam aparecer, pertinentes a essa política.
                                    <br></br>
                                    <br></br>

                                    <h2 className="h5 mb-0 pt-3 me-3">Da navegação no site e aplicativos próprios</h2>
                                    <div style={{ marginTop: 10 }}></div>
                                    A <b>DISTRIBUIDORA IPANEMA</b> armazena somente os dados preenchidos por seus usuários no ato da solicitação de cadastro (E-mail, Nome, Telefone, CPF ou CNPJ e Cidade); as informações recebidas advindas da solicitação são armazenadas em ambiente controlado e seguro. No site hospedado no endereço www.distribuidoraipanema.com.br ou através do aplicativo oficial, o usuário faz a requisição de seu cadastro, ora denominado, “Pré-cadastro”. Cabe a <b>DISTRIBUIDORA IPANEMA</b> fazer a análise se aceita o cadastro daquele usuário ou não, mediante prévias análises junto aos órgãos de créditos, tais como Serasa e SPC. Uma vez aprovado esse cadastro o usuário passa a ser tratado como CLIENTE da Distribuidora Ipanema.
                                    <div style={{ marginTop: 10 }}></div>
                                    Transformado em CLIENTE da Distribuidora Ipanema o mesmo desfrutará da possibilidade de fazer os pedidos on-line dos produtos comercializados pela mesma, mediante um Login e senha fornecido ao CLIENTE com todos os padrões de segurança. O usuário poderá acessar seu histórico de compras, ter acesso aos seus dados de cadastro (sem edição) e terá livre acesso ao suporte fornecido pela empresa.
                                    <br></br>
                                    <br></br>

                                    <h2 className="h5 mb-0 pt-3 me-3">Informações de navegação</h2>
                                    <div style={{ marginTop: 10 }}></div>
                                    O site da <b>DISTRIBUIDORA IPANEMA</b> recebe e armazena automaticamente, informações em seus servidores sobre as atividades advindas do navegador, incluindo o endereço IP e a página acessada, através dos “cookies”.
                                    <div style={{ marginTop: 10 }}></div>
                                    Os “cookies” permitem o reconhecimento dos dispositivos que acessam os sites e acompanham a navegação do usuário dentro das páginas, de maneira a personalizá-la de acordo com o perfil de cada cliente. Os “cookies” não armazenam nenhuma informação pessoal, apenas contêm os dados de login do usuário.
                                    <br></br>
                                    <br></br>

                                    <h2 className="h5 mb-0 pt-3 me-3">Uso de informações e privacidade dos dados</h2>
                                    <div style={{ marginTop: 10 }}></div>
                                    Os dados de cadastro realizados através dos formulários são para uso exclusivo dos serviços prestados pela Distribuidora Ipanema. A <b>DISTRIBUIDORA IPANEMA</b> compromete-se a não divulgar os dados cadastrais dos usuários a terceiros, exceto quando essas informações forem necessárias para o processo de entrega de informação, como no caso da solicitação de um usuário final ou por alguma solicitação judicial.
                                    <div style={{ marginTop: 10 }}></div>
                                    Informamos que, caso haja solicitação formal, por qualquer Autoridade Pública, devidamente fundamentada, o usuário do site autoriza expressamente a <b>DISTRIBUIDORA IPANEMA</b> a encaminhar os dados cadastrais solicitados, independente de notificação prévia ao usuário.
                                    <div style={{ marginTop: 10 }}></div>
                                    Caso o usuário opte pelo encerramento do seu cadastro, este deverá entrar em contato com o suporte da <b>DISTRIBUIDORA IPANEMA</b> requerendo a desativação de sua conta. Na hipótese da solicitação de remoção dos dados cadastrais, a <b>DISTRIBUIDORA IPANEMA</b> compromete-se a cumprir a política de privacidade de acordo com as normas da LGPD, contudo, o histórico de compras será mantido referenciando à um usuário anônimo. Este histórico de compras é de uso EXCLUSIVO da <b>DISTRIBUIDORA IPANEMA</b> e não pode ser removido, devido a situações fiscais.
                                    <br></br>
                                    <br></br>

                                    <h2 className="h5 mb-0 pt-3 me-3">Envio de comunicados e mensagens publicitárias</h2>
                                    <div style={{ marginTop: 10 }}></div>
                                    O sistema envia automaticamente e-mails de alerta notificando o usuário cadastrado sobre atividades que realizou no site. Portanto, é de suma importância manter seu cadastro atualizado. A <b>DISTRIBUIDORA IPANEMA</b> poderá enviar comunicados e mensagens publicitárias aos usuários fazendo uso dos dados fornecidos pelo próprio USUÁRIO como: e-mail, SMS, WhatsApp e outros meios. Os comunicados, newsletters, avisos de ofertas e mensagens publicitárias enviadas terão obrigatoriamente, opção de cancelamento do envio daquele tipo de mensagem. A solicitação será atendida e o USUÁRIO/CLIENTE notificado sobre realização desta execução.
                                    <br></br>
                                    <br></br>

                                    <h2 className="h5 mb-0 pt-3 me-3">Modificações de nossa política de privacidade</h2>
                                    <div style={{ marginTop: 10 }}></div>
                                    A <b>DISTRIBUIDORA IPANEMA</b> reserva-se no direito de alterar sua política de privacidade a qualquer momento visando sua constante melhoria e aprimoramento. Ao utilizar os nossos serviços, o usuário concorda e aceita os termos da política de privacidade.
                                    <br></br>
                                    <br></br>

                                    <h2 className="h5 mb-0 pt-3 me-3">Contatos para Dúvidas, Sugestões, Reclamações</h2>
                                    <div style={{ marginTop: 10 }}></div>
                                    Caso tenha qualquer dúvida, sugestão, reclamação sobre segurança e privacidade ou sobre qualquer outro serviço prestado pelo site e aplicativos, nos contatar através do e-mail <a className="nav-link-style fs-md" href="mailto:distribuidoraipanema@hotmail.com">distribuidoraipanema@hotmail.com</a> e WhatsApp: <a className="nav-link-style fs-md" href="https://api.whatsapp.com/send?phone=5532988146312&text=Olá!" target="_blank">+55 (32) 9.8814-6312</a>.
                                    <br></br>
                                    <br></br>

                                    <h2 className="h5 mb-0 pt-3 me-3">Versões</h2>
                                    <div style={{ marginTop: 10 }}></div>
                                    1.0 - 06/07/2021
                                    <br></br>
                                    <br></br>

                                </section>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        )
    }
}

export default TermosUsuarios