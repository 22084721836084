import React, { Component } from 'react';

import UsuarioService from '../../service/usuario.service';
import UserContext from '../../contexts/user';

import master_card from '../../assets/img/cartoes/master_card.png';
import visa_cartao from '../../assets/img/cartoes/visa_cartao_2.png';
import elo_cartao from '../../assets/img/cartoes/elo_cartao_2.png';
import american_express from '../../assets/img/cartoes/american_express.png';
import ListaProdutos from './lista-produtos';
import logo_ipanema_png from '../../assets/logo_ipanema.png';
import logo_ipanema_svg from '../../assets/logo_ipanema.svg';

const usuarioService = new UsuarioService();

const styleCartoes = {
    marginTop: 6,
    marginRight: 5,
}

class PedidoDetalhado extends Component {
    static contextType = UserContext;

    user;

    constructor(props) {
        super(props);

        this.state = {
            signed: false,
            pedido: null,
            quantidade: 1,
            valorTotal: 0,
            credito: 0,
        }
    }

    componentDidMount() {
        this.user = this.context;

        if (this.user.pedidoSelecionado) {
            this.setState({ pedido: JSON.parse(this.user.pedidoSelecionado), signed: this.user.signed });
            setTimeout(() => {
                const aux = this.user.loading ? this.user.updateLoading() : false;
            }, 1000);
        } else {
            this.user.updateLoading();
            const idPedido = this.props.location.search.substring(1, this.props.location.search.length);
            usuarioService.getPedidoID(idPedido).once('value', snapshot => {
                if (snapshot) {
                    const dados = snapshot.val();
                    const auxValor = dados.Total.toFixed(2).replace('.', ',');

                    this.setState({
                        pedido: {
                            Key: snapshot.key,
                            Bairro: dados.Bairro,
                            BandeiraCartao: dados.BandeiraCartao,
                            CEP: dados.CEP,
                            Cidade: dados.Cidade,
                            Complemento: dados.Complemento,
                            Email: dados.Email,
                            FormaPagamento: dados.FormaPagamento,
                            IDUsuario: dados.IDUsuario,
                            IDVendedor: dados.IDVendedor,
                            Logradouro: dados.Logradouro,
                            Nome: dados.Nome,
                            Numero: dados.Numero,
                            Observacao: dados.Observacao,
                            Produtos: dados.Produtos,
                            Referencia: dados.Referencia,
                            Status: dados.Status,
                            StatusPagamento: dados.StatusPagamento,
                            Telefone: dados.Telefone,
                            TelefoneSecundario: dados.TelefoneSecundario,
                            Timestamp: dados.Timestamp,
                            Total: dados.Total,
                            ValorString: auxValor,
                            UF: dados.UF,
                            ValorReal: auxValor.substring(0, auxValor.indexOf(",")),
                            ValorCentavos: auxValor.substring((auxValor.length - 2), auxValor.length),
                            Data: usuarioService.tratarData(dados.Timestamp),
                            Hora: usuarioService.tratarHora(dados.Timestamp),
                            DataHoraConfirmado: usuarioService.tratarDataHora(dados.Timestamp),
                            DataHoraSeparacao: usuarioService.tratarDataHora(dados.TimestampSeparacao),
                            DataHoraPrevisaoRota: usuarioService.tratarData(dados.TimestampPrevisaoRota),
                            DataHoraRota: usuarioService.tratarDataHora(dados.TimestampRota),
                            DataHoraEntregue: usuarioService.tratarDataHora(dados.TimestampEntregue),
                            DataCancelamento: usuarioService.tratarData(dados.TimestampCancelado),
                            PrazoBoleto: dados.PrazoBoleto,
                            NumeroParcelas: dados.NumeroParcelas,
                            IDGrupoPromocional: dados.IDGrupoPromocional,
                            NomeGrupoPromocional: dados.NomeGrupoPromocional,
                            PorcentagemDesconto: dados.PorcentagemDesconto,
                            ValorRealDesconto: dados.ValorRealDesconto,
                            ValorCentavosDesconto: dados.ValorCentavosDesconto,
                            ValorDesconto: dados.ValorDesconto,
                            DescontoGrupoPromocional: dados.DescontoGrupoPromocional,
                            PDFbase64: dados.PDFbase64,
                            PDFbase64Parcela2: dados.PDFbase64Parcela2,
                            VencimentoBoleto: dados.TimestampVencimento,
                            DataPedido: dados.TimestampVencimento,
                            ValorDesconto: dados.ValorDesconto
                        }
                    });

                    this.user.setProdutosPedidoSelecionadoURL(JSON.stringify(dados.Produtos));

                    setTimeout(() => {
                        const aux = this.user.loading ? this.user.updateLoading() : false;
                    }, 1000);
                }
            });
        }
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;

        if (this.user.signed !== prevState.signed) {
            this.setState({ signed: this.user.signed });
        }
    }

    imprimir(e) {
        e.preventDefault();

        usuarioService.getDadosEmpresa().once('value', snap => {
            if (snap) {
                const dadosEmpresa = snap.val()
                let produtos = this.state.pedido.Produtos;
                let windowPopup;
                let auxProdutos = '';

                for (let i = 0; i < produtos.length; i++) {
                    if (!produtos[i].DescontoGrupoPromocional) {
                        auxProdutos = auxProdutos +
                            '<tr>' +
                            '<td><div style="display: inline-block">' + produtos[i].CodigoProduto + '</div></td>' +
                            '<td><div style="margin-left: 5px; display: inline-block">' + produtos[i].Nome + '</div></td>' +
                            '<td><div style="margin-left: 10px; display: inline-block">' + produtos[i].QuantidadeComprada + ' ' + usuarioService.tratarUnidade(produtos[i].Unidade) + '</div></td>' +
                            '<td><div style="margin-left: 10px; display: inline-block"> R$ ' + produtos[i].ValorString + '</div></td>' +
                            '<td><div style="margin-left: 10px; display: inline-block"> R$ ' + (produtos[i].QuantidadeComprada * produtos[i].Valor).toFixed(2).replace('.', ',') + '</div></td>' +
                            '</tr>'
                    } else {
                        auxProdutos = auxProdutos +
                            '<tr>' +
                            '<td><div style="display: inline-block">' + produtos[i].CodigoProduto + '</div></td>' +
                            '<td><div style="margin-left: 5px; display: inline-block">' + produtos[i].Nome + '<div style="font-size: 10px; display: inline-block"> - ' + produtos[i].NomeGrupoPromocional + ' | ' + produtos[i].PorcentagemDesconto + '% </div></div></td>' +
                            '<td><div style="margin-left: 10px; display: inline-block">' + produtos[i].QuantidadeComprada + ' ' + usuarioService.tratarUnidade(produtos[i].Unidade) + '</div></td>' +
                            '<td><div style="margin-left: 10px; display: inline-block"> R$ ' + produtos[i].ValorRealDesconto + ',' + produtos[i].ValorCentavosDesconto + '</div></td>' +
                            '<td><div style="margin-left: 10px; display: inline-block"> R$ ' + (produtos[i].QuantidadeComprada * produtos[i].ValorDesconto).toFixed(2).replace('.', ',') + '</div></td>' +
                            '</tr>'
                    }
                }

                //FUNCIONAL
                windowPopup = window.open('Pedido', '_blank', 'width=1000,height=' + (320 + (produtos.length * 30))); //Uma impressão com um único produto, === 320 de altura, a cada produto aumentar 10px
                windowPopup.document.open();

                usuarioService.getClienteByID(this.state.pedido.IDUsuario).once('value', snapB => {
                    if (snap) {
                        const dados = snapB.val();
                        const cpfCNPJ = dados.CPF && dados.CPF.length > 0 ? ('<div style="font-weight: 550; display: inline-block; margin-right: 5px;"> CPF: </div>' + dados.CPF) : ('<div style="font-weight: 550; display: inline-block; margin-right: 5px;"> CNPJ: </div>' + dados.CNPJ);

                        usuarioService.getNomeVendedor(this.state.pedido.IDVendedor).once('value', snapC => {
                            if (snap) {
                                const nomeVendedor = snapC.val()

                                windowPopup.document.write(
                                    '<html style="width: auto; margin: 16px">' +
                                    '<head><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">' +
                                    '<style>table.minimalistBlack {  border: 2px solid #000000;  width: 100%;  text-align: left;}table.minimalistBlack td, table.minimalistBlack th {  border: 1px solid #000000;  padding: 5px 5px;}table.minimalistBlack tbody td {  font-size: 13px;}table.minimalistBlack thead {    border-bottom: 2px solid #000000;}table.minimalistBlack thead th {  font-size: 15px;  font-weight: bold;  color: #000000;  text-align: center;}table.minimalistBlack tfoot {  font-size: 14px;  font-weight: bold;  color: #000000;  border-top: 2px solid #000000;}table.minimalistBlack tfoot td {  font-size: 14px;}</style>' +
                                    '<body>' +
                                    //Inicio ROW
                                    '<div class="row mt-2">' +

                                    //HEADER
                                    '<div class="col-sm text-center">' +
                                    '<table class="minimalistBlack">' +
                                    '<thead>' +
                                    '<tr>' +
                                    '<th colspan="5" style="text-align: center; height: 65px">' + dadosEmpresa.RazaoSocial + '<div style="white-space:pre-line"></div>' +
                                    // '<th colspan="5" style="text-align: center; height: 65px"><img style="width: 70px; position: absolute; margin-top: -5px; margin-left: -100px" src="require("../../assets/logo_ipanema.png")">' + dadosEmpresa.RazaoSocial + '<div style="white-space:pre-line"></div>' +
                                    // '<th colspan="5" style="text-align: center; height: 65px"><img style="width: 70px; position: absolute; margin-top: -5px; margin-left: -100px" src="' + { logo_ipanema_png } + '">' + dadosEmpresa.RazaoSocial + '<div style="white-space:pre-line"></div>' +
                                    '<div style="font-weight: normal; font-size: 12px">Pedido Nº ' + this.state.pedido.Key + ' - ' + this.state.pedido.Data + ' às ' + this.state.pedido.Hora + '</div></th>' +
                                    '</tr>' +
                                    '</thead>' +
                                    //HEADER FIM
                                    //RODAPÉ
                                    // '<tfoot>' +
                                    // '<tr>' +
                                    // '<td colspan="5" style="text-align: center">Data: ______/______/______  Valor <div style="white-space:pre-line"></div> <div style="margin-left: -300px; margin-top: 10px"> Assinatura: </div></td>' +
                                    // '</tr>' +
                                    // '</tfoot>' +
                                    //RODAPÉ FIM
                                    '<tbody>' +
                                    //LINHA 1
                                    '<tr>' +
                                    '<td colspan="5"><div style="font-weight: 550; display: inline-block; margin-right: 5px;">Cliente: </div>' + this.state.pedido.Nome + '<div style="white-space:pre-line"></div>' +
                                    cpfCNPJ +
                                    '</td>' +
                                    '</tr>' +
                                    //LINHA 1 FIM
                                    //LINHA 2 HEADER
                                    '<tr style="font-weight: 700">' +
                                    '<td>Cód.</td>' +
                                    '<td>Produto</td>' +
                                    '<td>Qtde.</td>' +
                                    '<td>Valor unitário</td>' +
                                    '<td>Subtotal</td>' +
                                    '</tr>' +
                                    //LINHA 2 HEADER FIM
                                    //LINHA 3 LOOP Produtos
                                    auxProdutos +
                                    //LINHA 3 LOOP Produtos FIM

                                    //LINHA TOTAL
                                    '<tr>' +
                                    '<td colspan="5" style="font-size: 14px; font-weight: 600; text-align: end;">Total: R$ ' + this.state.pedido.ValorReal + ',' + this.state.pedido.ValorCentavos + '</td>' +
                                    '</tr>' +
                                    //LINHA TOTAL FIM
                                    //LINHA FORMA PAGAMENTO
                                    '<tr>' +
                                    '<td colspan="2" style="font-weight: 600;">Forma de pagamento: ' + this.state.pedido.FormaPagamento + (this.state.pedido.FormaPagamento === 'Boleto' ? (' - ' + this.state.pedido.PrazoBoleto) + ' dias' : '') +
                                    '</td>' +
                                    '<td colspan="3">' +
                                    '<div style="text-align: end;">Data da emissão: ' + usuarioService.tratarData(new Date().getTime()) + '</div>' +
                                    '</td>' +
                                    '</tr>' +
                                    //LINHA FORMA PAGAMENTO FIM
                                    //LINHA VENDEDOR
                                    '<tr>' +
                                    '<td colspan="5" style="font-weight: 600;">Vendedor: ' + nomeVendedor + '</td>' +
                                    '</tr>' +
                                    //LINHA VENDEDOR FIM
                                    '</tbody>' +
                                    '</table>' +
                                    //Fechamento ROW
                                    '</div>' +
                                    '</body>' +
                                    '</html>');
                            }
                        });
                    }
                });
            }
        });
    }

    imprimirBoleto(e, parcela) {
        e.preventDefault();

        const linkSource = `data:application/pdf;base64,${parcela === 1 ? this.state.pedido.PDFbase64 : this.state.pedido.PDFbase64Parcela2}`;
        const downloadLink = document.createElement("a");
        const fileName = `Boleto pedido: ${this.state.pedido.Key}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    // loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>
    render() {
        let props = this.context;

        return (
            <div>
                {this.state.pedido &&
                    <main className="offcanvas-enabled" style={{ paddingTop: '5rem' }}>

                        {/* <a className="step-item active">
                            <div className={this.state.pedido.Status === 'Confirmado' ? 'step-progress active current' : 'step-item'}><span className="step-count">1</span></div>
                            <div className="step-label"><i className="ci-cart" />Confirmado</div>
                        </a> */}

                        <section className="ps-lg-4 pe-lg-3 pt-5 bg-dark">
                            {this.state.pedido.Status !== 'Cancelado' ?
                                <div className="steps steps-light pt-2 pb-3 mb-5">
                                    <a className={this.state.pedido.Status === 'Confirmado' ? 'step-item active current' : 'step-item active'}>
                                        <div className="step-progress"><span className="step-count">1</span></div>
                                        <div className="step-label"><i className="ci-cart" />Confirmado</div>
                                        <div style={{}}><small style={{ fontSize: 10, marginLeft: 16 }}>{this.state.pedido.DataHoraConfirmado}</small></div>
                                    </a>

                                    <a className={this.state.pedido.Status === 'Em separação' ? 'step-item active current' : (this.state.pedido.Status === 'Em rota' || this.state.pedido.Status === 'Entregue' ? 'step-item active' : 'step-item')}>
                                        <div className="step-progress"><span className="step-count">2</span></div>
                                        <div className="step-label"><i className="ci-user-circle" />Em separação</div>
                                        {
                                            this.state.pedido.Status === 'Em separação' || this.state.pedido.Status === 'Em rota' || this.state.pedido.Status === 'Entregue' &&
                                            <div style={{}}><small style={{ fontSize: 10, marginLeft: 16 }}>{this.state.pedido.DataHoraSeparacao}</small></div>
                                        }
                                    </a>

                                    <a className={this.state.pedido.Status === 'Em rota' ? 'step-item active current' : (this.state.pedido.Status === 'Entregue' ? 'step-item active' : 'step-item')}>
                                        <div className="step-progress"><span className="step-count">3</span></div>
                                        <div className="step-label"><i className="ci-package" />Em rota</div>
                                        {this.state.pedido.Status === 'Em separação' && !(this.state.pedido.DataHoraPrevisaoRota.includes('1969')) &&
                                            <div style={{}}><small style={{ fontSize: 10, marginLeft: 16 }}>*Previsão: {this.state.pedido.DataHoraPrevisaoRota}</small></div>
                                        }
                                        {(this.state.pedido.Status === 'Em rota' || this.state.pedido.Status === 'Entregue') && !(this.state.pedido.DataHoraPrevisaoRota.includes('1969')) &&
                                            <div style={{}}><small style={{ fontSize: 10, marginLeft: 16 }}>{this.state.pedido.DataHoraRota}</small></div>
                                        }


                                        {(this.state.pedido.Status != 'Em separação' && this.state.pedido.Status != 'Em rota' && this.state.pedido.Status != 'Entregue' && this.state.pedido.Status != 'Cancelado' && this.state.pedido.DataHoraPrevisaoRota) && !(this.state.pedido.DataHoraPrevisaoRota.includes('1969')) &&
                                            <div style={{}}><small style={{ fontSize: 10, marginLeft: 16 }}>*Previsão: {this.state.pedido.DataHoraPrevisaoRota}</small></div>
                                        }

                                    </a>
                                    <a className={this.state.pedido.Status === 'Entregue' ? 'step-item active current' : 'step-item'}>
                                        <div className="step-progress"><span className="step-count">4</span></div>
                                        <div className="step-label"><i className="ci-card" />Entregue</div>
                                        {this.state.pedido.Status == 'Entregue' &&
                                            <div style={{}}><small style={{ fontSize: 10, marginLeft: 16 }}>{this.state.pedido.DataHoraEntregue}</small></div>
                                        }
                                    </a>
                                </div>
                                :
                                <div className="steps steps-light pt-2 pb-3 mb-5">
                                    <a className="step-item active current">
                                        <div className="step-progress"><span className="step-count">X</span></div>
                                        <div className="step-label"><i className="ci-cart" />Cancelado</div>
                                        <div style={{}}><small style={{ fontSize: 10, marginLeft: 16 }}>{this.state.pedido.DataCancelamento}</small></div>
                                    </a>
                                </div>
                            }
                        </section>

                        <section className="ps-lg-4 pe-lg-3" style={{ marginTop: -35 }}>
                            <div className="px-3">
                                {/* Page title + breadcrumb*/}
                                <nav className="mb-4" aria-label="breadcrumb">
                                    <ol className="breadcrumb flex-lg-nowrap">
                                        <li className="breadcrumb-item"><a className="text-nowrap" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/meus-pedidos')}><i className="ci-home" />Meus pedidos</a></li>
                                        <li className="breadcrumb-item text-nowrap active" aria-current="page">Pedido detalhado ({this.state.pedido.Key})</li>
                                    </ol>
                                </nav>
                                {/* Content*/}
                                {/* Checkout form*/}
                                <form className="needs-validation" noValidate>
                                    <div className="row g-0 pb-5 mb-xl-3">
                                        {/* Delivery details*/}
                                        <div className="col-xl-6 mb-3">
                                            <div className="d-flex flex-wrap align-items-center">
                                                <h1 className="h3 mb-4">Detalhes pedido nº {this.state.pedido.Key} <small style={{ fontSize: 12, marginLeft: 16 }}>{this.state.pedido.Data} às {this.state.pedido.Hora}</small></h1>
                                                <button className="btn btn-light btn-shadow  mb-4" style={{ marginLeft: 40, fontSize: 12 }} onClick={e => this.imprimir(e)}><i className="ci-printer fs-lg me-2" style={{ fontSize: 5 }} />Imprimir</button>
                                                {this.state.pedido.PDFbase64 &&
                                                    <button className="btn btn-light btn-shadow  mb-4" style={{ marginLeft: 40, fontSize: 12 }} onClick={e => this.imprimirBoleto(e, 1)}><i className="ci-printer fs-lg me-2" style={{ fontSize: 5 }} />Imprimir boleto</button>
                                                }
                                                {this.state.pedido.PDFbase64Parcela2 &&
                                                    <button className="btn btn-light btn-shadow  mb-4" style={{ marginLeft: 40, fontSize: 12 }} onClick={e => this.imprimirBoleto(e, 2)}><i className="ci-printer fs-lg me-2" style={{ fontSize: 5 }} />Imprimir boleto (2)</button>
                                                }
                                            </div>

                                            {/* <div className="d-none d-sm-block py-2 fs-sm text-muted text-nowrap me-2">Categoria:</div> */}

                                            <div className="d-flex flex-wrap justify-content-between">
                                                <div className="bg-light d-flex align-items-center  rounded-3 border px-3 mb-4 me-3 py-2">
                                                    <div style={{ flexDirection: 'row', display: 'inline-flex' }} >
                                                        <div className="fs-ms text-muted">Status Pagamento:</div>
                                                        <div className="fs-ms fw-medium text-heading" style={{ marginLeft: 8 }}>{this.state.pedido.StatusPagamento}</div>
                                                    </div>
                                                </div>
                                            </div>


                                            <h2 className="h6 mb-4">Dados para entrega</h2>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center rounded-3 border py-2 px-3 mb-4">
                                                <div className="d-flex align-items-center me-3 py-2">
                                                    {/* <img className="rounded-circle" src="img/grocery/newyork.jpg" width={50} alt="New York" /> */}
                                                    <div className="ps-3">
                                                        {/* <div className="fs-ms text-muted">Your city is:</div> */}
                                                        <div className="fs-md fw-medium text-heading">
                                                            <div style={{ display: 'inline-flex', flexDirection: 'row' }}><div className="text-muted" style={{ marginRight: 4 }}>Logradouro:</div> {this.state.pedido.Logradouro}
                                                                <div>
                                                                    {
                                                                        this.state.pedido.Complemento && this.state.pedido.Complemento.length > 0 && <div>
                                                                            , {this.state.pedido.Complemento}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div><br></br>
                                                            <div style={{ display: 'inline-flex', flexDirection: 'row' }}><div className="text-muted" style={{ marginRight: 4 }}>Número: </div>{this.state.pedido.Numero}</div><br></br>
                                                            <div style={{ display: 'inline-flex', flexDirection: 'row' }}><div className="text-muted" style={{ marginRight: 4 }}>Bairro:</div> {this.state.pedido.Bairro} </div><br></br>

                                                            <small className="mt-2">
                                                                <div>
                                                                    {
                                                                        this.state.pedido.Referencia && this.state.pedido.Referencia.length > 0 &&
                                                                        <div className="mt-2">
                                                                            <div style={{ display: 'inline-flex', flexDirection: 'row' }}><div className="text-muted" style={{ marginRight: 4 }}>Referência:</div> {this.state.pedido.Referencia} </div><br></br>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="text-muted">{this.state.pedido.Cidade}/{this.state.pedido.UF} - {this.state.pedido.CEP}</div>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="py-2"><a className="btn btn-light btn-sm btn-shadow mt-3 mt-sm-0" href="#"><i className="ci-edit me-2" />Alterar endereço</a></div> */}
                                            </div>
                                            <div className="row gx-4 gy-3">
                                                <div className="col-sm-4">
                                                    <label className="form-label" htmlFor="co-fn">Nome <span className="text-danger">*</span></label>
                                                    <input className="form-control" type="text" id="co-fn" disabled value={this.state.pedido.Nome} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <label className="form-label" htmlFor="co-ln">Telefone <span className="text-danger">*</span></label>
                                                    <input className="form-control" type="tel" id="co-phone" disabled value={this.state.pedido.Telefone} />
                                                </div>
                                                {
                                                    this.state.pedido.TelefoneSecundario.length > 0 &&
                                                    <div className="col-sm-4">
                                                        <label className="form-label" htmlFor="co-ln">Telefone secundário<span className="text-danger">*</span></label>
                                                        <input className="form-control" type="tel" id="co-phone2" disabled value={this.state.pedido.TelefoneSecundario} />
                                                    </div>
                                                }
                                                <div className="col-sm-6">
                                                    <label className="form-label" htmlFor="co-fn">Email</label>
                                                    <input className="form-control bg-image-none" type="email" id="co-email" disabled value={this.state.pedido.Email} />
                                                </div>

                                                {
                                                    this.state.pedido.Observacao.length > 0 &&
                                                    <div className="col-sm-12">
                                                        <label className="form-label" htmlFor="co-note">Observação</label>
                                                        <textarea className="form-control bg-image-none" id="co-note" rows={3} placeholder="Alguma observação para a hora da entrega?" maxLength={200} value={this.state.pedido.Observacao} disabled />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {/* Order summary + payment*/}
                                        <div className="col-xl-5 offset-xl-1 mb-2">
                                            <div className="bg-light rounded-3 py-4 px-4 px-xxl-5">
                                                <h2 className="h5 pb-0">Seu pedido</h2>
                                                {
                                                    props &&
                                                    <ListaProdutos data={props} history={this.props.history} />
                                                }
                                                {this.state.pedido.ValorDescontoVoucher &&
                                                    <h3 className="fw-normal text-center my-2 py-2" style={{ fontSize: 12, color: 'red' }}>- R$ {this.state.pedido.ValorDescontoVoucher.toFixed(2).replace('.', ',')} <small>(Voucher)</small></h3>
                                                }

                                                <h3 className="fw-normal text-center my-2 py-2">R$ {this.state.pedido.ValorReal},<small>{this.state.pedido.ValorCentavos}</small></h3>
                                                <div className="accordion accordio-flush shadow-sm rounded-3 mb-4" id="payment-methods">
                                                    {
                                                        this.state.pedido.FormaPagamento === 'Boleto' &&
                                                        <div className="accordion-item border-bottom">
                                                            <div className="accordion-header py-3 px-3">
                                                                <div className="form-check d-table" data-bs-toggle="collapse" data-bs-target="#boleto">
                                                                    <input className="form-check-input" type="radio" name="license" id="payment-boleto" checked={true} readOnly />
                                                                    <label className="form-check-label fw-medium text-dark" htmlFor="payment-boleto">Boleto<i className="ci-document text-muted fs-base align-middle mt-n1 ms-2" /></label>
                                                                </div>
                                                            </div>

                                                            <div className="collapse show" id="credit-card" data-bs-parent="#payment-methods">
                                                                <div className="accordion-body py-2">
                                                                    {/* <input className="form-control bg-image-none mb-3" type="text" placeholder="Card number" /> */}
                                                                    <div className="row">
                                                                        {this.state.pedido.NumeroParcelas === 1 ?
                                                                            <div className="col-sm mb-3">
                                                                                <input className="form-check-input" id="payment-master1" type="radio" checked={true} readOnly /> <div style={{ color: 'GrayText', fontSize: 12, display: 'inline-block' }}>Vencimento em {this.state.pedido.PrazoBoleto} dias </div>
                                                                            </div>
                                                                            :
                                                                            <div className="col-sm mb-3">
                                                                                {/* <input className="form-check-input" id="payment-master" type="radio" checked={true} readOnly /> <div style={{ color: 'GrayText', fontSize: 12, display: 'inline-block' }}>Dois boletos, R$ {(this.state.pedido.Total / 2).toFixed(2).replace('.', ',')} com prazo de 10 dias e R$ {(this.state.pedido.Total / 2).toFixed(2).replace('.', ',')} com prazo de 15 dias</div> */}
                                                                                <input className="form-check-input" id="payment-master2" type="radio" checked={true} readOnly /> <div style={{ color: 'GrayText', fontSize: 12, display: 'inline-block' }}>Dois boletos, 50% do total com prazo de 10 dias e 50% restantes com prazo de 15 dias</div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        this.state.pedido.FormaPagamento === 'Cartão de crédito' &&
                                                        <div className="accordion-item border-bottom">
                                                            <div className="accordion-header py-3 px-3">
                                                                <div className="form-check d-table" data-bs-target="#credit-card">
                                                                    <input className="form-check-input" type="radio" name="license" id="payment-card" checked={true} readOnly />
                                                                    <label className="form-check-label fw-medium text-dark" htmlFor="payment-card">Cartão de crédito<i className="ci-card text-muted fs-lg align-middle mt-n1 ms-2" /></label>
                                                                </div>
                                                            </div>
                                                            {/* <div className="collapse" id="credit-card" data-bs-parent="#payment-methods"> */}
                                                            <div className={this.state.pedido.FormaPagamento === 'Cartão de crédito' ? "collapse show" : "collapse"} id="credit-card" data-bs-parent="#payment-methods">

                                                                <div className="accordion-body py-2">
                                                                    {/* <input className="form-control bg-image-none mb-3" type="text" placeholder="Card number" /> */}
                                                                    <div className="row">
                                                                        {
                                                                            this.state.pedido.BandeiraCartao === 'MasterCard' &&
                                                                            <div className="col-3 mb-3">
                                                                                <input className="form-check-input" id="payment-master3" style={styleCartoes} type="radio" checked={true} readOnly />
                                                                                <img src={master_card} width={40} alt="master_card" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.pedido.BandeiraCartao === 'Visa' &&
                                                                            <div className="col-3 mb-3">
                                                                                <input className="form-check-input" id="payment-visa" style={styleCartoes} type="radio" checked={true} readOnly />
                                                                                <img src={visa_cartao} width={40} alt="visa_cartao" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.pedido.BandeiraCartao === 'Elo' &&
                                                                            <div className="col-3 mb-3">
                                                                                <input className="form-check-input" id="payment-elo" style={styleCartoes} type="radio" checked={true} readOnly />
                                                                                <img src={elo_cartao} width={40} alt="elo_cartao" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.pedido.BandeiraCartao === 'AmericanExpress' &&
                                                                            <div className="col-3 mb-3">
                                                                                <input className="form-check-input" id="payment-american" style={styleCartoes} type="radio" checked={true} readOnly />
                                                                                <img src={american_express} width={40} alt="american_express" />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        this.state.pedido.FormaPagamento === 'Cartão de débito' &&
                                                        <div className="accordion-item border-bottom">
                                                            <div className="accordion-header py-3 px-3">
                                                                <div className="form-check d-table" data-bs-target="#credit-card">
                                                                    <input className="form-check-input" type="radio" name="license" id="payment-card-debito" checked={true} readOnly />
                                                                    <label className="form-check-label fw-medium text-dark" htmlFor="payment-card-debito">Cartão de débito<i className="ci-card text-muted fs-lg align-middle mt-n1 ms-2" /></label>
                                                                </div>
                                                            </div>
                                                            {/* <div className="collapse" id="credit-card" data-bs-parent="#payment-methods"> */}
                                                            <div className={this.state.pedido.FormaPagamento === 'Cartão de débito' ? "collapse show" : "collapse"} id="credit-card-debito" data-bs-parent="#payment-methods-debito">

                                                                <div className="accordion-body py-2">
                                                                    {/* <input className="form-control bg-image-none mb-3" type="text" placeholder="Card number" /> */}
                                                                    <div className="row">
                                                                        {
                                                                            this.state.pedido.BandeiraCartao === 'MasterCard' &&
                                                                            <div className="col-3 mb-3">
                                                                                <input className="form-check-input" id="payment-master-debito" style={styleCartoes} type="radio" checked={true} readOnly />
                                                                                <img src={master_card} width={40} alt="master_card" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.pedido.BandeiraCartao === 'Visa' &&
                                                                            <div className="col-3 mb-3">
                                                                                <input className="form-check-input" id="payment-visa-debito" style={styleCartoes} type="radio" checked={true} readOnly />
                                                                                <img src={visa_cartao} width={40} alt="visa_cartao" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.pedido.BandeiraCartao === 'Elo' &&
                                                                            <div className="col-3 mb-3">
                                                                                <input className="form-check-input" id="payment-elo-debito" style={styleCartoes} type="radio" checked={true} readOnly />
                                                                                <img src={elo_cartao} width={40} alt="elo_cartao" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.pedido.BandeiraCartao === 'AmericanExpress' &&
                                                                            <div className="col-3 mb-3">
                                                                                <input className="form-check-input" id="payment-american-debito" style={styleCartoes} type="radio" checked={true} readOnly />
                                                                                <img src={american_express} width={40} alt="american_express" />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        this.state.pedido.FormaPagamento === 'Dinheiro' &&
                                                        <div className="accordion-item">
                                                            <div className="accordion-header py-3 px-3">
                                                                <div className="form-check d-table" data-bs-toggle="collapse" data-bs-target="#cash">
                                                                    <input className="form-check-input" type="radio" name="license" id="payment-cash" checked={true} readOnly />
                                                                    <label className="form-check-label fw-medium text-dark" htmlFor="payment-cash">Dinheiro<i className="ci-wallet text-muted fs-lg align-middle mt-n1 ms-2" /></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        this.state.pedido.FormaPagamento === 'Pix' &&
                                                        <div className="accordion-item">
                                                            <div className="accordion-header py-3 px-3">
                                                                <div className="form-check d-table" data-bs-toggle="collapse" data-bs-target="#cash">
                                                                    <input className="form-check-input" type="radio" name="license" id="payment-cash" checked={true} readOnly />
                                                                    <label className="form-check-label fw-medium text-dark" htmlFor="payment-cash">Pix<i className="ci-coin text-muted fs-lg align-middle mt-n1 ms-2" /></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        this.state.pedido.FormaPagamento === 'Nota' &&
                                                        <div className="accordion-item">
                                                            <div className="accordion-header py-3 px-3">
                                                                <div className="form-check d-table" data-bs-toggle="collapse" data-bs-target="#cash">
                                                                    <input className="form-check-input" type="radio" name="license" id="payment-cash" checked={true} readOnly />
                                                                    <label className="form-check-label fw-medium text-dark" htmlFor="payment-cash">Nota - 15 dias<i className="ci-edit text-muted fs-lg align-middle mt-n1 ms-2" /></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        this.state.pedido.FormaPagamento === 'Cheque' &&
                                                        <div className="accordion-item">
                                                            <div className="accordion-header py-3 px-3">
                                                                <div className="form-check d-table" data-bs-toggle="collapse" data-bs-target="#cheque">
                                                                    <input className="form-check-input" type="radio" name="license" id="payment-cheque" checked={true} readOnly />
                                                                    <label className="form-check-label fw-medium text-dark" htmlFor="payment-cheque">Cheque<i className="ci-document text-muted fs-base align-middle mt-n1 ms-2" /></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                {/* <div className="pt-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                                    <button className="btn btn-info d-block w-40" onClick={e => this.imprimir(e)}>Imprimir</button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </main>
                }
            </div>
        )
    }
}

export default PedidoDetalhado