import React, { Component } from 'react';
import semImagem from '../../assets/img/semImagem.png';
import UsuarioService from '../../service/usuario.service';
import UserContext from '../../contexts/user';

import { toast } from 'react-toastify';

const usuarioService = new UsuarioService();

const styleCartoes = {
    marginTop: 6,
    marginRight: 5,
}


class MeusDados extends Component {
    static contextType = UserContext;
    user;

    // loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

    constructor(props) {
        super(props);

        this.state = {
            signed: false,
            endereco: '',
            idVendedor: '',
            nome: '',
            telefone: '',
            telefoneSecundario: '',
            email: '',
            credito: 'R$ 0,00',
            creditoDisponivel: 'R$ 0,00',
            cpf: false,
            cnpj: false,
            inscricaoEstadual: false,
            nomeVendedor: '',
            creditoVoucher: 0
        }
    }

    componentDidMount() {
        this.user = this.context;

        setTimeout(() => window.scrollTo(0, 0), 100);

        const aux2 = !this.user.loading ? this.user.updateLoading() : false;

        setTimeout(() => {
            const aux = this.user.loading ? this.user.updateLoading() : false;
        }, 1000);

        usuarioService.getEndereco(this.user.user.user.uid).once('value', snapshot => {
            if (snapshot) {
                const dados = snapshot.val();

                console.log('this.user.creditoVoucher: ' + this.user.creditoVoucher)

                this.setState({
                    idVendedor: dados.IDVendedor,
                    endereco: {
                        bairro: dados.Bairro,
                        cep: dados.CEP,
                        cidade: dados.Cidade,
                        complemento: dados.Complemento,
                        logradouro: dados.Logradouro,
                        numero: dados.Numero,
                        referencia: dados.Referencia,
                        uf: dados.UF
                    },
                    nome: dados.Nome,
                    telefone: dados.Telefone,
                    telefoneSecundario: dados.TelefoneSecundario,
                    email: dados.Email,
                    cpf: dados.CPF ? dados.CPF : false,
                    cnpj: dados.CNPJ ? dados.CNPJ : false,
                    inscricaoEstadual: dados.InscricaoEstadual ? dados.InscricaoEstadual : false,
                    credito: 'R$ ' + dados.Credito.toFixed(2).replace('.', ','),
                    creditoDisponivel: 'R$ ' + dados.CreditoDisponivel.toFixed(2).replace('.', ','),
                    creditoVoucher: 'R$ ' + this.user.creditoVoucher.toFixed(2).replace('.', ','),
                });

                usuarioService.getNomeVendedor(dados.IDVendedor).once('value', snap => {
                    this.setState({ nomeVendedor: snap.val() });
                });

                setTimeout(() => {
                    const aux = this.user.loading ? this.user.updateLoading() : false;
                }, 1000);
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;

        if (this.user.signed !== prevState.signed) {
            this.setState({ signed: this.user.signed });
        }

        if (this.user.creditoVoucher !== prevState.creditoVoucher) {
            this.setState({ creditoVoucher: this.user.creditoVoucher });
        }
    }

    render() {
        let props = this.context;

        return (
            <div>
                <main className="offcanvas-enabled " style={{ paddingTop: '5rem' }}>
                    <section className="ps-lg-4 pe-lg-3 pt-4">
                        <div className="px-3 pt-2">
                            {/* Page title + breadcrumb*/}
                            <nav className="mb-4" aria-label="breadcrumb">
                                <ol className="breadcrumb flex-lg-nowrap">
                                    <li className="breadcrumb-item"><a className="text-nowrap" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/')}><i className="ci-home" />Início</a></li>
                                    <li className="breadcrumb-item text-nowrap active" aria-current="page">Meus dados</li>
                                </ol>
                            </nav>
                            {/* Content*/}
                            <div className="row g-0 pb-5 mb-xl-3">
                                <div className="bg-light px-3 pt-4 pb-4 col-xl-6 mb-3">
                                    <h1 className="h2 mb-4">Dados da conta</h1>
                                    <h2 className="h5 mb-4">Dados para entrega</h2>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center rounded-3 border py-2 px-3 mb-4">
                                        <div className="d-flex align-items-center me-3 py-2">
                                            <div className="ps-3">
                                                <div className="fs-md fw-medium text-heading">
                                                    <div style={{ display: 'inline-flex', flexDirection: 'row' }}><div className="text-muted" style={{ marginRight: 4 }}>Logradouro:</div> {this.state.endereco.logradouro}
                                                        <div>
                                                            {
                                                                this.state.endereco.complemento && this.state.endereco.complemento.length > 0 && <div>
                                                                    , {this.state.endereco.complemento}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div><br></br>
                                                    <div style={{ display: 'inline-flex', flexDirection: 'row' }}><div className="text-muted" style={{ marginRight: 4 }}>Número: </div>{this.state.endereco.numero}</div><br></br>
                                                    <div style={{ display: 'inline-flex', flexDirection: 'row' }}><div className="text-muted" style={{ marginRight: 4 }}>Bairro:</div> {this.state.endereco.bairro} </div><br></br>

                                                    <small className="mt-2">
                                                        <div>
                                                            {
                                                                this.state.endereco.referencia && this.state.endereco.referencia.length > 0 &&
                                                                <div className="mt-2">
                                                                    <div style={{ display: 'inline-flex', flexDirection: 'row' }}><div className="text-muted" style={{ marginRight: 4 }}>Referência:</div> {this.state.endereco.referencia} </div><br></br>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="text-muted">{this.state.endereco.cidade}/{this.state.endereco.uf} - {this.state.endereco.cep}</div>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="py-2"><a className="btn btn-light btn-sm btn-shadow mt-3 mt-sm-0" href="#"><i className="ci-edit me-2" />Alterar endereço</a></div> */}
                                    </div>
                                    <div className="row gx-4 gy-3">
                                        <div className="col-sm-4">
                                            <label className="form-label" htmlFor="co-fn">Nome</label>
                                            <input className="form-control" type="text" id="co-fn" disabled value={this.state.nome} />
                                        </div>
                                        <div className="col-sm-4">
                                            <label className="form-label" htmlFor="co-ln">Telefone</label>
                                            <input className="form-control" type="tel" id="co-phone" disabled value={this.state.telefone} />
                                        </div>
                                        {
                                            this.state.telefoneSecundario.length > 0 &&
                                            <div className="col-sm-4">
                                                <label className="form-label" htmlFor="co-ln">Telefone secundário</label>
                                                <input className="form-control" type="tel" id="co-phone2" disabled value={this.state.telefoneSecundario} />
                                            </div>
                                        }
                                        <div className="col-sm-6">
                                            <label className="form-label" htmlFor="co-fn">Email</label>
                                            <input className="form-control bg-image-none" type="email" id="co-email" disabled value={this.state.email} />
                                        </div>

                                        {this.state.cpf &&
                                            <div className="col-sm-4">
                                                <label className="form-label" htmlFor="co-fn">CPF</label>
                                                <input className="form-control bg-image-none" type="string" id="co-cpf" disabled value={this.state.cpf} />
                                            </div>
                                        }

                                        {this.state.cnpj &&
                                            <div className="col-sm-5">
                                                <label className="form-label" htmlFor="co-fn">CNPJ</label>
                                                <input className="form-control bg-image-none" type="string" id="co-cnpj" disabled value={this.state.cnpj} />
                                            </div>
                                        }

                                        {this.state.inscricaoEstadual &&
                                            <div className="col-sm-3">
                                                <label className="form-label" htmlFor="co-fn">I. E.</label>
                                                <input className="form-control bg-image-none" type="string" id="co-inscricaoEstadual" disabled value={this.state.inscricaoEstadual} />
                                            </div>
                                        }

                                        <div className="col-sm-6">
                                            <label className="form-label" htmlFor="co-fn">Vendedor</label>
                                            <input className="form-control bg-image-none" type="string" id="co-inscricaoEstadual" disabled value={this.state.nomeVendedor} />
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-light px-3 pt-4 pb-4 col-sm-2 offset-xl-1" style={{ height: '100%' }}>
                                    <div className="col-sm">
                                        <label className="form-label" htmlFor="co-fn">Limite de crédito</label>
                                        <input className="form-control bg-image-none" type="string" id="co-credito" disabled value={this.state.credito} />
                                    </div>

                                    <div className="col-sm mt-4">
                                        <label className="form-label" htmlFor="co-fn">Crédito disponível</label>
                                        <input className="form-control bg-image-none" type="string" id="co-credito" disabled value={this.state.creditoDisponivel} />
                                    </div>

                                    <div className="col-sm mt-4">
                                        <label className="form-label" htmlFor="co-fn">Crédito voucher</label>
                                        <input className="form-control bg-image-none" type="string" id="co-credito" disabled value={`R$ ${Number(this.state.creditoVoucher).toFixed(2).replace('.', ',')}`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

            </div >
        )
    }
}

export default MeusDados