import React, { Component } from 'react';
import Footer from '../footer/footer';
import LoginPopUp from '../modals/login_popup.js';
import RegistroPopUp from '../modals/cadastro_popup';
import ManutencaoPopUp from '../modals/manutencao_popup';
import VersaoDesatualizadaPopUp from '../modals/versao_desatualizada_popup';
import RecuperarSenhaPopUp from '../modals/esqueci_senha_pupup';
import FinalizarCompraRemocaoProdutosPopUp from '../modals/finalizar_compra_remocao_produtos';
import CompraCanceladaPopUp from '../modals/compra_cancelada_popup';

// import Home from '../home/home';
import Routes from './routes';
import { UserProvider } from '../../contexts/user';
import UserContext from '../../contexts/user';
import Loader from "react-loader-spinner";
import Modal from 'react-modal';

import './modal_transition.css'; // Theme Cartzilla
import AlterarDataEntregaPopUp from '../modals/alterar_data_entrega_popup';
import PagamentoPendentePopUp from '../modals/pagamento_pendente';

// import Modal from "react-animated-modal"; Não funciona

const screenWidth = window.innerWidth;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: null,
        borderWidth: 0
    },
    overlay: {
        background: 'rgba(52, 52, 52, 0.3)',
        zIndex: 1000
    }
};

const customStyles2 = {
    content: {
        top: screenWidth <= 1366 ? '55%' : '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '100%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: null,
        borderWidth: 0,
    },
    overlay: {
        background: 'rgba(52, 52, 52, 0.3)',
        zIndex: 1000
    }
};



class Layout extends Component {
    // loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>
    // static contextType = UserContext

    componentDidMount() {
        console.log(screenWidth)
        // Modal.setAppElement(document.getElementById('app'))
        if (typeof (window) !== 'undefined') {
            Modal.setAppElement('body')
        }
    }

    render() {
        return (
            <div>
                <UserProvider>
                    <UserContext.Consumer>
                        {value =>
                            <div>
                                {/* Modals */}
                                <Modal
                                    isOpen={value.modal !== null ? true : false}
                                    style={customStyles2}
                                    contentLabel="PopUp"
                                    // ariaHideApp={false}
                                    onRequestClose={() => {
                                        if (value.modal !== 'Manutencao' && value.modal !== 'VersaoSistema' && value.modal !== 'FinalizarCompraRemocaoProdutos') {
                                            value.updateModal(null);
                                        }
                                    }}>
                                    <div>
                                        {
                                            value.modal === 'Login' &&
                                            <LoginPopUp context={value} />
                                        }
                                        {
                                            value.modal === 'Cadastro' &&
                                            <RegistroPopUp context={value} />
                                        }

                                        {
                                            value.modal === 'Manutencao' &&
                                            <ManutencaoPopUp context={value} />
                                        }

                                        {
                                            value.modal === 'VersaoSistema' &&
                                            <VersaoDesatualizadaPopUp context={value} />
                                        }

                                        {
                                            value.modal === 'RecuperarSenha' &&
                                            <RecuperarSenhaPopUp context={value} />
                                        }

                                        {
                                            value.modal === 'FinalizarCompraRemocaoProdutos' &&
                                            <FinalizarCompraRemocaoProdutosPopUp context={value} />
                                        }

                                        {
                                            value.modal === 'AlterarDataEntrega' &&
                                            <AlterarDataEntregaPopUp context={value} />
                                        }

                                        {
                                            value.modal === 'CompraCancelada' &&
                                            <CompraCanceladaPopUp context={value} />
                                        }

                                        {
                                            value.modal === 'PagamentoPendente' &&
                                            <PagamentoPendentePopUp context={value} />
                                        }


                                    </div>
                                </Modal>


                                <Modal
                                    isOpen={value.loading}
                                    style={customStyles}
                                    contentLabel="Loading"
                                    ariaHideApp={false}
                                >
                                    <div>
                                        <Loader
                                            visible={true}
                                            type="ThreeDots"
                                            color="white"
                                            height={80}
                                            width={80}
                                        />
                                    </div>
                                </Modal>

                                {/* Páginas */}
                                <Routes context={value} />
                            </div>
                        }
                    </UserContext.Consumer>
                </UserProvider>

                <Footer />
            </div>
        )
    }
}

export default Layout