import React, { Component } from 'react';
import UserContext from '../../contexts/user';
import ListaProdutos from './lista-produtos';
import UsuarioService from '../../service/usuario.service';

const usuarioService = new UsuarioService();

class ProdutosLista extends Component {
    static contextType = UserContext;
    user;

    constructor() {
        super();

        this.state = {
            user: null,
            categorias: [],
            categoriaSelecionada: 'Todos',
            paginaAtual: 1,
            totalPaginas: 0,
            menorPreco: false,
            maiorPreco: false
        };
    }

    componentDidMount() {
        this.user = this.context;
        this.user.updateLoading();

        window.scrollTo(0, 0);

        usuarioService.getCategorias().once('value', snapshot => {
            let dados;
            let categorias = [];
            snapshot.forEach((item) => {
                dados = item.val();

                categorias.push({
                    Key: item.key,
                    Nome: dados.Nome
                })
            });
            //Ordem alfabética
            categorias.sort((a, b) => a.Nome.localeCompare(b.Nome));

            categorias.unshift({
                Key: 'Todos',
                Nome: 'Todos'
            })

            this.setState({ categorias: categorias });
        });

        setTimeout(() => {
            const resp = this.user.loading ? this.user.updateLoading() : false;
        }, 1000);

    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;

        if (this.user.categoria !== prevState.categoriaSelecionada && this.user.categoria) {
            this.setState({ categoriaSelecionada: this.user.categoria });
        }
    }

    selecionarProduto(value) {
        this.user.updateLoading();
        this.user.selecionarProduto(JSON.stringify(value));
        this.props.history.push({
            pathname: '/produto-detalhado',
            search: '?' + value.Key
        });
    }

    setTotalPaginas(value) {
        this.setState({ totalPaginas: value });
    }

    navegarPagina(index) {
        this.setState({ paginaAtual: index + 1 })
        this.user.updatePaginaAtual(index + 1);
    }

    alterarPagina(b) {
        if (b) {
            if (this.state.paginaAtual + 1 <= this.state.totalPaginas) {
                this.setState({ paginaAtual: this.state.paginaAtual + 1 });
                this.user.updatePaginaAtual(this.state.paginaAtual + 1);
            }
        } else {
            if (this.state.paginaAtual - 1 >= 1) {
                this.setState({ paginaAtual: this.state.paginaAtual - 1 });
                this.user.updatePaginaAtual(this.state.paginaAtual - 1);
            }
        }
    }

    alterarCategoria(value) {
        this.setState({ categoriaSelecionada: value });
        this.user.updateCategoria(value !== 'Todos' ? value : null);
    }

    aplicarFiltro(tipo) {
        this.user.updateFiltro(tipo);
        tipo === 'Maior Valor' ? this.setState({ maiorPreco: true, menorPreco: false }) : this.setState({ maiorPreco: false, menorPreco: true });
    }

    // loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>
    render() {
        let props = this.context;

        return (
            <div>
                <main className="offcanvas-enabled" style={{ paddingTop: '5rem' }}>
                    <section className="ps-lg-4 pe-lg-3 pt-4">
                        <div className="px-3 pt-2">
                            {/* Page title + breadcrumb*/}
                            <nav className="mb-4" aria-label="breadcrumb">
                                <ol className="breadcrumb flex-lg-nowrap">
                                    <li className="breadcrumb-item"><a className="text-nowrap" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/')}><i className="ci-home" />Início</a></li>
                                    <li className="breadcrumb-item text-nowrap active" aria-current="page">Catálogo de produtos</li>
                                </ol>
                            </nav>
                            {/* Content*/}
                            {/* Sorting*/}
                            <section className="d-md-flex justify-content-between align-items-center mb-4 pb-2">
                                <h1 className="h2 mb-3 mb-md-0 me-3">Catálogo de produtos</h1>
                                <div className="d-flex align-items-center">

                                    <div className="d-none d-sm-block py-2 fs-sm text-muted text-nowrap me-2">Categoria:</div>
                                    <ul className="nav nav-tabs fs-sm mb-0">
                                        <li className="nav-item">
                                            <select className="form-select flex-shrink-0" value={this.state.categoriaSelecionada} onChange={(evt) => this.setState(this.alterarCategoria(evt.target.value))} style={{ width: '10rem', height: '2.5rem', fontSize: 14, textAlignLast: 'center' }}>
                                                {this.state.categorias &&
                                                    (this.state.categorias.map((value) => <option key={value.Key}>{value.Nome}</option>))
                                                }
                                            </select>
                                        </li>
                                    </ul>

                                    <div className="d-none d-sm-block py-2 fs-sm text-muted text-nowrap me-2" style={{ marginLeft: 20 }}>Filtrar por:</div>
                                    <ul className="nav nav-tabs fs-sm mb-0">
                                        <li className="nav-item"><a className={this.state.menorPreco ? "nav-link active" : "nav-link"} style={{ cursor: 'pointer' }} onClick={() => this.aplicarFiltro('Menor Valor')}>Menor preço</a></li>
                                        <li className="nav-item"><a className={this.state.maiorPreco ? "nav-link active" : "nav-link"} style={{ cursor: 'pointer' }} onClick={() => this.aplicarFiltro('Maior Valor')}>Maior preço</a></li>
                                    </ul>
                                </div>
                            </section>
                            {/* Product grid*/}
                            <div className="row g-0 mx-n2">
                                {
                                    props &&
                                    <ListaProdutos data={props} history={this.props.history} setTotalPaginas={this.setTotalPaginas.bind(this)}  navegarPagina={this.navegarPagina.bind(this)}/>
                                }

                                {this.state.totalPaginas > 0 &&
                                    <div className="py-4 pb-md-5 mb-4">
                                        {/* Pagination*/}
                                        <nav className="d-flex justify-content-between pt-2" aria-label="Page navigation">
                                            <ul className="pagination">
                                                <li className="page-item"><a className="page-link" aria-label="Página anterior" style={{ cursor: this.state.paginaAtual !== 1 ? 'pointer' : null, color: this.state.paginaAtual !== 1 ? '#4B566B' : '#a8b4cc' }} onClick={() => this.alterarPagina(false)}><i className="ci-arrow-left me-2" />Anterior</a></li>
                                            </ul>
                                            <ul className="pagination">
                                                {
                                                    (Array.from({ length: this.state.totalPaginas }, (item, index) =>
                                                        <li key={index} className={this.state.paginaAtual - 1 === index ? 'page-item active d-none d-sm-block' : 'page-item d-none d-sm-block'}><a className="page-link" style={{ cursor: 'pointer' }} onClick={() => this.navegarPagina(index)} >{index + 1}</a></li>
                                                    ))
                                                }
                                            </ul>
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <a className="page-link" aria-label="Próxima página" style={{ cursor: this.state.paginaAtual !== this.state.totalPaginas ? 'pointer' : null, color: this.state.paginaAtual !== this.state.totalPaginas ? '#4B566B' : '#a8b4cc' }} onClick={() => this.alterarPagina(true)}>Próxima<i className="ci-arrow-right ms-2" /></a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                </main>

            </div >
        )
    }
}

export default ProdutosLista