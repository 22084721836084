import React, { Component } from 'react';
import UsuarioService from '../../service/usuario.service';
import UserContext from '../../contexts/user';
import TabelaPedidos from './tabela-pedidos';

const usuarioService = new UsuarioService();

const styleCartoes = {
    marginTop: 6,
    marginRight: 5,
}


class MeusPedidos extends Component {
    static contextType = UserContext;
    user;

    // loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

    constructor(props) {
        super(props);

        this.state = {
            signed: false,
            filtroSelecionado: 'Mais recentes',
            paginaAtual: 1,
            totalPaginas: 0,
        }
    }

    componentDidMount() {
        this.user = this.context;

        setTimeout(() => window.scrollTo(0, 0), 100);

        setTimeout(() => {
            const aux = this.user.loading ? this.user.updateLoading() : false;
        }, 1000);


    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;

        if (this.user.signed !== prevState.signed) {
            this.setState({ signed: this.user.signed });
        }
    }

    setTotalPaginas(value) {
        this.setState({ totalPaginas: value });
    }

    navegarPagina(index) {
        this.setState({ paginaAtual: index + 1 })
        this.user.updatePaginaAtual(index + 1);
    }

    alterarPagina(b) {
        if (b) {
            if (this.state.paginaAtual + 1 <= this.state.totalPaginas) {
                this.setState({ paginaAtual: this.state.paginaAtual + 1 });
                this.user.updatePaginaAtual(this.state.paginaAtual + 1);
            }
        } else {
            if (this.state.paginaAtual - 1 >= 1) {
                this.setState({ paginaAtual: this.state.paginaAtual - 1 });
                this.user.updatePaginaAtual(this.state.paginaAtual - 1);
            }
        }
    }

    alterarFiltro(value) {
        this.setState({ filtroSelecionado: value });
        this.user.updateFiltro(value);
    }

    render() {
        let props = this.context;

        return (
            <div>
                <main className="offcanvas-enabled " style={{ paddingTop: '5rem' }}>
                    <section className="ps-lg-4 pe-lg-3 pt-4">
                        <div className="px-3 pt-2">
                            {/* Page title + breadcrumb*/}
                            <nav className="mb-4" aria-label="breadcrumb">
                                <ol className="breadcrumb flex-lg-nowrap">
                                    <li className="breadcrumb-item"><a className="text-nowrap" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/')}><i className="ci-home" />Início</a></li>
                                    <li className="breadcrumb-item text-nowrap active" aria-current="page">Meus pedidos</li>
                                </ol>
                            </nav>
                            {/* Content*/}
                            <div className="bg-light row g-0 pb-5 mb-xl-3">
                                <div className="px-3 pt-4 pb-4 col-sm mb-3">
                                    <h1 className="h2 mb-4">Meus pedidos</h1>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center rounded-3 border py-2 px-3 mb-4">
                                        <div className="d-flex align-items-center me-3 py-2">
                                            <div className="ps-3">
                                                <div className="fs-md fw-medium text-heading">
                                                    <section className="col-sm">
                                                        <div className="d-flex justify-content-between align-items-center pt-lg-2 pb-4 mb-lg-3">
                                                            <div className="d-flex align-items-center">
                                                                <label className="d-none d-lg-block fs-sm text-dark text-nowrap opacity-75 me-2" htmlFor="order-sort">Filtrar:</label>
                                                                <label className="d-lg-none fs-sm text-nowrap opacity-75 me-2" htmlFor="order-sort">Filtrar:</label>
                                                                <select className="form-select" id="order-sort" value={this.state.filtroSelecionado} onChange={(evt) => this.setState(this.alterarFiltro(evt.target.value))} style={{ width: 170 }}>
                                                                    <option>Mais recentes</option>
                                                                    <option>Mais antigos</option>
                                                                    <option>Pagos</option>
                                                                    <option>Em aberto</option>
                                                                    <option>Cancelados</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {
                                                            props &&
                                                            <TabelaPedidos data={props} history={this.props.history} setTotalPaginas={this.setTotalPaginas.bind(this)} navegarPagina={this.navegarPagina.bind(this)} />
                                                        }

                                                        {/* Pagination*/}
                                                        {this.state.totalPaginas > 0 &&
                                                            <div className="py-4 pb-md-5 mb-4">
                                                                <nav className="d-flex justify-content-between pt-2" aria-label="Page navigation">
                                                                    <ul className="pagination">
                                                                        <li className="page-item"><a className="page-link" aria-label="Página anterior" style={{ cursor: this.state.paginaAtual !== 1 ? 'pointer' : null, color: this.state.paginaAtual !== 1 ? '#4B566B' : '#a8b4cc' }} onClick={() => this.alterarPagina(false)}><i className="ci-arrow-left me-2" />Anterior</a></li>
                                                                    </ul>
                                                                    <ul className="pagination">
                                                                        {
                                                                            (Array.from({ length: this.state.totalPaginas }, (item, index) =>
                                                                                <li key={index} className={this.state.paginaAtual - 1 === index ? 'page-item active d-none d-sm-block' : 'page-item d-none d-sm-block'}><a className="page-link" style={{ cursor: 'pointer' }} onClick={() => this.navegarPagina(index)} >{index + 1}</a></li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                    <ul className="pagination">
                                                                        <li className="page-item">
                                                                            <a className="page-link" aria-label="Próxima página" style={{ cursor: this.state.paginaAtual !== this.state.totalPaginas ? 'pointer' : null, color: this.state.paginaAtual !== this.state.totalPaginas ? '#4B566B' : '#a8b4cc' }} onClick={() => this.alterarPagina(true)}>Próxima<i className="ci-arrow-right ms-2" /></a>
                                                                        </li>
                                                                    </ul>
                                                                </nav>
                                                            </div>
                                                        }
                                                    </section>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div >
        )
    }
}

export default MeusPedidos