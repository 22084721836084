/* eslint-disable */

import React, { Component } from 'react';

import UserContext from '../../contexts/user'
import InputMask from "react-input-mask";
import UsuarioService from '../../service/usuario.service';

const usuarioService = new UsuarioService();

class RegistroPopUp extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.user = this.props.data

        this.state = {
            nome: '',
            telefone: '',
            email: '',
            cpf: '',
            cnpj: '',
            observacao: '',
            tipoCadastro: 'CPF',
            aCidades: [],
            aUfs: [],
            aCidades: [],
            aTodasCidades: [],
            sBuscaUF: 'MG'
        }
    }

    componentDidMount() {
        this.user = this.context;

        //Busca as UF e cidades cadastradas
        usuarioService.getUFCidades().once('value', snapshot => {
            let dados;
            let auxCidades = [];
            let auxUFs = [];
            let auxIndex;
            let auxDadosCidade;
            snapshot.forEach((item) => {
                dados = item.val();

                auxIndex = auxUFs.findIndex(x => x.value === item.key);

                if (auxIndex === -1) {
                    auxUFs.push({
                        value: item.key,
                        name: item.key,
                    });
                }

                Object.keys(dados).forEach((key, index) => {
                    auxDadosCidade = dados[key];
                    if (auxDadosCidade.Status) {
                        auxCidades.push({
                            uf: item.key,
                            value: auxDadosCidade.Cidade,
                            name: auxDadosCidade.Cidade
                        });
                    }
                });


            });

            auxCidades.sort((a, b) => a.name.localeCompare(b.name));
            auxUFs.sort((a, b) => a.name.localeCompare(b.name));

            this.setState({ aTodasCidades: auxCidades, aUfs: auxUFs }, () => this.carregarCidades());
        });
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;
    }

    carregarCidades() {
        let auxACidades = [];
        for (let i = 0; i < this.state.aTodasCidades.length; i++) {
            if (this.state.aTodasCidades[i].uf === this.state.sBuscaUF) {
                auxACidades.push({
                    uf: this.state.aTodasCidades[i].uf,
                    value: this.state.aTodasCidades[i].value,
                    name: this.state.aTodasCidades[i].name
                });
            }
        }

        this.setState({ aCidades: auxACidades, sBuscaCidade: auxACidades[0].name });
    }

    fecharPopup() {
        this.user.updateModal(null);
    }

    async enviarSolicitacao(event) {
        event.preventDefault();

        this.user.updateLoading(true);

        let map = {
            Nome: this.state.nome.trim(),
            Email: this.state.email.trim(),
            Telefone: this.state.telefone,
            TipoCadastro: this.state.tipoCadastro,
            CPF: this.state.cpf,
            CNPJ: this.state.cnpj,
            Observacao: this.state.observacao.trim(),
            bVisualizada: false,
            Plataforma: 'Web',
            UF: this.state.sBuscaUF,
            Cidade: this.state.sBuscaCidade
        }

        const resp = await this.user.setSolicitacaoCadastro(map);

        setTimeout(() => {
            this.user.updateLoading(false);
            this.user.updateModal(null);
            alert('Solicitação realizada com sucesso. Após análise, entraremos em contato nas próximas 48hrs. Obrigado!')
        }, 1500);

    }

    render() {
        return (
            <div>
                {/* Sign in / sign up modal*/}
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-secondary">
                            <ul className="nav nav-tabs card-header-tabs" role="tablist">
                                <li className="nav-item"><a className="nav-link fw-medium active" href="#signin-tab" data-bs-toggle="tab" role="tab" aria-selected="true"><i className="ci-unlocked me-2 mt-n1" />Solicitação de cadastro</a></li>
                            </ul>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={this.fecharPopup.bind(this)} />
                        </div>

                        <div className="modal-body tab-content py-4">
                            <form className="needs-validation tab-pane fade show active" autoComplete="off" id="signin-tab" onSubmit={this.enviarSolicitacao.bind(this)}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="si-email">Email</label>
                                    <input className="form-control" type="email" id="si-email" maxLength={50} placeholder="email@example.com" required onChange={(evt) => this.setState({ email: evt.target.value })} />
                                    <div className="invalid-feedback">Insira um e-mail válido.</div>
                                </div>

                                <div className="mb-3">
                                    <div className="row">
                                        <div className="col-auto">
                                            <label className="form-label" htmlFor="si-nome">Nome</label>
                                            <input className="form-control" type="text" id="si-nome" maxLength={50} required onChange={(evt) => this.setState({ nome: evt.target.value })} />
                                            <div className="invalid-feedback">Insira um nome válido.</div>
                                        </div>

                                        <div className="col-auto">
                                            <label className="form-label" htmlFor="si-telefone">Telefone</label>
                                            <InputMask className="form-control" mask="(99) 9 9999-9999" placeholder="(__) _ ____-____" type="text" id="si-telefone" required onChange={(evt) => this.setState({ telefone: evt.target.value })} />
                                            <div className="invalid-feedback">Insira um telefone válido.</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="row">
                                        <div className="col-auto">
                                            <input className="form-check-input" type="radio" id="si-tipocpf" required checked={this.state.tipoCadastro === 'CPF'} value={this.state.tipoCadastro} onChange={(evt) => this.setState({ tipoCadastro: 'CPF' })} />
                                            <label className="form-check-label" htmlFor="si-tipocpf" style={{ marginLeft: 5 }}>CPF</label>
                                        </div>

                                        <div className="col-auto">
                                            <input className="form-check-input" type="radio" id="si-tipoCNPJ" required checked={this.state.tipoCadastro === 'CNPJ'} value={this.state.tipoCadastro} onChange={(evt) => this.setState({ tipoCadastro: 'CNPJ' })} />
                                            <label className="form-check-label" htmlFor="si-tipoCNPJ" style={{ marginLeft: 5 }}>CNPJ</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="row">
                                        <div className="col-7">
                                            {this.state.tipoCadastro === 'CPF' ?
                                                <div>
                                                    <label className="form-label" htmlFor="si-cpfCNPJ">CPF</label>
                                                    <InputMask className="form-control" type="text" mask="999.999.999-99" id="si-cpfCNPJ" required onChange={(evt) => this.setState({ cpf: evt.target.value })} />
                                                    <div className="invalid-feedback">Insira um valor válido.</div>
                                                </div>
                                                :
                                                <div>
                                                    <label className="form-label" htmlFor="si-cpfCNPJ">CNPJ</label>
                                                    <InputMask className="form-control" type="text" mask="999.999.999/9999-99" id="si-cpfCNPJ" required onChange={(evt) => this.setState({ cnpj: evt.target.value })} />
                                                    <div className="invalid-feedback">Insira um valor válido.</div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-2">
                                            <label className="form-label" htmlFor="order-uf">UF</label>
                                            <select className="form-select" id="order-uf" value={this.state.sBuscaUF} onChange={(evt) => { this.setState({ sBuscaUF: evt.target.value }, () => this.carregarCidades()) }} style={{ width: 80 }}>
                                                {
                                                    this.state.aUfs.map((value, i) =>
                                                        <option key={value.name} value={value.name}>{value.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>

                                        <div className="col-3">
                                            <label className="form-label" htmlFor="order-cidade">Cidade</label>
                                            <select className="form-select" id="order-cidade" value={this.state.sBuscaCidade} onChange={(evt) => this.setState({ sBuscaCidade: evt.target.value })} style={{ width: 'auto' }}>
                                                {
                                                    this.state.aCidades.map((value, i) =>
                                                        <option key={value.name} value={value.name}>{value.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="si-observacao">Observação</label>
                                    <input className="form-control" type="text" id="si-observacao" maxLength={300} placeholder="Ex: entrar em contato na parte da manhã" onChange={(evt) => this.setState({ observacao: evt.target.value })} />
                                    <div className="invalid-feedback">Insira um valor válido.</div>
                                </div>

                                <button className="btn btn-primary btn-shadow d-block w-100 mt-4">Enviar solicitação</button>
                            </form>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export default RegistroPopUp