import React, { Component } from 'react';
import UsuarioService from '../../service/usuario.service';
import UserContext from '../../contexts/user';
import ListaPedido from './lista-pedido';


import master_card from '../../assets/img/cartoes/master_card.png';
import visa_cartao from '../../assets/img/cartoes/visa_cartao_2.png';
import elo_cartao from '../../assets/img/cartoes/elo_cartao_2.png';
import american_express from '../../assets/img/cartoes/american_express.png';
import { toast } from 'react-toastify';
import config from '../../environments/config';

const usuarioService = new UsuarioService();

const styleCartoes = {
    marginTop: 6,
    marginRight: 5,
}


class MeuCarrinho extends Component {
    static contextType = UserContext;
    user;

    // loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

    constructor(props) {
        super(props);

        this.state = {
            signed: false,
            endereco: '',
            idVendedor: '',
            nome: '',
            telefone: '',
            telefoneSecundario: '',
            email: '',
            observacao: '',
            totalCompra: 0,
            valorReal: 0,
            valorCentavos: 0,
            formaPagamento: 'Dinheiro',
            bandeiraCartao: 'MasterCard',
            minimoBoleto: 100,
            minimoBoletoQuinzeDias: 1000,
            minimoDuasPacelasBoleto: 1000,
            minimoPedido: 0,
            prazoBoleto: 10,
            iQuantidadeParcelas: 1,
            bCompraCheque: false,
            bCompraBoleto: false,
            bCompraNota: false,
            minimoCheque: 1000,
            bPentendeCompraAlteradaEstoque: false,
            idRotaEntrega: '',
            nomeRota: '',
            idCidade: '',
            latitude: false,
            longitude: false,
            sDiaRotaPrevisaoRota: '',
            timestampPrevisaoRota: false
        }
    }

    componentDidMount() {
        this.user = this.context;

        setTimeout(() => window.scrollTo(0, 0), 100);

        const aux2 = !this.user.loading ? this.user.updateLoading() : false;

        setTimeout(() => {
            const aux = this.user.loading ? this.user.updateLoading() : false;
        }, 1000);

        const auxValor = this.user.totalCompra.toFixed(2).replace('.', ',');
        this.setState({ totalCompra: this.user.totalCompra, valorReal: auxValor.substring(0, auxValor.indexOf(",")), valorCentavos: auxValor.substring((auxValor.length - 2), auxValor.length), minimoBoleto: this.user.valorMinimoBoleto, minimoPedido: this.user.valorMinimoPedido, minimoBoletoQuinzeDias: this.user.valorMinimoBoletoQuinzeDias, minimoDuasPacelasBoleto: this.user.valorMinimoDuasParcelas, minimoCheque: this.user.valorMinimoCheque });

        usuarioService.getEndereco(this.user.user.user.uid).once('value', snapshot => {
            if (snapshot) {
                const dados = snapshot.val();

                this.setState({
                    idVendedor: dados.IDVendedor,
                    endereco: {
                        bairro: dados.Bairro,
                        cep: dados.CEP,
                        cidade: dados.Cidade,
                        complemento: dados.Complemento,
                        logradouro: dados.Logradouro,
                        numero: dados.Numero,
                        referencia: dados.Referencia,
                        uf: dados.UF
                    },
                    nome: dados.Nome,
                    telefone: dados.Telefone,
                    telefoneSecundario: dados.TelefoneSecundario,
                    email: dados.Email,
                    bCompraCheque: dados.CompraViaCheque ? dados.CompraViaCheque : false,
                    bCompraBoleto: dados.CompraViaBoleto ? dados.CompraViaBoleto : false,
                    bCompraNota: dados.CompraViaNota ? dados.CompraViaNota : false,
                });

                setTimeout(() => {
                    const aux = this.user.loading ? this.user.updateLoading() : false;
                }, 1000);
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;

        if (this.user.signed !== prevState.signed) {
            this.setState({ signed: this.user.signed });
        }

        if (this.user.totalCompra !== prevState.totalCompra) {
            const auxValor = this.user.totalCompra.toFixed(2).replace('.', ',');
            this.setState({ totalCompra: this.user.totalCompra, valorReal: auxValor.substring(0, auxValor.indexOf(",")), valorCentavos: auxValor.substring((auxValor.length - 2), auxValor.length) });
        }

        if (this.user.bPentendeCompraAlteradaEstoque !== prevState.bPentendeCompraAlteradaEstoque) {
            this.setState({ formaPagamento: 'Dinheiro', bPentendeCompraAlteradaEstoque: this.user.bPentendeCompraAlteradaEstoque });
        }

        if (this.user.idRotaEntrega !== prevState.idRotaEntrega) {
            this.setState({ idRotaEntrega: this.user.idRotaEntrega, nomeRota: this.user.nomeRota, idCidade: this.user.idCidade, latitude: this.user.latitude, longitude: this.user.longitude, stringPrevisaoRota: this.user.sDiaRotaPrevisaoRota, timestampPrevisaoRota: this.user.timestampPrevisaoRota ? this.user.timestampPrevisaoRota : false });
        }

        if (this.user.timestampPrevisaoRota !== prevState.timestampPrevisaoRota) {
            this.setState({ timestampPrevisaoRota: this.context.timestampPrevisaoRota, stringPrevisaoRota: this.user.sDiaRotaPrevisaoRota });
        }
    }

    limparCarrinhoCompraEstoque() {
        this.user.limparCarrinhoCompraEstoque();
    }

    async finalizarCompra(e) {
        e.preventDefault();
        if (this.state.totalCompra > 0) {
            if (this.state.totalCompra >= this.state.minimoPedido) {
                this.user.updateLoading();

                //Aplica o desconto nos produtos do carrinho
                let auxProdutos = this.user.carrinho;
                let auxGrupoPromocional;
                let auxDesconto;
                let auxValor;
                for (let i = 0; i < auxProdutos.length; i++) {
                    auxDesconto = this.user.validarDescontoGrupoProduto(auxProdutos[i], auxProdutos);
                    auxGrupoPromocional = this.user.getGrupoPromocionalProduto(auxProdutos[i]);
                    if (auxDesconto) {
                        auxValor = (auxProdutos[i].Valor - (auxProdutos[i].Valor * (0.01 * auxDesconto))).toFixed(2);
                        auxProdutos[i].PorcentagemDesconto = auxDesconto;
                        auxProdutos[i].ValorRealDesconto = auxValor.toString().substring(0, auxValor.toString().indexOf("."));
                        auxProdutos[i].ValorCentavosDesconto = auxValor.toString().substring((auxValor.toString().length - 2), auxValor.length);
                        auxProdutos[i].ValorDesconto = Number(auxValor);
                        auxProdutos[i].NomeGrupoPromocional = auxGrupoPromocional.NomeGrupoPromocional;
                        auxProdutos[i].IDGrupoPromocional = auxGrupoPromocional.IDGrupo;
                        auxProdutos[i].DescontoGrupoPromocional = true;
                    } else
                        auxProdutos[i].DescontoGrupoPromocional = false;
                }

                const idDispositivo = localStorage.getItem('idDispositivo') ? localStorage.getItem('idDispositivo') : '_' + Math.random().toString(36).substr(2, 9) + '_' + Math.random().toString(36).substr(2, 9) + '_' + Math.random().toString(36).substr(2, 9) + '_' + Math.random().toString(36).substr(2, 9);
                localStorage.setItem('idDispositivo', idDispositivo);

                const date = new Date();

                const map = {
                    Produtos: this.user.carrinho,
                    Bairro: this.state.endereco.bairro,
                    CEP: this.state.endereco.cep,
                    Cidade: this.state.endereco.cidade,
                    Complemento: this.state.endereco.complemento,
                    Logradouro: this.state.endereco.logradouro,
                    Numero: this.state.endereco.numero,
                    Referencia: this.state.endereco.referencia,
                    UF: this.state.endereco.uf,
                    Nome: this.state.nome,
                    Telefone: this.state.telefone,
                    TelefoneSecundario: this.state.telefoneSecundario,
                    Email: this.state.email,
                    IDVendedor: this.state.idVendedor,
                    IDVendedor_Timestamp: this.state.idVendedor + '_' + date.getTime(),
                    Observacao: this.state.observacao,
                    Total: this.state.totalCompra,
                    ValorRealString: this.state.valorReal,
                    ValorCentavosString: this.state.valorCentavos,
                    FormaPagamento: this.state.formaPagamento,
                    BandeiraCartao: this.state.bandeiraCartao,
                    IDUsuario: this.user.user.user.uid,
                    Status: 'Confirmado',
                    StatusPagamento: 'Pendente',
                    Plataforma: 'Web',
                    NumeroParcelas: this.state.iQuantidadeParcelas,
                    PrazoBoleto: this.state.totalCompra >= this.state.minimoBoletoQuinzeDias ? 15 : 10,
                    PrazoNota: 15,
                    TimestampPrevisaoRota: this.state.timestampPrevisaoRota,
                    IDRota: this.state.idRotaEntrega,
                    NomeRota: this.state.nomeRota,
                    IDCidade: this.state.idCidade,
                    VersaoSistema: parseFloat(config.versao.toFixed(1)),
                    Latitude: this.state.latitude,
                    Longitude: this.state.longitude,
                    Timestamp: date.getTime(),
                    IDDispositivo: idDispositivo + '_' + this.user.ip
                }

                const respPedido = (await usuarioService.enviarPedido(this.user.carrinho, map)).data;

                console.log('respPedido', respPedido);
                this.user.setBAtualizarProdutosHome(true);


                // eslint-disable-next-line default-case
                switch (respPedido.Codigo) {
                    case 'COD_01': //Compra realizada com sucesso
                        toast.success('Pedido realizado com sucesso', {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        this.user.esvaziarCarrinho();
                        setTimeout(() => this.props.history.push('meus-pedidos'), 300);
                        break;

                    case 'COD_02':
                        alert(`Há produtos sem estoque ou insuficientes, verifique seu carrinho e tente realizar a compra novamente.\n\nProdutos com quantidade de estoque inferior a comprada foram ajustados. Atente-se aos descontos e a forma de pagamento.`, false);
                        const produtosSemEstoque = respPedido.ProdutosSemEstoque;

                        let auxCarrinho = this.user.carrinho;
                        let auxItensRemovidos = [];
                        let auxNovoCarrinho = [];
                        let auxIndex;

                        for (let i = 0; i < auxCarrinho.length; i++) {
                            auxIndex = produtosSemEstoque.findIndex(x => x.Key === auxCarrinho[i].Key);

                            if (auxIndex !== -1)
                                if (produtosSemEstoque[auxIndex].QuantidadeEstoque > 0) {

                                    if (auxCarrinho[i].QuantidadeComprada > produtosSemEstoque[auxIndex].QuantidadeEstoque)
                                        auxCarrinho[i].QuantidadeComprada = produtosSemEstoque[auxIndex].QuantidadeEstoque;

                                    //Apenas variável de armazenamento para debug
                                    auxCarrinho[i].QuantidadeEstoque = produtosSemEstoque[auxIndex].QuantidadeEstoque;
                                    auxNovoCarrinho.push(auxCarrinho[i]);
                                } else {
                                    auxItensRemovidos.push(auxCarrinho[i]);
                                }
                            else
                                auxNovoCarrinho.push(auxCarrinho[i]);
                        }

                        //Aplica o desconto nos produtos do carrinho
                        let auxGrupoPromocional;
                        let auxDesconto;
                        let auxValorX;
                        for (let i = 0; i < auxNovoCarrinho.length; i++) {
                            auxDesconto = this.user.validarDescontoGrupoProduto(auxNovoCarrinho[i], auxNovoCarrinho);
                            auxGrupoPromocional = this.user.getGrupoPromocionalProduto(auxNovoCarrinho[i]);
                            if (auxDesconto) {
                                auxValorX = (auxNovoCarrinho[i].Valor - (auxNovoCarrinho[i].Valor * (0.01 * auxDesconto))).toFixed(2);
                                auxNovoCarrinho[i].PorcentagemDesconto = auxDesconto;
                                auxNovoCarrinho[i].ValorRealDesconto = auxValorX.toString().substring(0, auxValorX.toString().indexOf("."));
                                auxNovoCarrinho[i].ValorCentavosDesconto = auxValorX.toString().substring((auxValorX.toString().length - 2), auxValorX.length);
                                auxNovoCarrinho[i].ValorDesconto = Number(auxValorX);
                                auxNovoCarrinho[i].NomeGrupoPromocional = auxGrupoPromocional.NomeGrupoPromocional;
                                auxNovoCarrinho[i].IDGrupoPromocional = auxGrupoPromocional.IDGrupo;
                                auxNovoCarrinho[i].DescontoGrupoPromocional = true;
                            } else
                                auxNovoCarrinho[i].DescontoGrupoPromocional = false;
                        }
                        this.user.calcularTotalCompra(auxNovoCarrinho);
                        this.user.setContItens(auxNovoCarrinho.length);
                        this.user.setItensRemovidosFaltaEstoqueCarrinho(auxItensRemovidos);
                        this.setState({formaPagamento: 'Dinheiro'});

                        this.user.setCarrinho(auxNovoCarrinho);
                        this.user.updateLoading();
                        break;

                    case 'COD_03':
                        alert('Falha ao verificar estoques, tente finalizar a compra novamente.', false);
                        break;

                    case 'COD_04':
                        alert('Codigo pedido não recuperado, tente finalizar a compra novamente.', false);
                        break;

                    case 'COD_05':
                        alert('Erro desconhecido ao finalizar o pedido. Verifique seu histórico antes de tentar novamente.', false);
                        break;
                }


                // if (!this.state.bPentendeCompraAlteradaEstoque) {
                //     const idPedido = await usuarioService.getIDPedido();


                //     if (idPedido) {
                //         //Verifica a disponibilidade dos produtos, se não houver falta de estoque, realiza a venda.
                //         const respostaProdutos = await usuarioService.verificarEstoqueProdutos(this.user.carrinho, idPedido, map);

                //         //Coloca o estoque atual, quando debitado, na informação do PRODUTO, evitando erro de estoque de carrinho
                //         const estoqueAtualizado = respostaProdutos.data.ProdutosSemEstoque;
                //         let auxIndex2;
                //         let novoCarrinho = this.user.carrinho;
                //         for (let i = 0; i < estoqueAtualizado.length; i++) {
                //             auxIndex2 = novoCarrinho.findIndex(x => x.Key === novoCarrinho[i].Key);
                //             if (auxIndex2 != -1) {
                //                 novoCarrinho[auxIndex2].QuantidadeEstoque = estoqueAtualizado[i].QuantidadeEstoque;
                //             }
                //         }
                //         this.user.setCarrinho(novoCarrinho);
                //         this.user.setBAtualizarProdutosHome(true);

                //         //COD_01: Compra realizada com sucesso;
                //         //COD_02: Produto debitado com sucesso;
                //         //COD_03: Produtos debitados, mas falta confirmar a compra com a remoção dos produtos;
                //         //COD_04: Falha ao debitar estoques;
                //         //COD_05: Falha ao inserir a compra (estoque debitado);
                //         switch (respostaProdutos.data.Codigo) {
                //             case 'COD_01':
                //                 toast.success('Pedido realizado com sucesso', {
                //                     position: "top-center",
                //                     autoClose: 2000,
                //                     hideProgressBar: true,
                //                     closeOnClick: true,
                //                     pauseOnHover: false,
                //                     draggable: true,
                //                     progress: undefined,
                //                 });

                //                 //Quantidade estoque analizada com o valor atual do sistema; 


                //                 this.enviarMapCompra(map, idPedido);
                //                 this.user.esvaziarCarrinho();
                //                 setTimeout(() => this.props.history.push('meus-pedidos'), 300);
                //                 break;

                //             case 'COD_03':
                //                 let auxCarrinho = this.user.carrinho;
                //                 let auxItensRemovidos = [];
                //                 let auxNovoCarrinho = [];

                //                 let produtosSemEstoque = respostaProdutos.data.ProdutosSemEstoque;
                //                 let auxIndex;

                //                 for (let i = 0; i < auxCarrinho.length; i++) {
                //                     auxIndex = produtosSemEstoque.findIndex(x => x.Key === auxCarrinho[i].Key);

                //                     if (produtosSemEstoque[auxIndex].EstoqueAlterado) {
                //                         auxNovoCarrinho.push(auxCarrinho[i]);
                //                     } else {
                //                         auxItensRemovidos.push(auxCarrinho[i]);
                //                     }
                //                 }

                //                 this.user.esvaziarCarrinho();

                //                 this.user.setIDCompraConfirmadaEstoqueAlterado(idPedido);
                //                 this.user.setCarrinho(auxNovoCarrinho);
                //                 this.user.calcularTotalCompra(auxNovoCarrinho);
                //                 this.user.setContItens(auxNovoCarrinho.length);
                //                 this.user.setItensRemovidosFaltaEstoqueCarrinho(auxItensRemovidos);

                //                 const sTime = '' + new Date().getTime();
                //                 localStorage.setItem('timestampCompraPendente', sTime);

                //                 setTimeout(() => this.user.updateModal('FinalizarCompraRemocaoProdutos'), 500);
                //                 break;

                //             case 'COD_05':
                //                 // alert('Houve um erro ao finalizar sua compra. Por favor verifique com o administrador do sistema ou tente novamente mais tarde!');
                //                 // setTimeout(() => this.user.updateLoading(), 200);
                //                 this.enviarMapCompra(map, idPedido);
                //                 break;
                //         }
                //     } else {
                //         alert('Houve um erro ao finalizar a compra, por favor tente novamente mais tarde!');
                //         this.props.history.push('/');
                //     }
                // } else {
                //     const timestmapCompraPendente = localStorage.getItem('timestampCompraPendente');

                //     if (timestmapCompraPendente) {
                //         let date = new Date();
                //         date.setTime(timestmapCompraPendente);
                //         var diffMs = (date - new Date()); // milliseconds between now & Christmas
                //         var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) * -1;

                //         if (diffMins < 3) {
                //             this.enviarMapCompra(map, this.user.idCompraConfirmadaEstoqueAlterado);
                //         } else {
                //             this.setState({ bPentendeCompraAlteradaEstoque: false });
                //             localStorage.removeItem('timestampCompraPendente');
                //             this.user.esvaziarCarrinho();
                //             setTimeout(() => this.user.updateModal('CompraCancelada'), 500);
                //         }
                //     }
                //     //Validar tempo para concluir a compra após falta de estoque de produtos;
                //     //this.enviarMapCompra(map, this.user.idCompraConfirmadaEstoqueAlterado);
                // }
            } else {
                toast.warn('O valor de pedido mínimo é de R$ ' + this.state.minimoPedido.toFixed(2).replace('.', ','), {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            toast.error('Não é possível finalizar uma compra de valor R$ 0,00', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async enviarMapCompra(map, idPedido) {
        try {
            const totalCompra = await usuarioService.getTotalPedidoCreditoVoucher(this.user.user.user.uid, map.Total, idPedido);

            if (totalCompra !== map.Total) {
                let valorDesconto = totalCompra;
                if (totalCompra < 0) {
                    valorDesconto = totalCompra * -1;
                } else {
                    valorDesconto = (totalCompra - map.Total) * -1;
                }
                const auxValor = totalCompra.toFixed(2).replace('.', ',');
                if (totalCompra > 0) {
                    map.Total = totalCompra;
                    map.ValorRealString = auxValor.substring(0, auxValor.indexOf(","));
                    map.ValorCentavosString = auxValor.substring((auxValor.length - 2));
                } else {
                    map.Total = 0;
                    map.ValorRealString = "0";
                    map.ValorCentavosString = "00";
                }
                map.ValorDescontoVoucher = valorDesconto;
            }

            const resp = usuarioService.setFinalizarCompra(idPedido, map).then(resp => {
                toast.success('Pedido realizado com sucesso', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });

                this.user.esvaziarCarrinho();
                setTimeout(() => this.props.history.push('meus-pedidos'), 300);
            });

        } catch (error) {
            console.log(error)
            alert('Houve um erro ao finalizar a compra, por favor tente novamente mais tarde!');
            this.props.history.push('/');
        }
    }

    navegarMeusPedidos(e) {
        e.preventDefault();
        setTimeout(() => this.props.history.push('meus-pedidos'), 300);
    }

    render() {
        let props = this.context;

        return (
            <div>
                <main className="offcanvas-enabled" style={{ paddingTop: '5rem' }}>
                    <section className="ps-lg-4 pe-lg-3 pt-4">
                        <div className="px-3 pt-2">
                            {/* Page title + breadcrumb*/}
                            <nav className="mb-4" aria-label="breadcrumb">
                                <ol className="breadcrumb flex-lg-nowrap">
                                    <li className="breadcrumb-item"><a className="text-nowrap" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/')}><i className="ci-home" />Início</a></li>
                                    <li className="breadcrumb-item text-nowrap"><a style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/produtos-lista')}>Catálogo de produtos</a>
                                    </li>
                                    <li className="breadcrumb-item text-nowrap active" aria-current="page">Meu carrinho</li>
                                </ol>
                            </nav>
                            {/* Content*/}
                            {/* Checkout form*/}
                            <form className="needs-validation" noValidate>
                                <div className="row g-0 pb-5 mb-xl-3">
                                    {/* Delivery details*/}
                                    <div className="col-xl-6 mb-3">
                                        <h1 className="h2 mb-4">Checkout</h1>
                                        <h2 className="h5 mb-4">Dados para entrega</h2>
                                        <div className="d-flex flex-wrap justify-content-between align-items-center rounded-3 border py-2 px-3 mb-4">
                                            <div className="d-flex align-items-center me-3 py-2">
                                                {/* <img className="rounded-circle" src="img/grocery/newyork.jpg" width={50} alt="New York" /> */}
                                                <div className="ps-3">
                                                    {/* <div className="fs-ms text-muted">Your city is:</div> */}
                                                    <div className="fs-md fw-medium text-heading">
                                                        <div style={{ display: 'inline-flex', flexDirection: 'row' }}><div className="text-muted" style={{ marginRight: 4 }}>Logradouro:</div> {this.state.endereco.logradouro}
                                                            <div>
                                                                {
                                                                    this.state.endereco.complemento && this.state.endereco.complemento.length > 0 && <div>
                                                                        , {this.state.endereco.complemento}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div><br></br>
                                                        <div style={{ display: 'inline-flex', flexDirection: 'row' }}><div className="text-muted" style={{ marginRight: 4 }}>Número: </div>{this.state.endereco.numero}</div><br></br>
                                                        <div style={{ display: 'inline-flex', flexDirection: 'row' }}><div className="text-muted" style={{ marginRight: 4 }}>Bairro:</div> {this.state.endereco.bairro} </div><br></br>

                                                        <small className="mt-2">
                                                            <div>
                                                                {
                                                                    this.state.endereco.referencia && this.state.endereco.referencia.length > 0 &&
                                                                    <div className="mt-2">
                                                                        <div style={{ display: 'inline-flex', flexDirection: 'row' }}><div className="text-muted" style={{ marginRight: 4 }}>Referência:</div> {this.state.endereco.referencia} </div><br></br>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="text-muted">{this.state.endereco.cidade}/{this.state.endereco.uf} - {this.state.endereco.cep}</div>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="py-2"><a className="btn btn-light btn-sm btn-shadow mt-3 mt-sm-0" href="#"><i className="ci-edit me-2" />Alterar endereço</a></div> */}
                                        </div>
                                        <div className="row gx-4 gy-3">
                                            <div className="col-sm-4">
                                                <label className="form-label" htmlFor="co-fn">Nome <span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" id="co-fn" disabled value={this.state.nome} />
                                            </div>
                                            <div className="col-sm-4">
                                                <label className="form-label" htmlFor="co-ln">Telefone <span className="text-danger">*</span></label>
                                                <input className="form-control" type="tel" id="co-phone" disabled value={this.state.telefone} />
                                            </div>
                                            {
                                                this.state.telefoneSecundario.length > 0 &&
                                                <div className="col-sm-4">
                                                    <label className="form-label" htmlFor="co-ln">Telefone secundário<span className="text-danger">*</span></label>
                                                    <input className="form-control" type="tel" id="co-phone2" disabled value={this.state.telefoneSecundario} />
                                                </div>
                                            }
                                            <div className="col-sm-6">
                                                <label className="form-label" htmlFor="co-fn">Email</label>
                                                <input className="form-control bg-image-none" type="email" id="co-email" disabled value={this.state.email} />
                                            </div>
                                            <div className="col-sm-12">
                                                <label className="form-label" htmlFor="co-note">Observação</label>
                                                <textarea className="form-control bg-image-none" id="co-note" rows={3} placeholder="Alguma observação para a hora da entrega?" maxLength={200} value={this.state.observacao} onChange={(evt) => this.setState({ observacao: evt.target.value })} />
                                            </div>
                                        </div>
                                        {/* <div className="rounded-3 border p-4 my-3">
                                            <h2 className="h6 pb-2">Cupom de desconto</h2>
                                            <div className="d-flex">
                                                <input className="form-control bg-image-none me-3" type="text" placeholder="Coupon code" />
                                                <button className="btn btn-outline-primary" type="button">Aplicare</button>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* Order summary + payment*/}
                                    <div className="col-xl-5 offset-xl-1 mb-2">
                                        <div className="bg-light rounded-3 py-5 px-4 px-xxl-5">
                                            <h2 className="h5 pb-3">Seu pedido</h2>
                                            {
                                                props &&
                                                <ListaPedido data={props} history={this.props.history} />
                                            }
                                            {this.user?.creditoVoucher === 0 ?
                                                <h3 className="fw-normal text-center my-2 py-2">R$ {this.state.valorReal},<small>{this.state.valorCentavos}</small></h3>
                                                :
                                                <div>
                                                    {this.state.totalCompra > 0 &&
                                                        <h4 className="fw-normal text-center my-2 py-2" style={{ fontSize: 12, color: 'red' }}>- R$ {this.user?.retornaTotalVoucherGasto().toFixed(2).replace('.', ',')} <small>(Voucher)</small></h4>
                                                    }
                                                    <h3 className="fw-normal text-center my-2 py-2 mt-1">R$ {this.user?.retornaTotalCompraValidandoVoucher().toFixed(2).replace('.', ',')}</h3>

                                                    <div style={{ display: 'inline-block', fontSize: 10, color: 'red' }}>* O desconto do Voucher será confirmado ao finalizar a compra</div>

                                                </div>
                                            }

                                            {this.state.formaPagamento === 'Boleto' &&
                                                <div>
                                                    <small style={{ fontSize: 10 }}>* Compras acima de R$ {this.state.minimoBoletoQuinzeDias.toFixed(2).replace('.', ',')} o boleto terá um prazo de 15 dias</small>
                                                    <br></br><small style={{ fontSize: 10 }}>* Compras acima de R$ {this.state.minimoDuasPacelasBoleto.toFixed(2).replace('.', ',')} poderão ser pagas em 2 boletos</small>
                                                </div>
                                            }
                                            <div className="accordion accordio-flush shadow-sm rounded-3 mb-4" id="payment-methods">
                                                {this.state.totalCompra >= this.state.minimoBoleto && this.state.bCompraBoleto ?
                                                    <div className="accordion-item border-bottom">
                                                        <div className="accordion-header py-3 px-3">
                                                            <div className="form-check d-table" data-bs-toggle="collapse" data-bs-target="#boleto">
                                                                <input className="form-check-input" type="radio" name="license" id="payment-boleto" checked={this.state.formaPagamento === 'Boleto'} onChange={() => this.setState({ formaPagamento: 'Boleto' })} />
                                                                <label className="form-check-label fw-medium text-dark" htmlFor="payment-boleto">Boleto<i className="ci-document text-muted fs-base align-middle mt-n1 ms-2" /></label>
                                                            </div>
                                                        </div>

                                                        <div className={this.state.formaPagamento === 'Boleto' ? "collapse show" : "collapse"} id="credit-card" data-bs-parent="#payment-methods">
                                                            <div className="accordion-body py-2">
                                                                {/* <input className="form-control bg-image-none mb-3" type="text" placeholder="Card number" /> */}
                                                                <div className="row">
                                                                    {this.state.totalCompra < this.state.minimoDuasPacelasBoleto ?
                                                                        <div className="col-sm mb-3">
                                                                            <input className="form-check-input" id="payment-master1" type="radio" checked={true} onChange={() => null} /> <div style={{ color: 'GrayText', fontSize: 12, display: 'inline-block' }}>Vencimento em {this.state.totalCompra >= this.state.minimoBoletoQuinzeDias ? 15 : 10} dias </div>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <div className="col-sm mb-3">
                                                                                <input className="form-check-input" id="payment-maste2" type="radio" checked={this.state.iQuantidadeParcelas === 1} onChange={() => this.setState({ iQuantidadeParcelas: 1 })} /> <div style={{ color: 'GrayText', fontSize: 12, display: 'inline-block' }}>Boleto único com prazo de 15 dias </div>
                                                                            </div>

                                                                            <div className="col-sm mb-3">
                                                                                {/* <input className="form-check-input" id="payment-master" type="radio" checked={this.state.iQuantidadeParcelas === 2} onChange={() => this.setState({ iQuantidadeParcelas: 2 })} /> <div style={{ color: 'GrayText', fontSize: 12, display: 'inline-block' }}>Dois boletos, R$ {(this.state.totalCompra / 2).toFixed(2).replace('.', ',')} com prazo de 10 dias e R$ {(this.state.totalCompra / 2).toFixed(2).replace('.', ',')} com prazo de 15 dias</div> */}
                                                                                <input className="form-check-input" id="payment-master3" type="radio" checked={this.state.iQuantidadeParcelas === 2} onChange={() => this.setState({ iQuantidadeParcelas: 2 })} /> <div style={{ color: 'GrayText', fontSize: 12, display: 'inline-block' }}>Dois boletos, 50% do total com prazo de 10 dias e 50% restantes com prazo de 15 dias</div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    : <div>
                                                        {
                                                            this.state.bCompraBoleto &&
                                                            <small>* Compras no boleto só são permitidas acima de R$ {this.state.minimoBoleto.toFixed(2).replace('.', ',')}</small>
                                                        }
                                                    </div>
                                                }

                                                {this.state.totalCompra >= this.state.minimoCheque && this.state.bCompraCheque ?
                                                    <div className="accordion-item border-bottom">
                                                        <div className="accordion-header py-3 px-3">
                                                            <div className="form-check d-table" data-bs-toggle="collapse" data-bs-target="#cheque">
                                                                <input className="form-check-input" type="radio" name="license" id="payment-cheque" checked={this.state.formaPagamento === 'Cheque'} onChange={() => this.setState({ formaPagamento: 'Cheque' })} />
                                                                <label className="form-check-label fw-medium text-dark" htmlFor="payment-cheque">Cheque<i className="ci-document text-muted fs-base align-middle mt-n1 ms-2" /></label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    : <div>
                                                        {this.state.bCompraCheque &&
                                                            <small>* Compras no cheque só são permitidas acima de R$ {this.state.minimoCheque.toFixed(2).replace('.', ',')}</small>
                                                        }
                                                    </div>
                                                }



                                                {/* <div className="accordion-item border-bottom">
                                                    <div className="accordion-header py-3 px-3">
                                                        <div className="form-check d-table" data-bs-target="#credit-card">
                                                            <input className="form-check-input" type="radio" name="license" id="payment-card" checked={this.state.formaPagamento === 'Cartão de crédito'} onChange={() => this.setState({ formaPagamento: 'Cartão de crédito' })} />
                                                            <label className="form-check-label fw-medium text-dark" htmlFor="payment-card">Cartão de crédito<i className="ci-card text-muted fs-lg align-middle mt-n1 ms-2" /></label>
                                                        </div>
                                                    </div> */}
                                                {/* <div className="collapse" id="credit-card" data-bs-parent="#payment-methods"> */}
                                                {/* <div className={this.state.formaPagamento === 'Cartão de crédito' ? "collapse show" : "collapse"} id="credit-card" data-bs-parent="#payment-methods">

                                                        <div className="accordion-body py-2"> */}
                                                {/* <input className="form-control bg-image-none mb-3" type="text" placeholder="Card number" /> */}
                                                {/* <div className="row">
                                                                <div className="col-3 mb-3">
                                                                    <input className="form-check-input" id="payment-master4" style={styleCartoes} type="radio" checked={this.state.bandeiraCartao === 'MasterCard'} onChange={() => this.setState({ bandeiraCartao: 'MasterCard' })} />
                                                                    <img src={master_card} width={40} alt="master_card" onClick={() => this.setState({ bandeiraCartao: 'MasterCard' })} />
                                                                </div>
                                                                <div className="col-3 mb-3">
                                                                    <input className="form-check-input" id="payment-visa" style={styleCartoes} type="radio" checked={this.state.bandeiraCartao === 'Visa'} onChange={() => this.setState({ bandeiraCartao: 'Visa' })} />
                                                                    <img src={visa_cartao} width={40} alt="visa_cartao" onClick={() => this.setState({ bandeiraCartao: 'Visa' })} />
                                                                </div>
                                                                <div className="col-3 mb-3">
                                                                    <input className="form-check-input" id="payment-elo" style={styleCartoes} type="radio" checked={this.state.bandeiraCartao === 'Elo'} onChange={() => this.setState({ bandeiraCartao: 'Elo' })} />
                                                                    <img src={elo_cartao} width={40} alt="elo_cartao" onClick={() => this.setState({ bandeiraCartao: 'Elo' })} />
                                                                </div>
                                                                <div className="col-3 mb-3">
                                                                    <input className="form-check-input" id="payment-american" style={styleCartoes} type="radio" checked={this.state.bandeiraCartao === 'AmericanExpress'} onChange={() => this.setState({ bandeiraCartao: 'AmericanExpress' })} />
                                                                    <img src={american_express} width={40} alt="american_express" onClick={() => this.setState({ bandeiraCartao: 'AmericanExpress' })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                {/* <div className="accordion-item border-bottom">
                                                    <div className="accordion-header py-3 px-3">
                                                        <div className="form-check d-table" data-bs-target="#credit-card">
                                                            <input className="form-check-input" type="radio" name="license" id="payment-card-debito" checked={this.state.formaPagamento === 'Cartão de débito'} onChange={() => this.setState({ formaPagamento: 'Cartão de débito' })} />
                                                            <label className="form-check-label fw-medium text-dark" htmlFor="payment-card-debito">Cartão de débito<i className="ci-card text-muted fs-lg align-middle mt-n1 ms-2" /></label>
                                                        </div>
                                                    </div> */}
                                                {/* <div className="collapse" id="credit-card" data-bs-parent="#payment-methods"> */}
                                                {/* <div className={this.state.formaPagamento === 'Cartão de débito' ? "collapse show" : "collapse"} id="credit-card-debito" data-bs-parent="#payment-methods-debito">

                                                        <div className="accordion-body py-2"> */}
                                                {/* <input className="form-control bg-image-none mb-3" type="text" placeholder="Card number" /> */}
                                                {/* <div className="row">
                                                                <div className="col-3 mb-3">
                                                                    <input className="form-check-input" id="payment-master-debito" style={styleCartoes} type="radio" checked={this.state.bandeiraCartao === 'MasterCard'} onChange={() => this.setState({ bandeiraCartao: 'MasterCard' })} />
                                                                    <img src={master_card} width={40} alt="master_card" onClick={() => this.setState({ bandeiraCartao: 'MasterCard' })} />
                                                                </div>
                                                                <div className="col-3 mb-3">
                                                                    <input className="form-check-input" id="payment-visa-debito" style={styleCartoes} type="radio" checked={this.state.bandeiraCartao === 'Visa'} onChange={() => this.setState({ bandeiraCartao: 'Visa' })} />
                                                                    <img src={visa_cartao} width={40} alt="visa_cartao" onClick={() => this.setState({ bandeiraCartao: 'Visa' })} />
                                                                </div>
                                                                <div className="col-3 mb-3">
                                                                    <input className="form-check-input" id="payment-elo-debito" style={styleCartoes} type="radio" checked={this.state.bandeiraCartao === 'Elo'} onChange={() => this.setState({ bandeiraCartao: 'Elo' })} />
                                                                    <img src={elo_cartao} width={40} alt="elo_cartao" onClick={() => this.setState({ bandeiraCartao: 'Elo' })} />
                                                                </div>
                                                                <div className="col-3 mb-3">
                                                                    <input className="form-check-input" id="payment-american-debito" style={styleCartoes} type="radio" checked={this.state.bandeiraCartao === 'AmericanExpress'} onChange={() => this.setState({ bandeiraCartao: 'AmericanExpress' })} />
                                                                    <img src={american_express} width={40} alt="american_express" onClick={() => this.setState({ bandeiraCartao: 'AmericanExpress' })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {this.state.bCompraNota &&
                                                    <div className="accordion-item">
                                                        <div className="accordion-header py-3 px-3">
                                                            <div className="form-check d-table" data-bs-toggle="collapse" data-bs-target="#cash">
                                                                <input className="form-check-input" type="radio" name="license" id="payment-cash" checked={this.state.formaPagamento === 'Nota'} onChange={() => this.setState({ formaPagamento: 'Nota' })} />
                                                                <label className="form-check-label fw-medium text-dark" htmlFor="payment-cash">Nota - 15 dias<i className="ci-edit text-muted fs-lg align-middle mt-n1 ms-2" /></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* <div className="accordion-item">
                                                    <div className="accordion-header py-3 px-3">
                                                        <div className="form-check d-table" data-bs-toggle="collapse" data-bs-target="#cash">
                                                            <input className="form-check-input" type="radio" name="license" id="payment-cash" checked={this.state.formaPagamento === 'Pix'} onChange={() => this.setState({ formaPagamento: 'Pix' })} />
                                                            <label className="form-check-label fw-medium text-dark" htmlFor="payment-cash">Pix<i className="ci-coin text-muted fs-lg align-middle mt-n1 ms-2" /></label>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="accordion-item">
                                                    <div className="accordion-header py-3 px-3">
                                                        <div className="form-check d-table" data-bs-toggle="collapse" data-bs-target="#cash">
                                                            <input className="form-check-input" type="radio" name="license" id="payment-cash" checked={this.state.formaPagamento === 'Dinheiro'} onChange={() => this.setState({ formaPagamento: 'Dinheiro' })} />
                                                            <label className="form-check-label fw-medium text-dark" htmlFor="payment-cash">Dinheiro<i className="ci-wallet text-muted fs-lg align-middle mt-n1 ms-2" /></label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="collapse" id="cash" data-bs-parent="#payment-methods">
                                                        <div className="accordion-body pt-2">
                                                            <p className="fs-sm mb-0">I will pay with cash to the courier on delivery.</p>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>

                                            {this.state.stringPrevisaoRota &&
                                                <div>
                                                    <div className="pt-2 text-center">
                                                        <small>A entrega está prevista para {this.state.stringPrevisaoRota}</small>
                                                        {/* <button className="btn btn-primary d-block w-100" >Alterar data de entrega</button> */}
                                                    </div>
                                                    <div className="text-center">
                                                        <small style={{ fontSize: 10 }}>* Em caso de feriados, a entrega será reagendada</small>
                                                    </div>

                                                    <div className="text-center">
                                                        <small style={{ textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => this.user.updateModal('AlterarDataEntrega')}>Alterar data de previsão de entrega</small>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                this.state.bPentendeCompraAlteradaEstoque &&
                                                <div className="pt-2">
                                                    <button className="btn btn-accent d-block w-10" style={{ fontSize: 12 }} onClick={e => this.limparCarrinhoCompraEstoque(e)}>Esvaziar carrinho</button>
                                                    <div style={{ display: 'inline-block', fontSize: 12 }}>* Ao sair desta página esta compra será descartada</div>
                                                </div>
                                            }

                                            <div className="pt-4">
                                                {
                                                    this.user?.bloquearCompraPagamentoPendente && this.user?.pagamentoPendente ?
                                                        <div>
                                                            <div style={{ display: 'inline-block', fontSize: 12 }}>* Para realizar um novo pedido você deve realizar todos os pagamentos em aberto</div>
                                                            <button className="btn btn-accent d-block w-100 mt-2" onClick={e => this.navegarMeusPedidos(e)}>Meus pedidos</button>
                                                        </div>
                                                        :
                                                        <button className="btn btn-primary d-block w-100" onClick={e => this.finalizarCompra(e)}>{!this.state.bPentendeCompraAlteradaEstoque ? 'Finalizar compra' : 'Confirmar compra'}</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </main>

            </div >
        )
    }
}

export default MeuCarrinho