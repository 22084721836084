import React, { Component } from 'react';
import semImagem from '../../assets/img/semImagem.png';
import UsuarioService from '../../service/usuario.service';
import UserContext from '../../contexts/user';

import { toast } from 'react-toastify';

const usuarioService = new UsuarioService();

const screenWidth = window.innerWidth;


class TabelaPedidos extends Component {
    static contextType = UserContext;
    user;

    // loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

    constructor(props) {
        super(props);
        this.user = this.props.data

        this.state = {
            signed: false,
            pedidos: [],
            copiaPedidos: [],
            paginaAtual: 1,
            totalItensPorPagina: 30,
            filtro: 'Mais recentes',
            pageLoaded: false
        }
    }

    componentDidMount() {
        this.user = this.context;

        setTimeout(() => window.scrollTo(0, 0), 100);

        usuarioService.getPedidos(this.user.user.user.uid).once('value', snapshot => {
            let dados;
            let auxPedidos = [];
            let auxValor;
            snapshot.forEach((item) => {
                dados = item.val();

                auxValor = dados.Total.toFixed(2).replace('.', ',');

                auxPedidos.push({
                    Key: item.key,
                    Bairro: dados.Bairro,
                    BandeiraCartao: dados.BandeiraCartao,
                    CEP: dados.CEP,
                    Cidade: dados.Cidade,
                    Complemento: dados.Complemento,
                    Email: dados.Email,
                    FormaPagamento: dados.FormaPagamento,
                    IDUsuario: dados.IDUsuario,
                    IDVendedor: dados.IDVendedor,
                    Logradouro: dados.Logradouro,
                    Nome: dados.Nome,
                    Numero: dados.Numero,
                    Observacao: dados.Observacao,
                    Produtos: dados.Produtos,
                    Referencia: dados.Referencia,
                    Status: dados.Status,
                    StatusPagamento: dados.StatusPagamento,
                    Telefone: dados.Telefone,
                    TelefoneSecundario: dados.TelefoneSecundario,
                    Timestamp: dados.Timestamp,
                    Total: dados.Total,
                    ValorString: auxValor,
                    UF: dados.UF,
                    ValorReal: auxValor.substring(0, auxValor.indexOf(",")),
                    ValorCentavos: auxValor.substring((auxValor.length - 2), auxValor.length),
                    Data: usuarioService.tratarData(dados.Timestamp),
                    Hora: usuarioService.tratarHora(dados.Timestamp),
                    DataHoraConfirmado: usuarioService.tratarDataHora(dados.Timestamp),
                    DataHoraSeparacao: usuarioService.tratarDataHora(dados.TimestampSeparacao),
                    DataHoraPrevisaoRota: usuarioService.tratarData(dados.TimestampPrevisaoRota),
                    DataHoraRota: usuarioService.tratarDataHora(dados.TimestampRota),
                    DataHoraEntregue: usuarioService.tratarDataHora(dados.TimestampEntregue),
                    DataCancelamento: usuarioService.tratarData(dados.TimestampCancelado),
                    PrazoBoleto: dados.PrazoBoleto,
                    PrazoNota: dados.PrazoNota,
                    NumeroParcelas: dados.NumeroParcelas,
                    IDGrupoPromocional: dados.IDGrupoPromocional,
                    NomeGrupoPromocional: dados.NomeGrupoPromocional,
                    PorcentagemDesconto: dados.PorcentagemDesconto,
                    ValorRealDesconto: dados.ValorRealDesconto,
                    ValorCentavosDesconto: dados.ValorCentavosDesconto,
                    ValorDesconto: dados.ValorDesconto,
                    DescontoGrupoPromocional: dados.DescontoGrupoPromocional,
                    PDFbase64: dados.PDFbase64 ? dados.PDFbase64 : false,
                    PDFbase64Parcela2: dados.PDFbase64Parcela2 ? dados.PDFbase64Parcela2 : false,
                    VencimentoBoleto: dados.TimestampVencimento ? usuarioService.tratarDataArquivo(dados.TimestampVencimento) : false,
                    DataPedido: dados.TimestampVencimento ? usuarioService.tratarDataArquivo(dados.Timestamp) : false,
                    ValorDescontoVoucher: dados.ValorDescontoVoucher ? dados.ValorDescontoVoucher : false
                });
            });
            //Ordem alfabética
            // auxPedidos.sort((a, b) => a.Timestamp.localeCompare(b.Timestamp));
            this.props.setTotalPaginas(Math.ceil(auxPedidos.length / this.state.totalItensPorPagina));

            this.setState({ pedidos: auxPedidos.reverse(), copiaPedidos: auxPedidos });
        });

    }

    componentWillUnmount() {
        this.setState({ pedidos: [] });
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.props.data;

        if (this.user.signed !== prevState.signed) {
            this.setState({ signed: this.user.signed });
        }

        if (this.user.paginaAtual !== prevProps.data.paginaAtual) {
            this.setState({ paginaAtual: this.user.paginaAtual });
        }

        if (this.user.filtro && this.user.filtro !== prevProps.data.filtro) {
            if (this.user.filtro === 'Mais recentes') {
                this.user.updateLoading();
                const auxPedidos = this.state.copiaPedidos.sort((a, b) => a.Timestamp - b.Timestamp);
                this.setState({ pedidos: auxPedidos.reverse(), filtro: 'Mais recente' });
                this.props.setTotalPaginas(Math.ceil(auxPedidos.length / this.state.totalItensPorPagina));
                this.props.navegarPagina(0);
                setTimeout(() => this.user.updateLoading(), 500);
            } else
                if (this.user.filtro === 'Mais antigos') {
                    this.user.updateLoading();
                    const auxPedidos = this.state.copiaPedidos.sort((a, b) => a.Timestamp - b.Timestamp);
                    this.setState({ pedidos: auxPedidos, filtro: 'Mais recente' });
                    this.props.setTotalPaginas(Math.ceil(auxPedidos.length / this.state.totalItensPorPagina));
                    this.props.navegarPagina(0);
                    setTimeout(() => this.user.updateLoading(), 500);
                } else
                    if (this.user.filtro === 'Pagos') {
                        this.user.updateLoading();
                        const auxPedidos = this.state.copiaPedidos.filter((a) => a.StatusPagamento === 'Pago');
                        this.setState({ pedidos: auxPedidos, filtro: 'Pagos' });
                        this.props.setTotalPaginas(Math.ceil(auxPedidos.length / this.state.totalItensPorPagina));
                        this.props.navegarPagina(0);
                        setTimeout(() => this.user.updateLoading(), 500);
                    } else
                        if (this.user.filtro === 'Em aberto') {
                            this.user.updateLoading();
                            const auxPedidos = this.state.copiaPedidos.filter((a) => a.StatusPagamento === 'Pendente' || (a.Status !== 'Entregue' && a.Status !== 'Cancelado'));
                            this.setState({ pedidos: auxPedidos, filtro: 'Pagos' });
                            this.props.setTotalPaginas(Math.ceil(auxPedidos.length / this.state.totalItensPorPagina));
                            this.props.navegarPagina(0);
                            setTimeout(() => this.user.updateLoading(), 500);
                        } else
                            if (this.user.filtro === 'Cancelados') {
                                this.user.updateLoading();
                                const auxPedidos = this.state.copiaPedidos.filter((a) => a.StatusPagamento === 'Cancelado' || a.Status === 'Cancelado');
                                this.setState({ pedidos: auxPedidos, filtro: 'Pagos' });
                                this.props.setTotalPaginas(Math.ceil(auxPedidos.length / this.state.totalItensPorPagina));
                                this.props.navegarPagina(0);
                                setTimeout(() => this.user.updateLoading(), 500);
                            }
        }
    }

    detalharPedido(value) {
        this.user.updateLoading();
        this.user.selecionarPedido(JSON.stringify(value));
        this.props.history.push({
            pathname: '/pedido-detalhado',
            search: '?' + value.Key
        });
    }

    render() {
        let props = this.context;

        return (
            <div className="table-responsive fs-md mb-4" style={{ overflowX: 'auto', WebkitOverflowScrolling: 'touch', maxWidth: screenWidth < 1024 ? 250 : 'auto' }}>
                <table className="table table-hover mb-0" >
                    <thead>
                        <tr>
                            <th>Número #</th>
                            <th>Data da compra</th>
                            <th>Status</th>
                            <th>Total</th>
                            <th>Pagamento</th>
                            <th>Detalhes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (this.state.pedidos.map((value, i) =>
                                (i >= this.state.totalItensPorPagina * (this.state.paginaAtual - 1) && i < this.state.paginaAtual * this.state.totalItensPorPagina) &&
                                <tr key={value.Key}>
                                    <td className="py-3"><a className="nav-link-style fw-medium fs-sm" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>{value.Key}</a></td>
                                    <td className="py-3"><small>{value.Data} às {value.Hora}</small></td>
                                    <td className="py-3"><span className={value.Status === 'Confirmado' ? 'badge m-0 bg-info' : (value.Status === 'Em separação' ? 'badge m-0 bg-warning' : (value.Status === 'Em rota' ? 'badge m-0 bg-primary' : (value.Status === 'Cancelado' ? 'badge m-0 bg-danger' : 'badge m-0 bg-success')))} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>{value.Status}</span></td>
                                    <td className="py-3">R$ {value.ValorReal},<small>{value.ValorCentavos}</small></td>
                                    <td className="py-3"><span className={value.StatusPagamento === 'Pendente' ? 'badge m-0 bg-warning' : (value.StatusPagamento === 'Pago' ? 'badge m-0 bg-success' : 'badge m-0 bg-danger')} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{value.StatusPagamento}</span></td>
                                    <td className="py-3"><a className="text-center" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', }} onClick={() => this.detalharPedido(value)}><i className="ci-search" /></a></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TabelaPedidos