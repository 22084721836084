import React, { Component } from 'react';
import semImagem from '../../assets/img/semImagem.png';
import UsuarioService from '../../service/usuario.service';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toast } from 'react-toastify';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 5 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 1490 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const usuarioService = new UsuarioService();


class ProdutosHome extends Component {
    user;

    loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

    constructor(props) {
        super(props);

        this.user = this.props.data

        this.state = {
            produtos: [],
            signed: false,
            listaProdutosPromocionais: this.user.listaProdutosPromocionais,
        }
    }

    componentDidMount() {
        this.buscarProdutos();
    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.props.data;

        if (this.user.signed !== prevState.signed) {
            this.setState({ signed: this.user.signed });
            this.buscarProdutos();
        }

        if (this.user.listaProdutosPromocionais !== prevProps.data.listaProdutosPromocionais) {
            this.setState({ listaProdutosPromocionais: this.user.listaProdutosPromocionais });
        }
    }

    async buscarProdutos() {
        if (this.user.signed) {
            let produtos = (await usuarioService.getProdutosCatalogo())?.data;

            console.log(produtos)
            //Ordem alfabética
            // produtos.sort((a, b) => a.Nome.localeCompare(b.Nome));

            const auxProdutos = produtos.sort(() => Math.random() - 0.5);

            this.setState({ produtos: auxProdutos });
        } else {
            const produtos = await usuarioService.getProdutosNaoAutenticadoHome();
            if (produtos.data) {
                this.setState({ produtos: produtos.data });
            }
        }
    }

    selecionarProduto(produto) {
        if (this.user.signed) {
            if (produto.QuantidadeEstoque > 0) {
                this.user.updateLoading();
                this.user.selecionarProduto(JSON.stringify(produto));
                this.props.history.push({
                    pathname: '/produto-detalhado',
                    search: '?' + produto.Key
                });
            } else {
                toast.warn('Produto sem estoque', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            toast.warning('Para visualizar mais detalhes, você precisa realizar login!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }

    navegarProdutosListaCategoria(grupo) {
        if (this.user.signed) {
            this.user.updateCategoria(grupo);
            setTimeout(() => this.props.history.push('/produtos-lista'), 100);
        } else {
            toast.warning('Para visualizar mais detalhes, você precisa realizar login!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.produtos.length > 0 ?
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={false}
                        // autoPlaySpeed={1000}
                        keyBoardControl={true}
                        // customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        // removeArrowOnDeviceType={["tablet", "mobile"]}
                        // deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {
                            (this.state.produtos.map((value) =>
                                <div key={value.Key} style={{ padding: 10 }}>
                                    <div className="card product-card card-static pb-3" style={{ opacity: value.QuantidadeEstoque <= 0 ? 0.7 : 100 }}>
                                        {
                                            this.state.listaProdutosPromocionais.findIndex(x => x.IDProduto === value.Key) !== -1 &&
                                            <div className="card product-card card-static"><span className="badge bg-danger badge-shadow">{this.state.listaProdutosPromocionais[this.state.listaProdutosPromocionais.findIndex(x => x.IDProduto === value.Key)].NomeGrupoPromocional} | {this.state.listaProdutosPromocionais[this.state.listaProdutosPromocionais.findIndex(x => x.IDProduto === value.Key)].PorcentagemDesconto}%</span></div>
                                        }
                                        {/* <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Adicionar à lista de desejos"><i className="ci-heart" /></button> */}
                                        <a className="card-img-top d-block overflow-hidden" onClick={() => this.selecionarProduto(value)} style={{ height: 170, cursor: 'pointer' }}>
                                            {/* <a className="card-img-top d-block overflow-hidden"  onClick={() => this.selecionarProduto(value)} style={{ height: 170 }}> */}
                                            {value.URL_Imagem ?
                                                <img src={value.URL_Imagem} alt={value.Nome} style={{ maxWidth: 520, maxHeight: 170, display: 'block', margin: '0 auto' }} />
                                                :
                                                <img style={{ cursor: 'pointer' }} src={semImagem} alt={value.Nome} />
                                            }
                                        </a>
                                        <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" style={{ cursor: 'pointer' }} onClick={() => this.navegarProdutosListaCategoria(value.Grupo)}>{value.Grupo}</a>
                                            <h3 className="product-title fs-sm text-truncate"><a style={{ cursor: 'pointer' }} onClick={() => this.selecionarProduto(value)}>{value.Nome}</a></h3>
                                            {/* <div className="product-price"><span className="text-accent">R$ 4.<small>30</small></span></div> */}
                                            {this.state.signed ?
                                                <div className="product-price"><span className="text-accent">R$ {value.ValorReal},<small>{value.ValorCentavos}</small></span></div>
                                                // <div className="product-price"><span className="text-accent">R$ {value.ValorString}</span></div>
                                                :
                                                null
                                                // <div className="product-price"><span className="text-accent">saiba mais</span></div>
                                            }

                                            {
                                                value.QuantidadeEstoque <= 0 ? <div style={{ position: 'absolute', fontSize: 14, color: 'red', fontWeight: '700', right: 10, bottom: 0 }}>Indisponível</div>
                                                    : null
                                            }
                                        </div>
                                        {this.state.signed ?
                                            <div>
                                                {
                                                    value.QuantidadeEstoque > 0 &&
                                                    <div className="product-floating-btn">
                                                        <button className="btn btn-primary btn-shadow btn-sm" type="button" onClick={() => this.selecionarProduto(value)}>+<i className="ci-cart fs-base ms-1" /></button>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div style={{ alignSelf: 'flex-end' }}>
                                                <button className="btn btn-accent btn-shadow btn-sm" type="button" onClick={() => this.selecionarProduto(value)}>Mais detalhes<i className="ci-add-circle ms-2" /></button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                    : null
                }
            </div>
        )
    }
}

export default ProdutosHome