/* eslint-disable */

import React, { Component } from 'react';

import UserContext from '../../contexts/user';
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";


class AlterarDataEntregaPopUp extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.user = this.props.data

        this.state = {
            dataSelecionada: null,
            datasExcluidas: [],
            datasDisponiveis: [],
            maxDate: new Date()
        }
    }

    componentDidMount() {
        this.user = this.context;



        //Criar um loop e excluir as demais datas
        const aDatasDisponiveis = this.user.aDatasDisponiveisRota;
        let auxDate = new Date(this.user.timestampPrevisaoRota);

        if (aDatasDisponiveis.length > 0) {
            let auxContDay = new Date().getDate();

            auxDate = new Date();
            auxDate.setHours(21);
            auxDate.setMinutes(59);
            auxDate.setSeconds(59);
            auxDate.setMilliseconds(999);

            const date1 = new Date(aDatasDisponiveis[0].getTime() <= new Date().getTime() ? aDatasDisponiveis[0].getTime() : new Date());
            const date2 = new Date(aDatasDisponiveis[aDatasDisponiveis.length - 1]);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            let copiaTimestampDatas = [];
            for (let j = 0; j < aDatasDisponiveis.length; j++) {
                copiaTimestampDatas.push(aDatasDisponiveis[j].getDate() + '/' + (aDatasDisponiveis[j].getMonth() + 1) + '/' + aDatasDisponiveis[j].getFullYear());
            }

            auxDate = new Date();
            auxDate.setHours(21);
            auxDate.setMinutes(59);
            auxDate.setSeconds(59);
            auxDate.setMilliseconds(999);

            let datasExcluidas = [];

            for (let i = 0; i < diffDays; i++) {
                auxDate.setDate(auxContDay);
                if (!copiaTimestampDatas.includes(auxDate.getDate() + '/' + (auxDate.getMonth() + 1) + '/' + auxDate.getFullYear())) {
                    datasExcluidas.push(auxDate.getTime());
                }
                auxContDay = auxDate.getDate() + 1;
            }

            this.setState({ dataSelecionada: new Date(this.user.timestampPrevisaoRota), maxDate: aDatasDisponiveis[aDatasDisponiveis.length - 1], datasExcluidas: datasExcluidas });
        }

    }

    componentDidUpdate(prevProps, prevState) {
        this.user = this.context;
    }

    fecharPopup() {
        this.user.updateModal(null);
    }

    alterarDataPrevisaoEntrega(event) {
        event.preventDefault();
        this.user.updateLoading(true);

        const date = new Date(this.state.dataSelecionada);
        this.user.alterarTimestampPrevisaoRota(date.getFullYear() + '-' + ((date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate()));
        
        setTimeout(() => {
            this.user.updateModal(null);
            this.user.updateLoading(false);
        }, 900);
    }

    dataAlterada(e) {
        if (e) {
            this.setState({ dataSelecionada: new Date(e).getTime() });
        }
    }

    render() {
        return (
            <div>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-secondary">
                            <ul className="nav nav-tabs card-header-tabs" role="tablist">
                                <li className="nav-item"><a className="nav-link fw-medium active" href="#confirmar-compra-tab" data-bs-toggle="tab" role="tab" aria-selected="true"><i className="ci-time me-2 mt-n1" />Agendamento previsão de entrega</a></li>
                            </ul>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={this.fecharPopup.bind(this)} />
                        </div>

                        <div className="modal-body tab-content py-4">
                            <div className="row mt-3">
                                <div className="col-sm text-center">
                                    <DatePicker
                                        locale={ptBR}
                                        selected={this.state.dataSelecionada}
                                        onChange={this.dataAlterada.bind(this)}
                                        excludeDates={this.state.datasExcluidas}
                                        inline
                                        minDate={new Date()}
                                        maxDate={this.state.maxDate}
                                    />
                                </div>
                            </div>

                            <button style={{ marginTop: 30 }} className="btn btn-primary btn-shadow d-block w-100" onClick={this.alterarDataPrevisaoEntrega.bind(this)}>Confirmar</button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AlterarDataEntregaPopUp